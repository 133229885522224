import { COUNTRIES } from '../../../utils/enums/countries';
import { ArgentinaInvoicesHistoryStrategy } from './ArgentinaInvoicesHistoryStrategy';
import { MexicoInvoicesHistoryStrategy } from './MexicoInvoicesHistoryStrategy';
import { PeruInvoicesHistoryStrategy } from './PeruInvoicesHistoryStrategy';

const invoicesHistoryStrategyRegistry = {
  [COUNTRIES.MEXICO]: new MexicoInvoicesHistoryStrategy(),
  // [COUNTRIES.COLOMBIA]: new ColombiaInvoiceStrategy(),
  // [COUNTRIES.SPAIN]: new SpainInvoiceStrategy(),
  // [COUNTRIES.INTERNATIONAL]: new InternationalInvoiceStrategy(),
  // [COUNTRIES.USA]: new UsaInvoiceStrategy(),
  [COUNTRIES.ARGENTINA]: new ArgentinaInvoicesHistoryStrategy(),
  // [COUNTRIES.COSTA_RICA]: new CostaRicaInvoiceStrategy(),
  // [COUNTRIES.PANAMA]: new PanamaInvoiceStrategy(),
  // [COUNTRIES.REPUBLICA_DOMINICANA]: new RepublicaDominicanaInvoiceStrategy(),
  [COUNTRIES.PERU]: new PeruInvoicesHistoryStrategy(),
};
export class InvoicesHistoryCountryStrategyFactory {
  /**
   * Creates an invoices history strategy for a given country.
   *
   * @param {string} country
   * @return {InvoicesHistoryStrategy}
   */
  static createStrategy(country) {
    return invoicesHistoryStrategyRegistry[country];
  }
}
