import { Icon, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { isDefaultClient, replaceAndParse } from '../../../utils';
import BigNumber from 'bignumber.js';
import { useFormat } from '../../../hooks/useFormat';
import { useDispatch, useSelector } from 'react-redux';
import { country as countrySelector } from '../../../selectors/company';
import { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import useInvoiceChange from '../../modals/invoice/hooks/useInvoiceChange';
import { getHelpLink } from './utils';
import { IconInfoCircle } from '@tabler/icons-react';
import { getPOSClient } from '../../../reducers/app';
import { showCreditLimitField } from '../../../pages/contacts/utils/limitCredit';
import checkIfIsDefaultClient from '../../../pages/contacts/utils/isDefaultClient';

export const CreditLimitNotification = ({
  client,
  values,
  total,
  totalToCollect,
  setCanCredit,
}) => {
  const { fmt, decimal } = useFormat();
  const country = useSelector(countrySelector);
  const { change, received } = useInvoiceChange(values, total);

  const dispatch = useDispatch();

  const [canCreditInvoice, setCanCreditInvoice] = useState(true);
  const [creditLimitAmount, setCreditLimitAmount] = useState(new BigNumber(0));

  if (!showCreditLimitField(country)) {
    return null;
  }

  const isAbleToCredit = (creditLimit, change, totalToCollect) => {
    const bigTotal = new BigNumber(total);

    if (
      creditLimit === null ||
      bigTotal.isLessThanOrEqualTo(received) ||
      totalToCollect === null
    ) {
      return true;
    }

    const bigCreditLimit = new BigNumber(creditLimit);
    const bigTotalToCollect = new BigNumber(totalToCollect);

    let totalCredit = bigTotalToCollect;

    if (change.isGreaterThanOrEqualTo(0)) {
      totalCredit = bigTotalToCollect.plus(change);
    }

    return bigCreditLimit.isGreaterThanOrEqualTo(totalCredit);
  };

  useEffect(() => {
    const fetchClientData = async () => {
      let contact = client;
      // if (!get(client, 'id') && checkIfIsDefaultClient(client, country)) {
      //   contact = await dispatch(getPOSClient());
      // }
      const creditLimit = get(contact, 'creditLimit', null);
      const canCredit = isAbleToCredit(creditLimit, change, totalToCollect);

      setCanCredit(canCredit);
      setCanCreditInvoice(canCredit);
      setCreditLimitAmount(
        creditLimit ? new BigNumber(creditLimit) : new BigNumber(0)
      );
    };

    if (received !== null) {
      fetchClientData();
    }
  }, [client, change, totalToCollect, dispatch, received]);

  const firstLoad = useMemo(() => received === null, [received]);

  const renderNotificationText = () => {
    if (creditLimitAmount.isEqualTo(0)) {
      return replaceAndParse(
        I18n.get(
          'creditLimit.notification.description.zero',
          'Con esta venta el cliente supera el límite que le asignaste, puedes registrar el pago total o conocer {{link}}'
        ),
        [
          `<a target='_blank' href='${getHelpLink(country)}'>${I18n.get('creditLimit.notification.link', 'cómo aumentar su crédito ↗')}</a>`,
        ]
      );
    }

    return replaceAndParse(
      I18n.get(
        'creditLimit.notification.description',
        'Con esta venta el cliente supera los {{amount}} del límite que le asignaste, puedes registrar el pago total o conocer {{link}}'
      ),
      [
        `<b>${creditLimitAmount.toFormat(decimal, fmt)}</b>`,
        `<a target='_blank' href='${getHelpLink(country)}'>${I18n.get('creditLimit.notification.link', 'cómo aumentar su crédito ↗')}</a>`,
      ]
    );
  };

  return !canCreditInvoice && !firstLoad && received !== null ? (
    <div className='credit-limit-container'>
      <div>
        <Icon icon={IconInfoCircle} color='#B45309' />
      </div>
      <div className=''>
        <Typography
          type='body-3-bold'
          text={I18n.get(
            'creditLimit.notification.title',
            'Límite de crédito excedido 🚫'
          )}
        />
        <p className='mt-2 body-3-regular'>{renderNotificationText()}</p>
      </div>
    </div>
  ) : null;
};

export default CreditLimitNotification;
