import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { graphqlOperation } from '@aws-amplify/api';
import { get, isEmpty, isNull, isUndefined, lowerFirst } from 'lodash';

import * as mutations from '../../../graphql/mutations';
import { itemTransformer } from '../../../utils/transformers';
import { toast, replaceAndParse } from '../../../utils';
import { formError, handleError } from '../../../utils/errors';
import {
  checkVariantImages,
  isItemFromStation,
  saveVariantsImages,
} from '../../../utils/items';
import {
  sendGTMEvent,
  sendNewGTMEvent,
} from '../../../reducers/company';
import * as itemsDB from '../../../database/itemsDB';
import { refresh } from '../../../reducers/items';
import { I18n } from '@aws-amplify/core';
import useReduxData from './useReduxData';
import { transform, validateOnSubmit } from '../../forms/item/utils';
import { endAction, startAction } from '../../../reducers/monitoring';
import { calculateActionTimeSelector } from '../../../selectors/monitoring';
import { toastHandler } from '@alegradev/smile-ui-react';
import { BARCODE_IN_USE_CODE } from '../utils';

export function useSubmitItem({ APIGraphql, station, history }) {

  const {
    dispatch,
    country,
    allCustomFields,
    decimal: decimalPrecision
  } = useReduxData();
  const calculateActionTime = useSelector(calculateActionTimeSelector);

  const submit = useCallback(async (values) => {
    dispatch(startAction({ action: 'createItem' }));
    if (validateOnSubmit(values)) {
      return formError(
        null,
        I18n.get('createItemError', 'Hubo un error en la creación del producto')
      );
    }

    const transformedValues = transform(values, {
      country,
      decimal: decimalPrecision,
      allCustomFields,
      categories: {
        mainCategory: values.mainCategory,
        inventoryCategory: values.inventoryCategory,
        inventariablePurchaseCategory: values.inventariablePurchaseCategory,
      },
    });

    try {
      const response = await APIGraphql(
        graphqlOperation(mutations.createItem, { item: itemTransformer(transformedValues) })
      );

      const item = get(response, 'data.createItem', null);

      dispatch(endAction({ action: 'createItem' }));
      dispatch(sendGTMEvent('item-created', {
        creationOrigin: 'advanced form',
        hasVariants: get(item, 'type', null) === 'variantParent',
        hasAdditionalFields: !isEmpty(get(item, 'customFields', [])),
        itemType: get(item, 'type', null),
        hasCategory: !!get(item, 'itemCategory', null),
      }));

      dispatch(sendNewGTMEvent('pos-item-created', {
        id: get(item, 'id', null),
        error: 'no error',
        responseTime: calculateActionTime("createItem"),
        type: get(item, 'type', null),
        method: 'advanced',
        origin: 'items',
        field: Object.keys(transformedValues).filter(
          key => !isUndefined(transformedValues[key]) && !isNull(transformedValues[key])
        ),
      }));

      if (get(transformedValues, 'type', '') === 'variantParent') {
        const variantsImages = checkVariantImages(transformedValues);
        if (variantsImages.length > 0) {
          await saveVariantsImages(JSON.parse(get(item, 'itemVariants')), variantsImages);
        }
      }

      if (isItemFromStation(item, station.idWarehouse)) {
        await itemsDB.put({ ...item, id: String(get(item, 'id')) });
        dispatch(refresh());
      }

      history.goBack();
      toast.success({
        title: I18n.get('itemCreatedSuccessfully', 'Directo al blanco'),
        subtitle: replaceAndParse(
          I18n.get('itemCreatedSuccessfullyMessage', 'El {} {} ya está disponible en tu cuenta.'),
          [
            get(item, 'type') !== 'simple' ? lowerFirst(I18n.get(get(item, 'type'), 'producto')) : lowerFirst(I18n.get('product', 'producto')),
            `<span class="font-weight-bold">${get(item, 'name', '')}</span>`,
          ]
        ),
      });
    } catch (error) {
      if (get(error, 'errors[0].errorInfo.code') === BARCODE_IN_USE_CODE) {
        toastHandler({
          type: 'error',
          title: I18n.get('barcodeInUse', 'Código de barras en uso'),
          description: I18n.get(
            'barcodeInUse.message',
            'Asegúrate de incluir un código que no esté asociado a otro ítem.'
          ),
          autoClose: 4000,
          width: '400px',
        });
      } else {
        toast.error({
          subtitle: handleError(error, {
            defaultMessage: I18n.get(
              'createItemError',
              'Hubo un error en la creación del producto'
            ),
          }),
        });
      }
      
      const parseError = handleError(error);
      dispatch(sendNewGTMEvent('pos-item-created', {
        error: parseError,
        method: 'basic',
      }));
      return formError(error, I18n.get('createItemError', 'Hubo un error en la creación del producto'));
    }
  }, [APIGraphql, dispatch, history, station.idWarehouse, country, decimalPrecision, allCustomFields]);

  return submit;
}
