import Cookie from 'js-cookie';
import http from '../utils/http';
import { graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

const baseUrl = process.env.REACT_APP_NOTIFICATIONS_ENDPOINT_V2;
const TOKEN_COOKIE_NAME = process.env.REACT_APP_ALEGRA_AUTH_TOKEN_COOKIE; 
const baseConfig = {
  headers: {
    Authorization: `Bearer ${Cookie.get(TOKEN_COOKIE_NAME)}`,
  },
};

const post = async (url, params = null, urlParams = null, userConfig = null) =>
  await http.post(
    `${baseUrl}${url}`,
    params,
    urlParams,
    { ...baseConfig, ...userConfig },
    Cookie.get('pos-block-api-request')
  );


const createNotification = async (api, params) => {
  const response = await api(graphqlOperation(mutations.createNotificationForUser, {
      notification: {...params},
    }));

  return response;
};

const defaultExport = {
  post,
  createNotification,
};

export default defaultExport;
