import { Divider, Grid, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { exonerationDocumentTypes } from '../../../../../../components/countriesData/costaRica/exonerationDocumentTypes';
import { InstitutionCodes } from '../../../../../../components/countriesData/costaRica/institutionNames';

const ExonerationDetail = ({ exoneration }) => {
  const { Wrapper } = Grid;

  const renderExonerationItem = ({ title, key, customValue, hideEmpty }) => {
    if (!exoneration[key] && hideEmpty) return null;
    return (
      <Wrapper direction={{ lg: 'column' }} gap={6} fullWidth>
        <Typography type='body-3-bold' text={title} />
        <Typography
          type='body-3-regular'
          text={customValue || exoneration[key] || '-'}
        />
        <Divider margin='0' />
      </Wrapper>
    );
  };

  return (
    <Wrapper direction={{ lg: 'column' }} gap={10}>
      {renderExonerationItem({
        title: I18n.get('documentType', 'Tipo de documento'),
        key: 'documentType',
        customValue:
          exonerationDocumentTypes.find(
            (option) => option.code === exoneration?.documentType
          )?.description || '-',
      })}
      {renderExonerationItem({
        title: I18n.get('otherDocumentType', 'Otro tipo de documento'),
        key: 'otherDocumentType',
        hideEmpty: true,
      })}
      {renderExonerationItem({
        title: I18n.get('documentNumber', 'Número de documento'),
        key: 'documentNumber',
      })}
      {renderExonerationItem({
        title: I18n.get('institutionName', 'Nombre de institución'),
        key: 'institutionName',
        customValue:
          InstitutionCodes.find(
            (option) => option.code === exoneration?.institutionName
          )?.name || '-',
      })}
      {renderExonerationItem({
        title: I18n.get('otherInstitutionName', 'Otro nombre de institución'),
        key: 'otherInstitutionName',
        hideEmpty: true,
      })}
      {renderExonerationItem({
        title: I18n.get('article', 'Artículo'),
        key: 'article',
      })}
      {renderExonerationItem({
        title: I18n.get('paragraph', 'Inciso'),
        key: 'paragraph',
      })}
      {renderExonerationItem({
        title: I18n.get('emissionDate', 'Fecha de emisión'),
        key: 'emissionDate',
      })}
      {renderExonerationItem({
        title: I18n.get('percentage', 'Porcentaje'),
        key: 'percentage',
        customValue: `${exoneration?.percentage}%`,
      })}
    </Wrapper>
  );
};

export default ExonerationDetail;
