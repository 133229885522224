import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, capitalize } from 'lodash';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { Field } from 'react-final-form';

import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { handleError } from '../../../../utils/errors';
import {
  APIGraphqlSelector,
  station as stationSelector,
} from '../../../../selectors/app';
import { renderField } from '../../fields/V0/Fields';

import NequiExt from '../../../svg/paymentMethods/NequiExt';
import { replaceAndParse } from '../../../../utils';
import { useFormat } from '../../../../hooks/useFormat';
import { sendGTMEvent, sendNewGTMEvent } from '../../../../reducers/company';
import {
  numeration as numerationSelector,
  currency as currencySelector,
  itemsCount as itemsCountSelector,
} from '../../../../selectors/activeInvoice';

import {
  Button,
  Icon,
  Typography,
  useDeviceType,
} from '@alegradev/smile-ui-react';
import Nequi from '../../../svg/paymentMethods/Nequi';
import AnnotationField from '../components/AnnotationField';
import SellerField from '../components/SellerField';
import { IconCheck, IconLoader2, IconRepeat } from '@tabler/icons-react';

const API_CALL_DELAY = 2000;

const NewPushNotification = ({ values, form, total, anotationMaxLimit }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [checking, setChecking] = useState(false);
  const [checkingError, setCheckingError] = useState();
  const [success, setSuccess] = useState(false);
  const [clientEmail, setClientEmail] = useState(null);
  const [clientPhone, setClientPhone] = useState(null);
  const [paymentId, setPaymentId] = useState();

  const station = useSelector(stationSelector);
  const numeration = useSelector(numerationSelector);
  const currency = useSelector(currencySelector);
  const itemsCount = useSelector(itemsCountSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const deviceType = useDeviceType();

  const { fmt, decimal } = useFormat();

  // const getQRPromise = APIGraphql(graphqlOperation())

  useEffect(() => {
    ref.current = true;
    return () => (ref.current = false);
  }, []);

  const checkConditionCallback = (responseData) => {
    const stopConditions = ['APPROVED', 'DECLINED', 'ERROR'];
    const { state } = responseData;

    if (state === 'ERROR') {
      setCheckingError(
        capitalize(
          I18n.get('externalPaymentError', 'falló la verificación del pago')
        )
      );
      return true;
    }

    if (state === 'DECLINED') {
      setCheckingError(
        capitalize(
          I18n.get('externalPaymentDeclined', 'el pago ha sido rechazado')
        )
      );
      return true;
    }

    return stopConditions.includes(state);
  };
  console.log('values.method3', values);
  const getProvider = useCallback(() => {
    switch (values?.paymentMethodSelected) {
      case 'nequiPush':
        return 'wompi';

      default:
        return values.method;
    }
  }, [values.method]);

  const getPaymentInfo = useCallback(() => {
    switch (values?.paymentMethodSelected) {
      case 'nequiPush':
        return {
          type: 'NEQUI',
          phone_number: clientPhone,
          sandbox_status: 'DECLINED',
        };

      default:
        return null;
    }
  }, [values.method, clientPhone]);

  const generatePushNotification = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await APIGraphql(
        graphqlOperation(mutations.createProviderPayment, {
          payment: {
            provider: getProvider(),
            terminal_id: get(station, 'id'),
            amount: total * 100,
            customer_email: clientEmail,
            payment_method: getPaymentInfo(),
          },
        })
      );

      if (!!get(response, 'data.createProviderPayment.id')) {
        setPaymentId(get(response, 'data.createProviderPayment.id'));
      }

      if (ref.current) {
        setLoading(false);
        setError(null);
      }
    } catch (error) {
      if (ref.current) {
        setLoading(false);
        setError(
          handleError(error, {
            defaultMessage: capitalize(
              I18n.get(
                'getQrCodeError',
                'ocurrió un problema al crear el pago, intente de nuevo'
              )
            ),
          })
        );
      }
    }
  }, [station, total, clientEmail, getPaymentInfo, getProvider, APIGraphql]);

  useEffect(() => {
    if (!clientEmail) {
      return;
    }

    generatePushNotification();
  }, [clientEmail, generatePushNotification]);

  const checkPaymentLongPolling = useCallback(
    async (paymentId) => {
      setChecking(true);
      setCheckingError(null);
      let response = {};
      try {
        response = await APIGraphql(
          graphqlOperation(queries.getProviderPayment, {
            payment: {
              id: paymentId,
              provider: getProvider(),
            },
          })
        );

        if (checkConditionCallback(response.data.getProviderPayment)) {
          if (get(response, 'data.getProviderPayment.state') === 'APPROVED') {
            form.change('transfer', total);
            form.change('qrId', paymentId);
            dispatch(
              sendNewGTMEvent('pos-sale-payment-status', {
                methodType: 'wompi-nequi',
                status: 'Pago aprobado',
                error: '',
                amount: parseFloat(total),
              })
            );
            setSuccess(true);
          } else {
            dispatch(
              sendNewGTMEvent('pos-sale-payment-status', {
                methodType: values.method,
                status: get(response, 'data.getProviderPayment.state'),
                error: '',
                amount: parseFloat(total),
              })
            );
          }

          if (ref.current) {
            setChecking(false);
            setCheckingError(null);
          }
          return;
        }
      } catch (error) {
        dispatch(
          sendNewGTMEvent('pos-sale-payment-status', {
            methodType: 'wompi-nequi',
            status: 'Error al verificar el pago',
            error: handleError(
              error,
              capitalize(
                I18n.get(
                  'checkQrCodeError',
                  'no se pudo verificar el estado del pago, intente de nuevo'
                )
              )
            ),
            amount: parseFloat(total),
          })
        );
        if (ref.current) {
          setChecking(false);
          setCheckingError(
            handleError(error, {
              defaultMessage: capitalize(
                I18n.get(
                  'checkQrCodeError',
                  'no se pudo verificar el estado del pago, intente de nuevo'
                )
              ),
            })
          );
        }
        return;
      } finally {
        if (!checkConditionCallback(response.data.getProviderPayment)) {
          setTimeout(() => checkPaymentLongPolling(paymentId), API_CALL_DELAY);
        }
      }
      //eslint-disable-next-line
    },
    [getProvider, total, form?.values?.transfer, APIGraphql]
  );

  const submit = async (values) => {
    if (!paymentId) {
      setClientPhone(get(values, 'phone'));
      setClientEmail(get(values, 'email'));
    }
  };

  useEffect(() => {
    if (!paymentId) return;

    checkPaymentLongPolling(paymentId);
  }, [checkPaymentLongPolling, paymentId]);

  return (
    <div>
      {success ? (
        <div className='form-row col-sm-12 pl-2 pr-0'>
          <div className='w-100 d-flex flex-column justify-content-center align-items-center col-sm-12 col-md-6'>
            <NequiExt className='mb-3' />
            <div
              className='svg-icon-sucess-background justify-content-center align-items-center mb-3'
              style={{ width: '5.6rem', height: '5.6rem' }}
            >
              <Icon icon={IconCheck} size='extraLarge' />
            </div>
            <p
              className='h2 px-3 py-2 inter-regular'
              style={{ color: '#16A34A' }}
            >
              {I18n.get('', '¡Pago exitoso!')}
            </p>
            <p className='h4 py-2'>
              {replaceAndParse(I18n.get('youReceived', 'Recibiste {}'), [
                total.toFormat(decimal, fmt),
              ])}
            </p>
          </div>
          <div
            className={`col-sm-12 col-md-6  ${deviceType === 'mobile' ? 'mobile' : 'px-0 form__invoice-advanced-data'}`}
          >
            <div className='d-flex justify-content-center'>
              <SellerField />
            </div>
            <div
              className={`col-sm-12 ${deviceType === 'mobile' ? 'p-0' : 'pl-2 pr-0'}`}
            >
              <AnnotationField
                anotationMaxLimit={anotationMaxLimit}
                type='textarea'
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className='d-flex align-items-center mb-4'>
            <Nequi height={26} />
            <Typography
              type='body-3-regular'
              text={I18n.get(
                'sendNequiNotificationToClient',
                'Envía una notificación de pago al Nequi de tu cliente.'
              )}
              variant='tertiary'
            />
          </div>

          <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
            <div className='form-row col-12 p-0'>
              <Field
                name='phone'
                className='col-sm-12 col-md-6 pl-0'
                disabled={loading || checking || checkingError}
                type='number'
                label={
                  <Typography
                    type='label-2'
                    text={capitalize(
                      I18n.get('clientPhone', 'Celular del cliente')
                    )}
                    variant='secondary'
                  />
                }
                required
              >
                {(props) => (
                  <>
                    {renderField({
                      ...props,
                      fontSize: '16px',
                      height: '48px',
                      fontColor: '#334155',
                      paddingLeft: '10px',
                    })}
                  </>
                )}
              </Field>
              <Field
                name='email'
                className='col-sm-12 col-md-6 pr-0'
                disabled={loading || checking || checkingError}
                type='text'
                label={
                  <Typography
                    type='label-2'
                    text={capitalize(
                      I18n.get('clientEmail', 'Correo del cliente')
                    )}
                    variant='secondary'
                  />
                }
                required
              >
                {(props) => (
                  <>
                    {renderField({
                      ...props,
                      fontSize: '16px',
                      height: '48px',
                      fontColor: '#334155',
                      paddingLeft: '10px',
                    })}
                  </>
                )}
              </Field>
            </div>
            {!checking && (
              <Button
                size='large'
                type='button'
                emphasis={!checkingError ? 'filled' : 'outline'}
                leftIcon={!checkingError ? undefined : IconRepeat}
                onClick={() => {
                  if (!paymentId) {
                    dispatch(
                      sendNewGTMEvent('pos-sale-payment-status', {
                        methodType: 'wompi-nequi',
                        status: 'Enviando solicitud de pago',
                        error: '',
                        amount: parseFloat(total),
                      })
                    );
                    dispatch(
                      sendGTMEvent('wompi-nequi-request-sent', {
                        isElectronicInvoice: get(
                          numeration,
                          'isElectronic',
                          false
                        ),
                        customerPaymentMethod: 'wompi-nequi',
                        currency: get(currency, 'code', 'COP'),
                        invoiceTotal: parseFloat(total),
                        totalItems: itemsCount,
                      })
                    );
                    submit(values);
                    return;
                  }
                  if (!!checkingError) {
                    dispatch(
                      sendGTMEvent('payment-verification-retried', {
                        isElectronicInvoice: get(
                          numeration,
                          'isElectronic',
                          false
                        ),
                        customerPaymentMethod: 'wompi-nequi',
                        currency: get(currency, 'code', 'COP'),
                        invoiceTotal: parseFloat(total),
                        totalItems: itemsCount,
                      })
                    );
                    dispatch(
                      sendNewGTMEvent('pos-sale-payment-status', {
                        methodType: 'wompi-nequi',
                        status: 'Reintentando pago',
                        error: '',
                        amount: parseFloat(total),
                      })
                    );
                    checkPaymentLongPolling(paymentId);
                    return;
                  }
                }}
                loading={loading || checking}
                text={
                  !!paymentId
                    ? checkingError
                      ? I18n.get('retry', 'Reintentar')
                      : I18n.get('sent', 'Enviado')
                    : I18n.get('sendPaymentRequest', 'Enviar solicitud de pago')
                }
                disabled={
                  !get(values, 'email') || !get(values, 'phone') || checking
                }
                full={!checkingError}
              />
            )}
          </div>
          {!error && !checking && !success && !paymentId && (
            <p className='h5 px-3 py-2 text-muted text-capitalize-first'>
              {I18n.get('paymentNotVerified', 'Pago sin verificar')}
            </p>
          )}

          {!!error && (
            <p className='h5 px-3 py-2 text-danger text-capitalize-first'>
              {error}
            </p>
          )}

          {!!checkingError && (
            <div className='w-100 d-flex justify-content-center align-items-center mt-3'>
              <p className='h5 px-3 py-2 text-danger text-capitalize-first '>
                {checkingError}
              </p>
            </div>
          )}

          {checking && (
            <div className='d-flex justify-content-center align-items-center px-3 py-2'>
              <Icon
                icon={IconLoader2}
                animated
                extraClass=' icon-primary mr-2'
              />
              <p className='h5 text-muted text-capitalize-first m-0 p-0'>
                {I18n.get('checkingPayment', 'Verificando el pago...')}
              </p>
            </div>
          )}
          <div className='d-flex justify-content-center mt-4'>
            <SellerField />
          </div>
          <div className='col-sm-12 pl-2 pr-0'>
            <AnnotationField
              anotationMaxLimit={anotationMaxLimit}
              type='text'
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NewPushNotification;
