import { Grid, Typography, Divider } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { formatMoney } from '../../../../../utils';
import { useMemo } from 'react';
import { Field } from 'react-final-form';
import { renderField } from '../../../../../components/forms/fields/V2';

function Resume({ values }) {
  const { Col, Row, Wrapper } = Grid;

  const bill = useMemo(() => {
    const data = {
      subtotal: 0,
      discount: 0,
      iva: 0,
      taxes: {},
      total: 0,
    };

    if(!values) return data;

    values?.products?.forEach(({ product }) => {
      const [price, quantity, discount] = [
        Number(product?.price || 0),
        Number(product?.quantity || 0),
        Number(product?.discount || 0),
      ];

      const discountedPrice = price * (1 - discount / 100);
      data.subtotal += price * quantity;
      data.discount += (data.subtotal * discount) / 100;

      const productTaxes = Array.isArray(product?.tax)
        ? product.tax
        : [product?.tax];

      productTaxes.forEach((tax) => {
        if (tax) {
          const taxId = tax.id;
          const taxAmount =
            (discountedPrice * quantity * (Number(tax.percentage) || 0)) / 100;

          if (!data.taxes[taxId]) {
            data.taxes[taxId] = {
              ...tax,
              total: taxAmount,
            };
          } else {
            data.taxes[taxId].total += taxAmount;
          }
        }
      });
    });

    return {
      ...data,
      taxes: Object.values(data.taxes),
      total:
        data.subtotal -
        data.discount +
        Object.values(data.taxes).reduce((acc, tax) => acc + tax.total, 0),
    };
  }, [values]);

  return (
    <Row spacing='lg'>
      <Col xs={7}>
        <Field
          label={I18n.get('notes', 'Notas')}
          render={renderField}
          fieldType='textarea'
          name='notes'
        />
      </Col>
      <Col xs={5}>
        <Wrapper
          backgroundColor='#F8FAFC'
          bordered
          gap={10}
          direction={{ lg: 'column' }}
          padding={{ lg: 'md' }}
          rounded={{ lg: 'lg' }}
          fullWidth
        >
          <Wrapper justify={{ lg: 'between' }} align={{ lg: 'center' }}>
            <Typography
              type='caption-bold'
              text={I18n.get('subtotal', 'Subtotal')}
            />
            <Typography
              type='body-3-bold'
              text={formatMoney(bill?.subtotal)}
            />
          </Wrapper>
          <Wrapper justify={{ lg: 'between' }} align={{ lg: 'center' }}>
            <Typography
              type='caption-regular'
              text={I18n.get('disccount', 'Descuento')}
            />
            <Typography
              type='body-3-bold'
              text={`-${formatMoney(bill?.discount)}`}
            />
          </Wrapper>
          <Wrapper justify={{ lg: 'between' }} align={{ lg: 'center' }}>
            <Typography
              type='caption-bold'
              text={I18n.get('subtotal', 'Subtotal')}
            />
            <Typography
              type='body-3-bold'
              text={formatMoney(bill?.subtotal - bill?.discount)}
            />
          </Wrapper>
          {bill.taxes.map((tax) => (
            <Wrapper justify={{ lg: 'between' }} align={{ lg: 'center' }} key={tax?.id}>
              <Typography
                type='caption-regular'
                text={`${tax?.name} (${tax?.percentage}%)`}
              />
              <Typography type='body-3-bold' text={formatMoney(tax?.total)} />
            </Wrapper>
          ))}
          <Divider margin='0' />
          <Wrapper justify={{ lg: 'between' }} align={{ lg: 'center' }}>
            <Typography type='caption-bold' text={I18n.get('total', 'Total')} />
            <Typography type='body-3-bold' text={formatMoney(bill?.total)} />
          </Wrapper>
        </Wrapper>
      </Col>
    </Row>
  );
}

export default Resume;
