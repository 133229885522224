import { get, values } from 'lodash';
import ExternalClientAPI from '../../../reducers/externalClients';
import AlegraEndpoint from '../../../reducers/emailTemplatesAPI';
import dayjs from 'dayjs';
import { I18n } from 'aws-amplify';
import { toastHandler } from '@alegradev/smile-ui-react';
import { SHARE_METHODS } from '../invoiceSaved/util';
import { COUNTRIES } from '../../../utils/enums/countries';
export const getExternalClientHash = async ({ body }) => {
  const response = await ExternalClientAPI.post(
    '/api/v1/generate-invoice-hash',
    body
  );

  return response;
};

export const getMailTemplate = async () => {
  const response = await AlegraEndpoint.get('api/v1/email-templates/invoice');
  return get(response, 'data', null);
};

export const completeMessageTemplate = (template, hash, company, invoice) => {
  const valuesToReplace = [
    get(invoice, 'numberTemplate.number', ''),
    get(company, 'name', ''),
    get(invoice, 'client.name', ''),
    `${process.env.REACT_APP_PORTAL}/new-statement/${hash}/invoice-link/${get(invoice, 'id', '')}`,
  ];

  let valueIndex = 0;
  const result = template.replace(/{{[^{}]+}}/g, () => {
    const value = valuesToReplace[valueIndex];
    valueIndex++;
    return value || '';
  });

  return result;
};

export const getPortalUrl = (hash, invoice) => {
  return `${process.env.REACT_APP_PORTAL}/new-statement/${hash}/invoice-link/${get(invoice, 'id')}`;
};

const generateButtonHTML = (url) => `
  <a href="${url}" style="background-color:#00b19d;width:200px;min-height:20px;padding:5px;color:#fff;border-radius:5px;display:block;max-height:20px;text-align:center;margin:10px 0;text-decoration:none;padding-top:9px" target="_blank">Ver mis facturas</a>
`;

export const completeEmailMessageTemplate = (
  template,
  hash,
  company,
  invoice,
  includeButton = true,
  country
) => {
  let valuesToReplace = [
    get(invoice, 'numberTemplate.number', ''),
    get(invoice, 'date')
      ? dayjs(get(invoice, 'date')).format('DD/MM/YYYY')
      : '',
    get(company, 'name', ''),
    get(company, 'phone', ''),
    get(company, 'email', ''),
  ];

  if (country === COUNTRIES.PANAMA) {
    valuesToReplace.push(
      dayjs(get(invoice, 'stamp.date', '')).format('DD/MM/YYYY')
    );
    valuesToReplace.push(get(invoice, 'numberTemplate.prefix', ''));
    valuesToReplace.push(get(invoice, 'numberTemplate.branchOfficeCode', ''));
  }

  valuesToReplace.push(get(invoice, 'client.name', ''));

  let valueIndex = 0;
  let result = template.replace(/{{[^{}]+}}/g, () => {
    const value = valuesToReplace[valueIndex];
    valueIndex++;
    return value || '';
  });

  if (includeButton) {
    const lines = result.split(/(<br[^>]*>)/);

    const lineToInsertButtonBefore = 5;
    const buttonHTML = generateButtonHTML(getPortalUrl(hash, invoice));

    lines.splice(lineToInsertButtonBefore, 0, buttonHTML);

    result = lines.join('');
  }

  return result;
};

export const completeEmailSubject = (subject, invoice) => {
  const valuesToReplace = [get(invoice, 'numberTemplate.number', '')];

  let valueIndex = 0;
  const result = subject.replace(/{{[^{}]+}}/g, () => {
    const value = valuesToReplace[valueIndex];
    valueIndex++;
    return value || '';
  });

  return result;
};

export const validate = (values, shareBy) => {
  const errors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (shareBy === 'email') {
    const email = get(values, 'to[0].value', '');

    if (!email) {
      errors.to = I18n.get('errors.email', 'Agregue un correo electronico');
    } else if (!emailRegex.test(email)) {
      errors.to = I18n.get(
        'errors.email',
        'Ingrese un correo electrónico válido'
      );
    }

    if (!get(values, 'subject')) {
      errors.subject = I18n.get(
        'shareInvoice.email.subject.error',
        'Ingresa un asunto para tu correo'
      );
    }
  } else if (shareBy === SHARE_METHODS.WHATSAPP) {
    const phone = get(values, 'phone', '');
    if (!phone) {
      errors.phone = I18n.get(
        'shareInvoice.phone.error',
        'Ingresa el número telefónico de tu cliente'
      );
    }

    if (!get(values, 'message')) {
      errors.message = I18n.get(
        'shareInvoice.whatsapp.message.error',
        'Ingresa el mensaje que enviarás a tu cliente'
      );
    }
  }

  return errors;
};

export const handleCopyLink = (hash, invoice) => {
  const ALEGRA_PORTAL_LINK = getPortalUrl(hash, invoice);
  navigator.clipboard.writeText(ALEGRA_PORTAL_LINK);

  toastHandler({
    type: 'success',
    title: I18n.get('linkCopied', 'Enlace copiado'),
  });
};

export const newShareInvoiceAvailable = ({ country }) => {
  const availableCountries = [
    COUNTRIES.USA,
    COUNTRIES.PERU,
    COUNTRIES.SPAIN,
    COUNTRIES.COSTA_RICA,
    COUNTRIES.REPUBLICA_DOMINICANA,
    COUNTRIES.INTERNATIONAL,
    COUNTRIES.ARGENTINA,
    COUNTRIES.MEXICO,
    COUNTRIES.COLOMBIA,
    COUNTRIES.PANAMA,
  ];
  return availableCountries.includes(country);
};

/* we detect OS to set the correct Whatsapp URL */
export const WHATSAPP_BASE_URL = {
  desktop: 'https://web.whatsapp.com/send',
  mobile: 'https://api.whatsapp.com/send',
};

export const getMobileOS = ({ company }) => {
  const userAgent = navigator?.userAgent || navigator?.vendor || window?.opera;
  if (get(company, 'id') === '1447969') {
    return 'mobile';
  }
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  return 'desktop';
};
