import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { BillFormProvider } from './FormProvider';
import { Button, Grid, Space, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { ProviderClient } from './providerClient';
import { BasicData } from './basicData';
import { Table } from './products';
import { FieldArray } from 'react-final-form-arrays';
import { Resume } from './resume';
import { ProviderPayment } from './providerPayment';
import { IconPlus } from '@tabler/icons-react';
import { client as clientSelector } from '../../../../selectors/activeInvoice';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from './header';
import {
  companySelector,
  decimalPrecision,
} from '../../../../selectors/company';
import { transform } from './utils/transformer';
import { useCreateUpdateBillMutation } from '../../services/mutations';
import { validate } from './utils/validations';
import {
  APIGraphqlSelector,
  stationWarehouse,
  station as stationSelector,
  firstTimeInBillsForm,
} from '../../../../selectors/app';
import { some } from 'lodash';
import { useBillQuery, useCostCenterSettingsQuery } from '../../services/hooks';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { banksSelector } from '../../../../reducers/banks';
import { setFirstTimeInBills } from '../../../../reducers/app';
import { sendNewGTMEvent } from '../../../../reducers/company';
import { activeCostCenters } from '../../../../selectors/costCenters';

function BillForm() {
  const { Wrapper } = Grid;
  const params = useParams();
  const client = useSelector(clientSelector);
  const company = useSelector(companySelector);
  const decimals = useSelector(decimalPrecision);
  const warehouse = useSelector(stationWarehouse);
  const station = useSelector(stationSelector);
  const api = useSelector(APIGraphqlSelector);
  const bankAccounts = useSelector(banksSelector.selectAll);
  const history = useHistory();
  const costCenters = useSelector(activeCostCenters);
  const { data: costCentersSettings } = useCostCenterSettingsQuery();
  const { mutateAsync, isLoading } = useCreateUpdateBillMutation();
  const { data } = useBillQuery(params?.id, api, true);
  const firstTimeInBillsSelector = useSelector(firstTimeInBillsForm);
  const dispatch = useDispatch();
  

  useEffect(() => {
    if (firstTimeInBillsSelector) {
      dispatch(setFirstTimeInBills(false));
    }

    sendNewGTMEvent('pos-form-bill-visited', {
      First: firstTimeInBillsSelector,
    });
  }, [firstTimeInBillsSelector]);

  const submit = async (values) => {
    const transformedValues = transform(values, decimals, station?.id);
    await mutateAsync(transformedValues);
  };

  const getClient = useMemo(() => {
    if (!client?.id) return null;
    if (client?.identification?.type) {
      return {
        ...client,
        identification: client?.identification?.number,
      };
    }
    return client;
  }, [client]);

  const initialValues = useMemo(() => {
    if (data) {
      return {
        id: data?.id,
        billNumber: data?.numberTemplate?.fullNumber || data?.id,
        products: data?.purchases?.items?.map((product) => {
          return {
            product,
          };
        }),
        bankAccounts: data?.payments?.length > 0 ? data?.payments?.map((bankAccount) => {
          const cashAccount = bankAccounts?.find(
            (account) => account?.type === 'cash'
          );
          return {
            bankAccount: {
              ...cashAccount,
              amount: bankAccount?.amount,
            },
          };
        }) : null,
        provider: data?.provider,
        currency: company?.currency?.code,
        billBuyDate: new Date(data?.date),
        expiredDate: new Date(data?.dueDate),
        warehouse: data?.warehouse,
        costCenter: data?.costCenter,
      };
    }

    const findBillCostCenter = costCentersSettings?.find(
      (item) => item.type === 'bill'
    );
    const costCenter = costCenters.find(
      (options) => options?.id === findBillCostCenter?.idCostCenter
    );

    return {
      products: [{}],
      provider: getClient,
      currency: company?.currency?.code,
      billBuyDate: new Date(),
      expiredDate: new Date(),
      costCenter,
      warehouse,
    };
  }, [data, getClient, costCentersSettings]);

  return (
    <Form
      onSubmit={submit}
      mutators={{
        ...arrayMutators,
      }}
      validate={(values) => validate(values)}
      initialValues={initialValues}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, values, form, errors, submitting }) => {
        return (
          <BillFormProvider>
            <form noValidate onSubmit={handleSubmit} className='w-full'>
              <Wrapper
                padding={{ lg: 'lg' }}
                backgroundColor='white'
                rounded={{ lg: 'lg' }}
                direction={{ lg: 'column' }}
                gap={18}
                bordered
              >
                <Header />
                <Typography
                  type='body-2-bold'
                  text={I18n.get('generalInformation', 'Datos generales')}
                />
                <ProviderClient form={form} values={values} />
                <BasicData />

                <Typography
                  type='body-2-bold'
                  text={I18n.get('pruchasedProducts', 'Productos comprados')}
                />

                <FieldArray name='products'>
                  {({ fields }) => (
                    <div>
                      <Table fields={fields} form={form} values={values} />
                      <Space height={10} />
                      <Button
                        type='button'
                        leftIcon={IconPlus}
                        onClick={() => fields.push({})}
                        text={I18n.get('addProduct', 'Agregar producto')}
                      />
                    </div>
                  )}
                </FieldArray>

                <Resume values={values} form={form} />

                {/*<FieldArray name='bankAccounts'>
                  {({ fields }) => (
                    <ProviderPayment
                      fields={fields}
                      values={values}
                      form={form}
                      change={form?.change}
                    />
                  )}
                </FieldArray>*/}
              </Wrapper>
              <Space height={10} />
              <Wrapper justify={{ lg: 'end' }} gap={20}>
                <Button
                  type='button'
                  emphasis='outline'
                  disabled={isLoading}
                  onClick={() => history.push('/bills')}
                  text={I18n.get('cancel', 'Cancelar')}
                />
                <Button
                  type='submit'
                  text={I18n.get('save', 'Guardar')}
                  loading={isLoading}
                  disabled={
                    some(values?.decimalsError, (value) => value) ||
                    submitting ||
                    Object.keys(errors).length > 0
                  }
                />
              </Wrapper>
            </form>
          </BillFormProvider>
        );
      }}
    </Form>
  );
}

export default BillForm;
