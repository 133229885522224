import { useDispatch, useSelector } from 'react-redux';
import Confirmation from '../../../components/modals/confirmation/Confirmation';
import { get } from 'lodash';
import { graphqlOperation, I18n } from 'aws-amplify';
import { closeModal, openModal } from '../../../reducers/modals';
import { replaceAndParse } from '../../../utils';
import { getNumber } from '../../../utils/invoices';
import { useRef, useState } from 'react';
import { country as countrySelector } from '../../../selectors/company';
import { COUNTRIES } from '../../../utils/enums/countries';
import {
  APIGraphqlSelector,
  station as stationSelector,
} from '../../../selectors/app';
import * as mutations from '../../../graphql/mutations';
import { handleError } from '../../../utils/errors';
export const VoidInvoice = ({
  invoice,
  setVoidLoading,
  fetchData,
  closePartibleFn,
}) => {
  const ref = useRef(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const country = useSelector(countrySelector);
  const confirmationOpen = useSelector((state) =>
    get(state, 'modals.voidInvoice.isOpen', false)
  );
  const dispatch = useDispatch();
  const APIGraphql = useSelector(APIGraphqlSelector);
  const station = useSelector(stationSelector);

  const voidInvoice = async (cause) => {
    setLoading(true);
    setVoidLoading(true);
    setError(null);

    try {
      await APIGraphql(
        graphqlOperation(mutations.cancelInvoice, {
          id: invoice.id,
          payments: !!invoice.payments ? invoice.payments.map((p) => p.id) : [],
          cause,
          idStation: get(station, 'id'),
        })
      );
      if (ref.current) {
        setLoading(false);
        setVoidLoading(false);
        setError(null);
      }
      fetchData({
        start: 0,
        limit: 20,
        sortDirection: 'desc',
      });
      dispatch(closeModal({ modal: 'voidInvoice' }));
      if (closePartibleFn) {
        closePartibleFn();
      }
    } catch (error) {
      if (ref.current) {
        setLoading(false);
        setVoidLoading(false);
        setError(handleError(error));
      }
    }
  };

  const checkCountry = () => {
    if (country !== COUNTRIES.REPUBLICA_DOMINICANA) return voidInvoice();

    dispatch(closeModal({ modal: 'voidInvoice' }));
    dispatch(openModal({ modal: 'voidInvoiceCause' }));
  };
  return (
    <Confirmation
      isOpen={confirmationOpen}
      onClose={() => dispatch(closeModal({ modal: 'voidInvoice' }))}
      onConfirm={() => checkCountry()}
      title={I18n.get('voidInvoice', 'anular venta')}
      closeText={I18n.get('cancel', 'Cancelar')}
      confirmText={I18n.get('confirmVoid', 'Confirmar anulación')}
      body={
        <>
          <p className='text-center h4 text-capitalize-first p-5'>
            {replaceAndParse(
              I18n.get(
                'areYouSureToVoidInvoice',
                'Confirma que deseas anular la venta <strong> {} </strong> para que se elimine de tus registros.'
              ),
              [getNumber(invoice, country)]
            )}
          </p>
          {!!error && (
            <p className='text-center h4 text-capitalize-first text-danger pb-4'>
              {error}
            </p>
          )}
        </>
      }
      submitting={loading}
      disableCancel={loading}
      hideRequired
    />
  );
};

export default VoidInvoice;
