import { Icon, TableCellRegular, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import dayjs from 'dayjs';
import { capitalize, get } from 'lodash';
import { getFmt } from '../../../../components/invoices/utils';
import BigNumber from 'bignumber.js';
import {
  IconAlertOctagonFilled,
  IconAlertTriangleFilled,
  IconCheck,
  IconDotsCircleHorizontal,
  IconFileSymlink,
  IconProgress,
} from '@tabler/icons-react';

const handleInvoicesDate = (invoice, isSplit = false) => {
  const today = dayjs();
  const invoiceDate = dayjs(invoice?.datetime || invoice?.timestamp);

  if (!isSplit) {
    return (
      <div>
        <Typography
          type='body-3-regular'
          text={dayjs(invoice.date).format('MMM D, YYYY')}
        />
        <Typography
          type='caption-regular'
          text={dayjs(invoice?.createdAt || invoice?.timestamp).format(
            'h:mm a'
          )}
          variant='tertiary'
        />
      </div>
    );
  }

  let formattedDate = invoiceDate.format('DD/MM/YYYY h:mm a');

  if (today.isSame(invoiceDate, 'day')) {
    formattedDate = invoiceDate.format('h:mm a');
  } else if (invoiceDate.isSame(today.subtract(1, 'day'), 'day')) {
    formattedDate = I18n.get('yesterday', 'ayer');
  }

  return (
    <Typography
      type='caption-regular'
      text={formattedDate}
      variant='tertiary'
    />
  );
};
const invoicesColumns = (
  isSplit,
  companyDecimal,
  mainCurrency,
  companyElectronic,
  invoicesHistoryStrategy
) => {
  const columns = [
    {
      Header: capitalize(I18n.get('invoiceNumber.column', 'Número')),
      accessor: 'numberTemplate.id',
      partiblePosition: 'top-left',
      width: '14%',
      Cell: ({ row }) => {
        const { original } = row;

        return (
          <TableCellRegular
            value={invoicesHistoryStrategy.getInvoiceNumber({
              invoice: original,
            })}
          />
        );
      },
    },
    {
      Header: capitalize(I18n.get('client', 'Cliente')),
      accessor: 'client.name',
      partiblePosition: 'bottom-left',
      width: companyElectronic ? '21%' : '26%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <Typography
            type={isSplit ? 'caption-regular' : 'body-3-regular'}
            text={(isSplit ? 'Cliente: ' : '') + original.client?.name}
          />
        );
      },
    },
    {
      Header: capitalize(I18n.get('', 'Creación')),
      accessor: (row) => row?.createdAt || row?.timestamp,
      partiblePosition: 'bottom-right',
      width: '15%',
      Cell: ({ row }) => {
        const { original } = row;
        return handleInvoicesDate(original, isSplit);
      },
    },
    {
      Header: capitalize(I18n.get('total', 'Total')),
      accessor: 'warehouse',
      alignment: 'right',
      partiblePosition: 'top-right',
      width: companyElectronic ? '15%' : '20%',
      Cell: ({ row }) => {
        const { original } = row;
        const decimal = !!get(original, 'decimalPrecision', null)
          ? Number(original.decimalPrecision)
          : companyDecimal;
        const fmt = getFmt(original, mainCurrency);
        return (
          <TableCellRegular
            value={new BigNumber(original.total).toFormat(decimal, fmt)}
          />
        );
      },
    },
    {
      Header: capitalize(I18n.get('', 'Por cobrar')),
      accessor: 'totalReceived',
      alignment: 'right',
      width: companyElectronic ? '15%' : '20%',
      Cell: ({ row }) => {
        const { original } = row;
        let duePay =
          original.total - (original.totalPaid || original.totalReceived);

        if (original?.totalReceived >= original?.total) {
          duePay = 0;
        }
        const decimal = !!get(original, 'decimalPrecision', null)
          ? Number(original.decimalPrecision)
          : companyDecimal;
        const fmt = getFmt(original, mainCurrency);
        return (
          <TableCellRegular
            value={new BigNumber(duePay).toFormat(decimal, fmt)}
          />
        );
      },
    },
    {
      Header: I18n.get('emissionStatus.column', 'Estado de emisión'),
      accessor: 'numberTemplate',
      width: '15%',
      partiblePosition: 'top-left',
      Cell: ({ row }) => {
        const { original } = row;
        return invoicesHistoryStrategy.getEmissionStatus({ invoice: original });
      },
    },
    {
      Header: capitalize(I18n.get('', 'Estado')),
      accessor: 'status',
      width: '10%',
      partiblePosition: 'top-right',
      Cell: ({ row }) => {
        const { status, offlineStatus, numberTemplate } = row.original;
        let icon, text;

        const documentType = get(numberTemplate, 'documentType', null);

        switch (offlineStatus) {
          case 'pending':
            icon = (
              <Icon
                icon={IconAlertTriangleFilled}
                color='#FBBF24'
                size='small'
              />
            );
            text = I18n.get('pending', 'pendiente');
            break;
          case 'syncing':
            icon = (
              <Icon
                icon={IconAlertTriangleFilled}
                color='#FBBF24'
                size='small'
              />
            );
            text = I18n.get('syncing', 'syncing');
            break;
          case 'error':
            icon = (
              <Icon
                icon={IconAlertOctagonFilled}
                color='#E11D48'
                size='small'
              />
            );
            text = I18n.get('error', 'error');
            break;
          default:
            icon =
              status === 'closed' ? (
                <Icon icon={IconCheck} color='#16A34A' size='small' />
              ) : (
                <Icon
                  icon={IconDotsCircleHorizontal}
                  color='#94A3B8'
                  size='small'
                />
              );
            text =
              status === 'closed'
                ? I18n.get(`${documentType}.charged`, `${status}`)
                : I18n.get(`invoiceStatus${status}`, `${status}`);
            break;
        }

        if (!isSplit) {
          return (
            <div className='d-flex align-items-center gap-2'>
              {icon}
              <Typography type='caption-regular' text={text} />
            </div>
          );
        }

        return <div className='d-flex align-items-center gap-2'>{icon}</div>;
      },
    },
  ];
  return columns.filter((column) => {
    if (!companyElectronic && column.accessor === 'numberTemplate') {
      return false;
    }
    return true;
  });
};

export default invoicesColumns;
