import {
  Container,
  EmptyState,
  Grid,
  PageHeading,
} from '@alegradev/smile-ui-react';
import { useHistory } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import Table from './table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermissionTo } from '../../../selectors/auth';
import { checkFeatureLimit } from '../../../reducers/membership';
import { useEffect } from 'react';
import { sendNewGTMEvent } from '../../../reducers/company';
import { IconLock, IconPlus } from '@tabler/icons-react';

function Main() {
  const { Wrapper } = Grid;
  const history = useHistory();
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);

  useEffect(() => {
    dispatch(sendNewGTMEvent('pos-contacts-visited'));
  }, []);

  if (!can('index', 'contacts')) {
    return (
      <Wrapper
        fullHeight
        fullWidth
        justify={{ lg: 'center' }}
        align={{ lg: 'center' }}
      >
        <EmptyState
          hideAction
          generalIcon={IconLock}
          title={I18n.get('youNeedPermissions', 'Necesitas autorización')}
          description={I18n.get(
            'youNeedPermissionsDescription',
            'Tu usuario tiene un perfil limitado, para ingresar a esta sección debes solicitar acceso a un usuario administrador.'
          )}
        />
      </Wrapper>
    );
  }

  return (
    <Container marginTop={30}>
      <PageHeading
        title={I18n.get('contacts', 'Contactos')}
        description={I18n.get(
          'contactsDescription',
          'Gestiona la información de los clientes que podrás asociar en tus documentos'
        )}
        descriptionExtra={{
          label: I18n.get('knowMore', 'Saber más'),
          onClick: () => null,
        }}
        actions={[
          {
            leftIcon: IconPlus,
            text: I18n.get('addContact', 'Nuevo contacto'),
            action: () =>
              dispatch(
                checkFeatureLimit('clients', () =>
                  history.push('/contacts/contact')
                )
              ),
            disabled: !can('add', 'contacts'),
            tooltip: !can('add', 'contacts')
              ? I18n.get(
                  'addContactsUnallow',
                  'Te hace falta el permiso para crear contactos'
                )
              : undefined,
          },
        ]}
      />
      <Table />
    </Container>
  );
}

export default Main;
