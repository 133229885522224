import { Field } from 'react-final-form';
import Typography from '../../../common/Typography';
import { useSelector } from 'react-redux';
import { isMexicoFeAvailable } from '../../../../utils/deploys/mexicoFeDeployGroups';
import useElectronicPayrollStatus from '../../../../hooks/useElectronicPayrollStatus/hook';
import { allRegimes } from '../../../countriesData/mexico/regimes';
import { DEFAULT_CFDI_USE } from '../../../countriesData/mexico/cfdiUses';
import configAPI from '../../../../reducers/configAPI';
import { client as clientSelector } from '../../../../selectors/activeInvoice';
import {
  MEXICO_DEFAULT_RFC,
  MEXICO_DEFAULT_OTHER_RFC,
} from '../../../countriesData/mexico/rfc';
import {
  country as countrySelector,
  companySelector,
} from '../../../../selectors/company';
import { capitalize, forEach, get } from 'lodash';
import { renderSelect } from '../../fields/V0/Fields';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { useEffect, useMemo, useState } from 'react';
import { I18n } from 'aws-amplify';
import { useDeviceType } from '@alegradev/smile-ui-react';

const UseField = ({ values }) => {
  const client = useSelector(clientSelector);
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const documentType = get(values, 'numeration.documentType', '');
  const { isActive } = useElectronicPayrollStatus();
  const [uses, setUses] = useState([]);
  const { deviceType } = useDeviceType();

  useEffect(() => {
    const loadCfdiUses = async () => {
      if (country !== COUNTRIES.MEXICO) return;
      const response = await configAPI.get('/cfdi-uses', {
        application_version: country,
      });
      setUses(get(response, 'data', []));
    };
    loadCfdiUses();
  }, [client]);

  const isUseDisabled = useMemo(() => {
    if (!client) return true;
    if (
      (client && client?.identification === MEXICO_DEFAULT_RFC) ||
      client?.identification === MEXICO_DEFAULT_OTHER_RFC
    )
      return true;
    return false;
  }, [client]);

  const isFeMexActive = useMemo(() => {
    return isMexicoFeAvailable(country, isActive);
  }, [country, company, isActive]);

  const useOptions = useMemo(() => {
    const options = [];
    if (country === COUNTRIES.MEXICO) {
      if (client) {
        forEach(client?.regimeObject, (value) => {
          if (allRegimes[value]) {
            forEach(uses, (use) => {
              const splitRegimes = use?.regimes?.split('|');
              if (splitRegimes.includes(`${allRegimes[value].code}`)) {
                options.push({
                  key: use.key,
                  value: use.value,
                });
              }
            });
          }
        });
      } else {
        options.push(DEFAULT_CFDI_USE);
      }
    }

    return options;
  }, [uses, client]);
  return country === COUNTRIES.MEXICO &&
    isFeMexActive &&
    documentType === 'invoice' ? (
    <div className='d-flex justify-content-center'>
      <Field
        name='use'
        className={`col-sm-12 ${deviceType === 'mobile' ? 'p-0' : 'pl-2 pr-0'}`}
        options={useOptions}
        label={
          <Typography
            text={capitalize(I18n.get('use', 'Uso'))}
            type='label-2'
            variant='secondary'
          />
        }
        defaultValue={useOptions.length > 1 ? null : useOptions[0]}
        getOptionLabel={(option) => option.value}
        getOptionValue={(option) => option.key}
        disabled={isUseDisabled}
        required
        menuPosition='absolute'
      >
        {(props) => (
          <>
            {renderSelect({
              ...props,
              fontSize: '16px',
              borderRadius: '12px',
              height: '48px',
              fontColor: '#334155',
            })}
          </>
        )}
      </Field>
    </div>
  ) : null;
};

export default UseField;
