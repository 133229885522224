import { capitalize, get } from 'lodash';
import BigNumber from 'bignumber.js';
import { I18n } from 'aws-amplify';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { useFormat } from '../../../hooks/useFormat';
import SavingIcon from './assets/SavingIcon.svg';
import { Typography } from '@alegradev/smile-ui-react';

const InvoiceSaving = ({ invoiceCreated }) => {
  const { fmt, decimal } = useFormat();
  const isElectronic = get(
    invoiceCreated,
    'numberTemplate.isElectronic',
    false
  );

  const deviceType = useDeviceType();

  return (
    <div className='pt-5 px-5'>
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <div className='mt-5 mb-4'>
          <img src={SavingIcon} />
        </div>
        <div className='d-flex gap-3'>
          <Typography
            text={
              isElectronic
                ? I18n.get('invoiceCreatedElectronicMessageTitle', 'Guardando')
                : I18n.get('saving', 'Guardando')
            }
            type='heading-4'
            variant='secondary'
          />
          <div className='dot-flashing'></div>
        </div>
        {!isElectronic && (
          <div
            className={`${deviceType !== 'mobile' && 'd-flex'} justify-content-center gap-2 mt-3`}
          >
            <Typography
              extraClassName={deviceType === 'mobile' ? 'text-center' : ''}
              type='body-2-regular'
              variant='secondary'
              text={I18n.get(
                'youCanPrintOrContinue',
                'Puedes imprimirla o crear una nueva venta mientras la guardamos.'
              )}
            />
          </div>
        )}
        {!isElectronic && (
          <div className='new-invoice-saved-total-container'>
            <div className='d-flex align-items-center gap-3 modal__invoice-success-total'>
              <div>
                <Typography
                  text={capitalize(I18n.get('total', 'total'))}
                  type='heading-4'
                  variant='secondary'
                />
              </div>
              <div>
                <Typography
                  text={new BigNumber(invoiceCreated.total).toFormat(
                    decimal,
                    fmt
                  )}
                  type='heading-4'
                  variant='secondary'
                />
              </div>
            </div>
            <div className='d-flex align-items-center gap-3 modal__invoice-success-total'>
              <div>
                <Typography
                  text={I18n.get('change', 'cambio')}
                  type='body-3-regular'
                  variant='secondary'
                />
              </div>
              <div>
                <Typography
                  text={new BigNumber(invoiceCreated?.cashReturned).toFormat(
                    decimal,
                    fmt
                  )}
                  type='body-3-regular'
                  variant='secondary'
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceSaving;
