import { I18n } from 'aws-amplify';
import { toLower } from 'lodash';

export const BaseInvoiceTextStrategy = {
  getSubmitButtonName: ({ values, isElectronic }) => {
    if (values?.transfer && !isElectronic) {
      return I18n.get('savePayment', 'Guardar pago');
    }

    if (isElectronic) {
      return I18n.get('emit', 'Emitir');
    }

    return I18n.get('checkIn', 'Facturar');
  },
  getNewSubmitButtonName: ({ values, numeration }) => {
    return I18n.get('continue', 'Continuar');
  },
  getModalTitle: (numeration) =>
    toLower(
      I18n.get(`invoiceModalTitle.${numeration?.documentType}`, 'factura')
    ),
};
