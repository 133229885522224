import { memo, useCallback, useEffect, useState } from "react";
import { I18n } from "@aws-amplify/core";
import Modal from "../../../common/Modal";
import RadioButton from "../../../svg/RadioButton";
import Switch from "../../../common/Switch";

const SelectItemVariantModel = props => {
  const [variantsSelected, setVariantsSelected] = useState([]);
  const [variantSelected, setVariantSelected] = useState(null);
  const [optionsSelected, setOptionsSelected] = useState([]);

  const checkIfVariantIsSelected = useCallback((variant) => {
    return variantsSelected?.some(value => value.id === variant?.id);
  }, [variantsSelected]);

  const checkedVariant = (variant) => {
    console.log("variant",variant, checkIfVariantIsSelected(variant));
    if (checkIfVariantIsSelected(variant)) {
      setVariantsSelected(() => variantsSelected?.filter((v) => v !== variant));
    } else {
      console.log("variant selected",variantsSelected);
      setVariantsSelected((prev) => [...prev, variant]);
      setVariantSelected(variant);
    }
  };
  
  const checkedIsOptionSelected = useCallback((option) => {
    return optionsSelected.some(opt => opt.id === option.id);
  }, [optionsSelected]);

  const selectedOption = (option) => {
    if (checkedIsOptionSelected(option)) {
      setOptionsSelected(optionsSelected.filter((v) => v.id !== option.id));
      return;
    } 
    
    setOptionsSelected([...optionsSelected, {...option, variant: variantSelected.id }]);
  };

  const saveVariants = () => {
    const variants = variantsSelected.map(variant => ({
      ...variant,
      options: optionsSelected.filter(option => option.variant === variant.id)
    }));

    props.handleVariantsSelected(variants);
    props.onRequestClose();
  };

  const AttributeComponent = memo(function Attribute({ variant }) {
    return (
      <div className={`item-variant ${variantSelected?.id === variant.id ? 'item-variant-selected' : null}`}>
        <span onClick={() => setVariantSelected(variant)}>{variant.name}</span>
        <Switch
          active={checkIfVariantIsSelected(variant)}
          onChange={() => checkedVariant(variant)}
          large
        />
      </div>
    );
  });

  const VariantComponent = memo(function Variant({ option, value}) {
    return (
      <div className="item-variant pointer" onClick={() => selectedOption(option)}>
        <RadioButton
          width="12"
          height="12"
          unfilled
          checked={checkedIsOptionSelected(option)}
        />
        <span>{value}</span>
      </div>
    );
  })

  const optionsFromVariants = useCallback(() => {
    const options = [];
    props.variants.forEach(variant => {
      const option = variant.options.map(option => ({...option, variant: variant.id}));
      options.push(...option);
    });
    return options;
  }, [props.variants]);

  const optionsFromSelectedVariants = useCallback(() => {
    const options = [];
    props.variantsSelected.forEach(variant => {
      const option = variant.options.map(option => ({...option, variant: variant.id}));
      options.push(...option);
    });
    return options;
  }, [props.variantsSelected]);

  useEffect(() => {
    const options = optionsFromVariants();
    setOptionsSelected((prev) => [...prev, ...options]);
  }, [props.variants, props.variantSelected, props.selectedVariant]);

  useEffect(() => {
    if (props.variantsSelected.length) {
      setVariantsSelected((prev) => [...prev, ...props.variantsSelected]);
      const defaultOptionsFromVariants = optionsFromVariants();
      const options = optionsFromSelectedVariants();
      const defaultOptions = defaultOptionsFromVariants.filter(option => !options.some(opt => opt.variant === option.variant));
      setOptionsSelected(() => [...defaultOptions, ...options]);
    }
  }, [props.variants, props.variantSelected, props.selectedVariant]);

  useEffect(() => {
    if (props.selectedVariant) {
      checkedVariant(props.selectedVariant);
    }
  }, [props.variants, props.variantSelected, props.selectedVariant]);
  
  return (
    <Modal 
      {...props}
      title={I18n.get("titleModalConfigItemsVariants", "Configurar variantes")}
      className="select-kit-item-modal"
    >
      <div className="subtitle">Crea cualidades o activa las opciones predefinidas para clasificar tus productos.</div>
      <div className="item-variant-container">
        <div className="items-variants pointer">
          {props.variants.map((variant, index) => (
            <AttributeComponent key={index} variant={variant} />
          ))}
        </div>
        <div className="variants">
          {variantSelected?.options.map((option, index) => (
            <VariantComponent key={index} option={option} value={option.value} />
          ))}
        </div>
      </div>
      <div className="items-variants-actions">
      <button
            type="button"
            className="btn btn-cancel text-capitalize-first"
            onClick={props.onRequestClose}
          >
            {I18n.get('cancel', 'cancelar')}
          </button>

          <button
            type="button"
            className="btn btn-submit absolute-center text-capitalize-first"
            onClick={saveVariants}
          >
            {I18n.get('save', 'guardar')}
          </button>
      </div>
    </Modal>
  );
};

export default SelectItemVariantModel;
