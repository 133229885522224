import { COUNTRIES } from '../../../../../utils/enums/countries';

import colombiaInitialValues from './colombia/initialValues';
import mexicoInitialValues from './mexico/initialValues';
import argentinaInitialValues from './argentina/initialValues';
import costaRicaInitialValues from './costaRica/initialValues';
import peruInitialValues from './peru/initialValues';
import republicaDominicanaInitialValues from './republicaDominicana/initialValues';
import spainInitialValues from './spain/initialValues';
import panamaInitialValues from './panama/initialValues';
import defaultInitialValues from './default/initialValues';
import { get } from 'lodash';

function initializeValues(props) {
  return {
    type: props.from === 'inventoryBill' ? 'provider' : 'client',
    statementAttached: true,
    term: props?.paymentTerms?.find((term) => term.id === '1')?.id || null,
    priceList: props?.priceLists?.find((term) => term.id === '1')?.id || null,
    accounting: {
      accountReceivable: get(
        props,
        'defaultAccountReceivableCategory.id',
        null
      ),
      debtToPay: get(props, 'defaultDebtToPayCategory.id', null),
    },
  };
}

function initialValuesPerCountry(props) {
  switch (props.country) {
    case COUNTRIES.COLOMBIA:
      return colombiaInitialValues(props);
    case COUNTRIES.MEXICO:
      return mexicoInitialValues(props);
    case COUNTRIES.ARGENTINA:
      return argentinaInitialValues(props);
    case COUNTRIES.COSTA_RICA:
      return costaRicaInitialValues(props);
    case COUNTRIES.PERU:
      return peruInitialValues(props);
    case COUNTRIES.REPUBLICA_DOMINICANA:
      return republicaDominicanaInitialValues(props);
    case COUNTRIES.SPAIN:
      return spainInitialValues(props);
    case COUNTRIES.PANAMA:
      return panamaInitialValues(props);
    default:
      return defaultInitialValues(props);
  }
}

function initialValues(props) {
  return {
    ...initializeValues(props),
    ...initialValuesPerCountry(props),
  };
}

export default initialValues;
