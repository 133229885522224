import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { get, capitalize } from 'lodash';
import { I18n } from '@aws-amplify/core';

import countries from '../../../../../components/countriesData/general/countries';
import panamaCountries from '../../../../../components/countriesData/panama/countries';
import colombiaAddresses from '../../../../../components/countriesData/colombia/citiesDepartments.json';
import costaRicaAddresses from '../../../../../components/countriesData/costaRica/districtsCantonsProvinces.json';
import neighborhoods from '../../../../../components/countriesData/costaRica/dataNeighborhood';
import argentinaProvinces from '../../../../../components/countriesData/argentina/provinces';
import argentinaCities from '../../../../../components/countriesData/argentina/cities.json';
import peruAddresses from '../../../../../components/countriesData/peru/address.json';
import dominicanaAddresses from '../../../../../components/countriesData/republicaDominicana/municipalityProvince.json';
import spainAddresses from '../../../../../components/countriesData/spain/municipalityProvince.json';
import panamaCities from '../../../../../components/countriesData/panama/cities.json';

import { renderField } from '../../../../../components/forms/fields/V2';
import {
  COUNTRIES,
  COUNTRIES_NAMES,
} from '../../../../../utils/enums/countries';
import { Grid } from '@alegradev/smile-ui-react';
import { useContactForm } from '../FormProvider';
import { identificationTypesEnum } from '../../../../../components/countriesData/costaRica/identificationTypes';
import { useSelector } from 'react-redux';
import { idCompanySelector } from '../../../../../selectors/company';
import {
  fetchDistricts,
  fetchNeighborhoods,
} from '../../../../../components/forms/company/BasicData/utils';

const colombiaAddress = (props) => {
  const { Col } = Grid;
  const identification = get(props, 'values.identification.type', '');
  const colombianContact = get(props, 'values.address.country', '') === 'COL';
  const foreignId =
    identification === 'DIE' ||
    identification === 'PP' ||
    identification === 'TE' ||
    identification === 'FOREIGN_NIT';
  const showCountry = !!identification && foreignId;
  const showCombinedAddress = !foreignId || colombianContact;
  const address = get(props, 'values.address.address', '');
  const combinedAddress = get(props, 'values.address.combined', '');

  const showFullAddress = () => {
    const identificationType =
      identification === 'CC' ||
      identification === 'NIT' ||
      identification === 'CE' ||
      identification === 'TI' ||
      identification === 'RC' ||
      identification === 'NUIP';
    return identificationType;
  };

  const isRequired = () => {
    if (!foreignId) {
      if (address || combinedAddress) {
        if (!combinedAddress) {
          const municipality = get(props, 'values.defaultAddress.combined', '');
          address && props.form.change('address.combined', municipality);
        }
        return true;
      }
    }
    return false;
  };

  const selectCountries =
    identification === 'FOREIGN_NIT'
      ? countries.filter((country) => country.key !== 'COL')
      : countries;

  return (
    <>
      {showCountry && (
        <Col xs={6} sm={12}>
          <Field
            name='address.country'
            component={renderField}
            search
            fieldType='rselect'
            options={selectCountries}
            getOptionLabel={(option) => option?.value}
            getOptionValue={(option) => option?.key}
            size='large'
            placeholder={I18n.get('select', 'Seleccionar')}
            label={capitalize(I18n.get('country', 'país'))}
          />
        </Col>
      )}

      {foreignId && props.isElectronic && (
        <Col xs={6} sm={12}>
          <Field
            name='address.city'
            component={renderField}
            type='text'
            label={capitalize(I18n.get('cityLabel', 'Ciudad'))}
          />
        </Col>
      )}

      {showCombinedAddress && (
        <Col xs={6} sm={12}>
          <Field
            name='address.combined'
            component={renderField}
            fieldType='rselect'
            placeholder={I18n.get('select', 'Seleccionar')}
            options={colombiaAddresses}
            getOptionLabel={(option) => option?.value}
            getOptionValue={(option) => option?.value}
            size='large'
            isClearable
            label={[
              capitalize(I18n.get('city', 'municipio')),
              capitalize(I18n.get('department', 'departamento')),
            ].join(' / ')}
            search
            canClear
            required={isRequired()}
          />
        </Col>
      )}

      <Col xs={showFullAddress() ? 12 : 6}>
        <Field
          name='address.address'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('address', 'Dirección'))}
          helpTooltip={I18n.get(
            'addressTooltip',
            'Debes incluir la dirección cuando realizas la venta porfuera de tu local o si eliges el municipio de tu cliente'
          )}
          required={isRequired()}
        />{' '}
      </Col>

      <Col xs={6}>
        <Field
          name='address.zipCode'
          component={renderField}
          type='number'
          label={capitalize(I18n.get('zipCode', 'Código postal'))}
        />
      </Col>
    </>
  );
};

const spainAddress = (props) => {
  const { Col } = Grid;
  const identification = get(props, 'values.identification.type', '');
  const showCombinedAddress = identification !== 'EXT';
  const showCountries = identification === 'EXT';
  return (
    <>
      {showCombinedAddress && (
        <Col xs={12}>
          <Field
            name='address.combined'
            component={renderField}
            fieldType='select'
            options={spainAddresses.map((item) => ({
              text: item.value,
              value: item.value,
            }))}
            placeholder={I18n.get('select', 'Seleccionar')}
            label={[
              capitalize(I18n.get('city', 'municipio')),
              capitalize(I18n.get('province', 'provincia')),
            ].join(' / ')}
            search
            required={identification === 'NIF'}
          />
        </Col>
      )}
      {showCountries && (
        <>
          <Col xs={6}>
            <Field
              name='address.country'
              component={renderField}
              fieldType='select'
              placeholder={I18n.get('select', 'Seleccionar')}
              options={countries?.map((item) => ({
                text: item.value,
                value: item.key,
              }))}
              label={capitalize(I18n.get('country', 'país'))}
              search
              required
            />
          </Col>
          <Col xs={6}>
            <Field
              name='address.city'
              component={renderField}
              type='text'
              label={capitalize(I18n.get('contact.ext.city', 'ciudad'))}
            />
          </Col>
        </>
      )}

      <Col xs={6}>
        <Field
          name='address.address'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('address', 'Dirección'))}
          required={identification === 'NIF'}
        />
      </Col>
      <Col xs={6}>
        <Field
          name='address.zipCode'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('zipCode', 'Código postal'))}
        />
      </Col>
    </>
  );
};

const getLabelDistrict = (option) => {
  if (!option.parentValue) return option.value;
  return [
    get(option, 'value', ''),
    get(option, 'parentValue', ''),
    get(option, 'provinceValue', ''),
  ].join(', ');
};

const costaRicaAddress = (props) => {
  const [costaRicaAddresses, setCostaRicaAddresses] = useState([]);
  const [neighborhoodsOptions, setNeighborhoodsOptions] = useState([]);
  const [loading, setLoading] = useState({
    districts: false,
    neighborhoods: false,
  });
  const { Col } = Grid;
  const identification = get(props, 'values.identification.type', '');
  const foreignId = identification === 'PE';
  const isFinalConsumer =
    identification === identificationTypesEnum.FINAL_CONSUMER;
  const combinedAddress = get(props, 'values.address.combined', '');
  const isNonDomiciledOrTaxpayer =
    identification === identificationTypesEnum.NON_DOMICILED_FOREIGNER ||
    identification === identificationTypesEnum.NON_TAXPAYER;

  const idCompany = useSelector(idCompanySelector);

  const getOptions = async () => {
    try {
      setLoading({ ...loading, districts: true });
      const newOptions = await fetchDistricts({ idCompany });
      const defaultValueAddress = get(props, 'values.address.combined', '');

      const defaultOption = newOptions.find((option) => {
        return (
          get(option, 'value', '') === get(defaultValueAddress, 'value', '') &&
          get(option, 'parentValue', '') ===
            get(defaultValueAddress, 'parentValue', '') &&
          get(option, 'provinceValue', '') ===
            get(defaultValueAddress, 'provinceValue', '')
        );
      });
      if (!!defaultOption) {
        props.form.change('address.combined', defaultOption);
      }
      setCostaRicaAddresses(newOptions);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading({ ...loading, districts: false });
    }
  };

  useEffect(() => {
    if (!idCompany) return;

    getOptions();
  }, [idCompany]);

  return (
    <>
      {!foreignId && (
        <Col xs={6} sm={12}>
          <Field
            name='address.combined'
            component={renderField}
            fieldType='rselect'
            options={costaRicaAddresses}
            isLoading={loading.districts}
            placeholder={I18n.get('select', 'Seleccionar')}
            label={[
              capitalize(I18n.get('district', 'distrito')),
              capitalize(I18n.get('canton', 'cantón')),
              capitalize(I18n.get('province', 'provincia')),
            ].join(' / ')}
            getOptionLabel={(option) => getLabelDistrict(option)}
            getOptionValue={(option) => getLabelDistrict(option)}
            search
            isDisabled={loading.districts}
            onChange={(option) => {
              props.form.change('address.combined', option);
            }}
            required={!isNonDomiciledOrTaxpayer && !isFinalConsumer}
          />
        </Col>
      )}

      {!foreignId && (
        <Col xs={6} sm={12}>
          <Field
            name='address.neighborhood'
            component={renderField}
            fieldType='text'
            label={capitalize(I18n.get('neighborhood', 'Barrio'))}
          />
        </Col>
      )}

      <Col xs={6} sm={12}>
        <Field
          name='address.address'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('address', 'Dirección'))}
          required={!isFinalConsumer}
        />
      </Col>
    </>
  );
};

const argentinaAddress = (props) => {
  const { Col } = Grid;
  const identification = get(props, 'values.identification.type', '');
  const provinceId = get(props, 'values.address.province', '');

  return (
    <>
      <Col xs={6}>
        <Field
          name='address.province'
          component={renderField}
          fieldType='select'
          options={argentinaProvinces.map((item) => ({
            text: item.value,
            value: item.value,
          }))}
          label={capitalize(I18n.get('province', 'Provincia'))}
          placeholder={I18n.get('select', 'Seleccionar')}
          required={
            props.isElectronic && !['CI', 'OTHER'].includes(identification)
          }
          search
        />
      </Col>

      <Col xs={6}>
        <Field
          name='address.city'
          component={renderField}
          fieldType='select'
          options={argentinaCities
            .filter((i) => i.parentValue === provinceId)
            .map((item) => ({
              text: item.value,
              value: item.value,
            }))}
          label={capitalize(I18n.get('city', 'Ciudad'))}
          placeholder={I18n.get('select', 'Seleccionar')}
          required={
            props.isElectronic && !['CI', 'OTHER'].includes(identification)
          }
          disabled={!provinceId}
          search
        />
      </Col>

      <Col xs={6}>
        <Field
          name='address.postalCode'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('zipCode', 'Código postal'))}
        />
      </Col>

      <Col xs={6}>
        <Field
          name='address.address'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('address', 'Dirección'))}
          required={
            props.isElectronic && !['CI', 'OTHER'].includes(identification)
          }
        />
      </Col>
    </>
  );
};

const peruAddress = (props) => {
  const { Col } = Grid;
  const { searchingClient } = useContactForm();
  const identification = get(props, 'values.identification.type', '');
  const localContact =
    get(props, 'values.address.country', '') === COUNTRIES_NAMES.PERU;
  const foreignId = !(identification === 'RUC' || identification === 'DNI');
  const showCountry = !!identification && foreignId;
  const showCombinedAddress = !foreignId || localContact;

  return (
    <>
      {showCountry && (
        <Col xs={12}>
          <Field
            name='address.country'
            magicLoading={searchingClient}
            placeholder={I18n.get('select', 'Seleccionar')}
            component={renderField}
            fieldType='select'
            options={countries?.map((item) => ({
              text: item.value,
              value: item.value,
            }))}
            label={capitalize(I18n.get('country', 'país'))}
            search
          />
        </Col>
      )}

      {showCombinedAddress && (
        <Col xs={12}>
          <Field
            name='address.combined'
            magicLoading={searchingClient}
            placeholder={I18n.get('select', 'Seleccionar')}
            component={renderField}
            fieldType='select'
            options={peruAddresses?.map((item) => ({
              text: item.caption,
              value: item.caption,
            }))}
            label={[
              capitalize(I18n.get('district', 'distrito')),
              capitalize(I18n.get('province', 'provincia')),
              capitalize(I18n.get('department', 'departamento')),
              capitalize(I18n.get('ubigeoCode', 'código ubigeo')),
            ].join(' / ')}
            search
          />
        </Col>
      )}
      {showCombinedAddress && (
        <Col xs={6}>
          <Field
            name='address.urbanization'
            magicLoading={searchingClient}
            component={renderField}
            type='text'
            label={capitalize(I18n.get('urbanization', 'Urbanización'))}
          />
        </Col>
      )}

      <Col xs={showCombinedAddress ? 6 : 12}>
        <Field
          name='address.address'
          magicLoading={searchingClient}
          component={renderField}
          type='text'
          label={capitalize(I18n.get('address', 'Dirección'))}
        />
      </Col>
    </>
  );
};

const dominicanaAddress = (props) => {
  const { Col } = Grid;
  const identification = get(props, 'values.identification.type', '');
  const localContact = get(props, 'values.address.country', '') === 'DOM';
  const foreignId = !(identification === 'RNC' || identification === 'CED');
  const showCountry = !!identification && foreignId;
  const showCombinedAddress = !foreignId || localContact;

  return (
    <>
      {showCountry && (
        <Col xs={12}>
          <Field
            name='address.country'
            component={renderField}
            fieldType='select'
            options={countries?.map((item) => ({
              text: item.value,
              value: item.value,
            }))}
            label={capitalize(I18n.get('country', 'país'))}
            placeholder={I18n.get('select', 'Seleccionar')}
            search
          />
        </Col>
      )}

      {showCombinedAddress && (
        <Col xs={12}>
          <Field
            name='address.combined'
            component={renderField}
            fieldType='select'
            options={dominicanaAddresses?.map((item) => ({
              text: item.value,
              value: item.value,
            }))}
            placeholder={I18n.get('select', 'Seleccionar')}
            label={[
              capitalize(I18n.get('city', 'municipio')),
              capitalize(I18n.get('province', 'provincia')),
            ].join(' / ')}
            search
          />
        </Col>
      )}

      <Col xs={showCombinedAddress ? 6 : 12}>
        <Field
          name='address.address'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('address', 'Dirección'))}
        />
      </Col>
    </>
  );
};

const panamaAddress = (props) => {
  const { Col } = Grid;
  const identificationType = get(props, 'values.identification.type', '');
  const kindOfPerson = get(props, 'values.identification.kindOfPerson', '');
  const isElectronic = get(props, 'values.isElectronic', '');
  const isRequiredField = () => {
    let isRequired = true;
    if (!identificationType && !kindOfPerson && !isElectronic) return false;
    if (
      identificationType === 'FINAL_CONSUMER' ||
      identificationType === 'FOREIGN' ||
      identificationType === 'FOREIGN_COMPANY'
    )
      isRequired = false;
    return isRequired;
  };
  return (
    <>
      {identificationType !== 'FOREIGN' &&
        identificationType !== 'FOREIGN_COMPANY' && (
          <Col xs={12}>
            <Field
              name='address.combined'
              placeholder={I18n.get('select', 'Seleccionar')}
              component={renderField}
              fieldType='select'
              options={panamaCities?.map((item) => ({
                text: `${item.value}, ${item.parentValue}, ${item.provinceValue}`,
                value: `${item.value}, ${item.parentValue}, ${item.provinceValue}`,
              }))}
              label={[
                capitalize(I18n.get('township', 'Corregimiento')),
                capitalize(I18n.get('district', 'Distrito')),
                capitalize(I18n.get('province', 'Provincia')),
              ].join(' / ')}
              search
              required={isRequiredField()}
            />
          </Col>
        )}

      {(identificationType === 'FOREIGN' ||
        identificationType === 'FOREIGN_COMPANY') && (
        <Col xs={6}>
          <Field
            name='address.country'
            component={renderField}
            fieldType='select'
            placeholder={I18n.get('select', 'Seleccionar')}
            options={panamaCountries.map((option) => ({
              text: option.value,
              value: option.key,
            }))}
            label={capitalize(I18n.get('country', 'país'))}
            search
            required={
              identificationType === 'FOREIGN' ||
              (isElectronic && identificationType === 'FOREIGN_COMPANY')
            }
          />
        </Col>
      )}

      <Col
        xs={
          identificationType === 'FOREIGN' ||
          identificationType === 'FOREIGN_COMPANY'
            ? 6
            : 12
        }
      >
        <Field
          name='address.address'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('address', 'Dirección'))}
          required={isRequiredField()}
        />
      </Col>
    </>
  );
};

const mexicoAddress = (props) => {
  const { Col } = Grid;
  const thirdType = get(props, 'values.thirdType', '');
  const posibleCountries =
    thirdType === 'NATIONAL'
      ? countries
      : countries.filter((country) => country.key !== 'MEX');

  useEffect(() => {
    if (thirdType === 'NATIONAL') {
      props.form.change(
        'address.country',
        countries.find((country) => country.key === 'MEX').key
      );
    } else {
      if (get(props, 'values.address.country', '') === 'MEX') {
        props.form.change('address.country', null);
      }
    }
  }, [thirdType]);

  return (
    <>
      <Col xs={6} sm={12}>
        <Field
          name='address.country'
          component={renderField}
          fieldType='select'
          placeholder={I18n.get('select', 'Seleccionar')}
          options={posibleCountries?.map((item) => ({
            text: item.value,
            value: item.key,
          }))}
          label={capitalize(I18n.get('country', 'país'))}
          search={true}
          required
          disabled={thirdType === 'NATIONAL'}
        />
      </Col>
      <Col xs={3} sm={6}>
        <Field
          name='address.zipCode'
          component={renderField}
          type='text'
          required
          label={capitalize(I18n.get('zipCode', 'Código postal'))}
        />
      </Col>
      <Col xs={3} sm={6}>
        <Field
          name='address.colony'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('colony', 'Colonia'))}
        />
      </Col>
      <Col xs={6} sm={6}>
        <Field
          name='address.street'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('street', 'Calle'))}
        />
      </Col>
      <Col xs={3} sm={6}>
        <Field
          name='address.exteriorNumber'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('exteriorNumber', 'Exterior'))}
        />
      </Col>
      <Col xs={3} sm={6}>
        <Field
          name='address.interiorNumber'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('interiorNumber', 'Interior'))}
        />
      </Col>
      <Col xs={4} sm={6}>
        <Field
          name='address.state'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('state', 'Estado'))}
        />
      </Col>
      <Col xs={4} sm={6}>
        <Field
          name='address.municipality'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('municipality', 'Municipio/delegación'))}
        />
      </Col>
      <Col xs={4} sm={6}>
        <Field
          name='address.locality'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('locality', 'Localidad'))}
        />
      </Col>
    </>
  );
};

const defaultAddress = (props) => {
  const { Col } = Grid;
  return (
    <>
      <Col xs={6}>
        <Field
          name='address.city'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('city', 'ciudad'))}
        />
      </Col>
      <Col xs={6}>
        <Field
          name='address.address'
          component={renderField}
          type='text'
          label={capitalize(I18n.get('address', 'Dirección'))}
        />
      </Col>
    </>
  );
};

const renderAddress = (props) => {
  switch (props.country) {
    case COUNTRIES.COLOMBIA:
      return colombiaAddress(props);
    case COUNTRIES.SPAIN:
      return spainAddress(props);
    case COUNTRIES.COSTA_RICA:
      return costaRicaAddress(props);
    case COUNTRIES.ARGENTINA:
      return argentinaAddress(props);
    case COUNTRIES.PERU:
      return peruAddress(props);
    case COUNTRIES.REPUBLICA_DOMINICANA:
      return dominicanaAddress(props);
    case COUNTRIES.PANAMA:
      return panamaAddress(props);
    case COUNTRIES.MEXICO:
      return mexicoAddress(props);
    default:
      return defaultAddress(props);
  }
};

export default renderAddress;
