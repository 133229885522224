export const InstitutionCodes = [
  { name: 'Ministerio de Hacienda', code: '01' },
  { name: 'Ministerio de Relaciones Exteriores y Culto', code: '02' },
  { name: 'Ministerio de Agricultura y Ganadería', code: '03' },
  { name: 'Ministerio de Economía, Industria y Comercio', code: '04' },
  { name: 'Cruz Roja Costarricense', code: '05' },
  { name: 'Benemérito Cuerpo de Bomberos de Costa Rica', code: '06' },
  { name: 'Asociación Obras del Espíritu Santo', code: '07' },
  {
    name: 'Federación Cruzada Nacional de protección al Anciano (Fecrunapa)',
    code: '08',
  },
  {
    name: 'Escuela de Agricultura de la Región Húmeda (EARTH)',
    code: '09',
  },
  {
    name: 'Instituto Centroamericano de Administración de Empresas (INCAE)',
    code: '10',
  },
  { name: 'Junta de Protección Social (JPS)', code: '11' },
  {
    name: 'Autoridad Reguladora de los Servicios Públicos (Aresep)',
    code: '12',
  },
  { name: 'Otros', code: '99' },
];

export const InstitutionCodesEnum = {
  MINISTRY_OF_FINANCE: '01',
  MINISTRY_OF_FOREIGN_AFFAIRS_AND_WORSHIP: '02',
  MINISTRY_OF_AGRICULTURE_AND_LIVESTOCK: '03',
  MINISTRY_OF_ECONOMY_INDUSTRY_AND_COMMERCE: '04',
  COSTA_RICAN_RED_CROSS: '05',
  BENEMERIT_FIRE_DEPARTMENT_OF_COSTA_RICA: '06',
  WORKS_OF_THE_HOLY_SPIRIT_ASSOCIATION: '07',
  NATIONAL_CRUSADE_FEDERATION_FOR_THE_PROTECTION_OF_THE_ELDERLY: '08',
  EARTH_REGION_AGRICULTURE_SCHOOL: '09',
  CENTRAL_AMERICAN_BUSINESS_ADMINISTRATION_INSTITUTE: '10',
  SOCIAL_PROTECTION_BOARD: '11',
  PUBLIC_SERVICES_REGULATORY_AUTHORITY: '12',
  OTHERS: '99',
};
