import { useDispatch, useSelector } from 'react-redux';
import {
  companySelector,
  country as countrySelector,
  electronicInvoicing,
  multicurrency,
  shiftsEnabled,
} from '../../../../selectors/company';
import { useInvoiceDetail } from '../context';
import {
  Icon,
  Toast,
  Tooltip,
  Typography,
  useModal,
} from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { capitalize, get, toUpper } from 'lodash';
import dayjs from 'dayjs';
import { allRegimes } from '../../../../components/countriesData/mexico/regimes';
import {
  activeCurrencies,
  getMainCurrency,
} from '../../../../selectors/currencies';
import { openModal } from '../../../../reducers/modals';
import { sendNewGTMEvent } from '../../../../reducers/company';
import { setPrint, setTypeToPrint } from '../../../../reducers/print';
import { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../../../components/common/Button';
import {
  IconLoader2,
  IconNote,
  IconPencil,
  IconPrinter,
  IconRotate,
  IconShare,
} from '@tabler/icons-react';
import { canEdit, canSendOrPrint } from '../../utils';
import { hasPermissionTo } from '../../../../selectors/auth';
import { Field, useForm, useFormState } from 'react-final-form';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { invoiceClients } from '../../../../selectors/clients';
import { renderClientName } from '../../../../components/invoices/utils';
import {
  renderDatePickerField,
  renderSelect,
} from '../../../../components/forms/fields/V0/Fields';
import { activeSellers } from '../../../../selectors/sellers';
import { setInvoice } from '../../../../reducers/editInvoice';
import { current, isOpen } from '../../../../selectors/shifts';
import { replaceAndParse } from '../../../../utils';
import { getNumber } from '../../../../utils/invoices';
import { syncOffline } from '../../../../reducers/activeInvoice';
import { canVoid } from '../utils';
import VoidInvoice from '../../components/VoidInvoice';
import ShareInvoice from '../../../../components/modals/ShareInvoice/ShareInvoice';
import LegalStatus from '../../components/LegalStatus';
import { InvoiceStatus } from '../../components/InvoiceStatus';
import InvoiceDetailActions from '../../components/InvoiceDetailActions';
import { InvoicesHistoryCountryStrategyFactory } from '../../strategies/InvoicesHistoryCountryStrategyFactory';
import { allNumerations } from '../../../../selectors/numerations';

const InvoiceDetailHeader = ({
  updateItemPrice,
  fetchData,
  closePartibleFn,
}) => {
  const clients = useSelector(invoiceClients);
  const { isEditable, setIsEditable, data } = useInvoiceDetail();
  const invoice = data;
  const country = useSelector(countrySelector);
  const isMulticurrency = useSelector(multicurrency);
  const mainCurrency = useSelector(getMainCurrency);
  const can = useSelector(hasPermissionTo);
  const currencies = useSelector(activeCurrencies);
  const [loading, setLoading] = useState(false);
  const sellers = useSelector(activeSellers);
  const isShiftEnabled = useSelector(shiftsEnabled);
  const isShiftOpen = useSelector(isOpen);
  const currentShift = useSelector(current);
  const company = useSelector(companySelector);
  const companyElectronic = useSelector(electronicInvoicing);
  const numerations = useSelector(allNumerations);
  const form = useForm();
  const { openModal: openNewModal } = useModal();
  const { values } = useFormState();

  const invoicesHistoryStrategy =
    InvoicesHistoryCountryStrategyFactory.createStrategy(country);

  const dispatch = useDispatch();

  useEffect(() => {
    if (invoice) {
      dispatch(setPrint({ type: 'invoice', value: data }));
    }
  }, [invoice, dispatch]);

  useEffect(() => {
    form.reset();
    setIsEditable(false);
  }, [data]);

  return (
    <>
      <div className='mb-4'>
        {!!invoice.offlineStatus && invoice.offlineStatus === 'error' && (
          <Toast
            type='error'
            title='Error'
            description={replaceAndParse(invoice.error)}
            closable={false}
            shadow={false}
          />
        )}
        <div className='w-100 d-flex mt-4 mb-5 justify-content-between'>
          <div>
            <Typography
              type='body-1-bold'
              variant='secondary'
              text={
                invoicesHistoryStrategy &&
                invoicesHistoryStrategy.getDetailInvoiceNumber({
                  invoice,
                  country,
                  numerations,
                })
              }
              withHtml
            />
            <Typography
              type='body-3-regular'
              variant='tertiary'
              text={I18n.get('', 'Creada el {{date}}').replace(
                '{{date}}',
                dayjs(
                  get(invoice, 'datetime', null) || get(invoice, 'timestamp')
                ).format('DD/MM/YYYY h:mm a')
              )}
            />
          </div>
          <InvoiceDetailActions feetchData={fetchData} invoice={invoice} />
        </div>

        <div className='w-100 d-flex justify-content-between'>
          <Typography
            type='body-1-bold'
            variant='secondary'
            text={I18n.get('', 'Datos generales')}
          />
        </div>
        <div className='row w-100'>
          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Cliente')}
            />
            <div className='mt-1' />
            {isEditable ? (
              <Field
                name='client'
                className='edit-field'
                height='2.4rem'
                fontSize='12px'
                options={
                  country !== COUNTRIES.MEXICO && can('view', 'contacts')
                    ? clients
                    : []
                }
                isLoading={loading}
                placeholder={toUpper(
                  I18n.get('selectAClient', 'selecciona un cliente')
                )}
                noOptionsMessage={() =>
                  can('view', 'contacts')
                    ? I18n.get(
                        'noResultsWereFound',
                        'No se encontraron resultados.'
                      )
                    : I18n.get(
                        'userNotAllowed.contacts.view',
                        'No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el POS correctamente.'
                      )
                }
                getOptionLabel={(option) => `${renderClientName(option)}`}
                getOptionValue={(option) => option.id}
                hideDropdownIndicator
                cacheOptions={false}
              >
                {(props) =>
                  renderSelect({
                    ...props,
                    borderRadius: '10px',
                    height: '3.2rem',
                    fontSize: '14px',
                    borderColor: '#94A3B866',
                  })
                }
              </Field>
            ) : (
              <Typography
                type='body-3-regular'
                variant='secondary'
                text={get(invoice, 'client.name')}
              />
            )}
          </div>
          {!isEditable && (
            <>
              <div className='col-md-4 col-sm-12 mt-4'>
                <Typography
                  type='label-2'
                  variant='secondary'
                  text={
                    invoicesHistoryStrategy &&
                    invoicesHistoryStrategy.getIdentificationLabel({
                      invoice,
                    })
                  }
                />
                <div className='mt-1' />
                <Typography
                  type='body-3-regular'
                  variant='secondary'
                  text={get(invoice, 'client.identification')}
                />
              </div>
              {country === COUNTRIES.MEXICO && (
                <div className='col-md-4 col-sm-12 mt-4'>
                  <Typography
                    type='label-2'
                    variant='secondary'
                    text={I18n.get('regime', 'Régimen')}
                  />
                  <div className='mt-1' />
                  <Typography
                    type='body-3-regular'
                    variant='secondary'
                    text={
                      get(invoice, 'regimeClient')
                        ? allRegimes[get(invoice, 'regimeClient')].label
                        : allRegimes.SIMPLIFIED_REGIME.label
                    }
                  />
                </div>
              )}
            </>
          )}

          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={capitalize(I18n.get('dueDate', 'Fecha de vencimiento'))}
            />
            <div className='mt-1' />
            {isEditable ? (
              <Field
                name='dueDate'
                className='edit-field'
                inputFormat='DD/MM/YYYY'
                component={renderDatePickerField}
                minDate={dayjs(get(invoice, 'dueDate', null))}
                disableMaxDate
              />
            ) : (
              <Typography
                type='body-3-regular'
                variant='secondary'
                text={dayjs(get(invoice, 'dueDate', null)).format('DD/MM/YYYY')}
              />
            )}
          </div>
          {companyElectronic && (
            <div className='col-md-4 col-sm-12 mt-4'>
              <Typography
                type='label-2'
                variant='secondary'
                text={I18n.get('emissionStatus.column', 'Estado de emisión')}
              />
              <div className='mt-1' />
              {invoicesHistoryStrategy &&
                invoicesHistoryStrategy.getLegalStatus({ invoice })}
            </div>
          )}

          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Estado')}
            />
            <div className='mt-1' />
            <InvoiceStatus invoice={invoice} />
          </div>

          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('currency', 'Moneda')}
            />
            <div className='mt-1' />

            {isEditable &&
            !get(invoice, 'payments.length') &&
            !!isMulticurrency ? (
              <Field
                name='currency'
                className={`edit-field ${!get(invoice, 'currency.main') ? 'currency' : ''}`}
                options={currencies}
                height='2.4rem'
                fontSize='12px'
                getOptionLabel={(option) => option.code}
                getOptionValue={(option) => option.code}
                hideDropdownIndicator
                showClearIndicator
                onChange={(value) => {
                  const currency = !!value ? value : mainCurrency;
                  if (get(values, 'items.length')) {
                    values.items.map((item, index) => {
                      form.change(`items.${index}`, {
                        ...updateItemPrice(item, { ...values, currency }),
                      });
                      return null;
                    });
                  }
                  return currency;
                }}
              >
                {(props) =>
                  renderSelect({
                    ...props,
                    borderRadius: '10px',
                    height: '3.2rem',
                    fontSize: '14px',
                    borderColor: '#94A3B866',
                  })
                }
              </Field>
            ) : (
              <Typography
                type='body-3-regular'
                variant='secondary'
                text={
                  !!get(invoice, 'currency.code')
                    ? get(invoice, 'currency.code')
                    : get(mainCurrency, 'code')
                }
              />
            )}
          </div>

          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('seller', 'Vendedor')}
            />
            <div className='mt-1' />
            {isEditable ? (
              <Field
                name='seller'
                component={renderSelect}
                options={sellers}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                hideDropdownIndicator
                showClearIndicator
                height='3.2rem'
                borderRadius='10px'
                borderColor='#94A3B866'
              />
            ) : (
              <Typography
                type='body-3-regular'
                variant='secondary'
                text={get(invoice, 'seller.name', '--')}
              />
            )}
          </div>
          {!!get(invoice, 'idShift', '') && (
            <div className='col-md-4 col-sm-12 mt-4'>
              <Typography
                type='label-2'
                variant='secondary'
                text={I18n.get('shiftNumber', 'Número de turno')}
              />
              <div className='mt-1' />
              <Typography
                type='body-3-regular'
                variant='secondary'
                text={get(invoice, 'idShift', '').toString()}
              />
            </div>
          )}
          {!!get(invoice, 'stamp.uuid') && (
            <div className='col-md-8 col-sm-12 mt-4'>
              <Typography
                type='label-2'
                variant='secondary'
                text={I18n.get('', 'Folio Fiscal')}
              />
              <div className='mt-1' />
              <Typography
                type='body-3-regular'
                variant='secondary'
                text={get(invoice, 'stamp.uuid', '').toString()}
              />
            </div>
          )}
        </div>
      </div>
      <div className='mb-4 mt-5'>
        <Typography
          type='body-1-bold'
          variant='secondary'
          text={I18n.get(
            'productsAndServicesSold',
            'Productos y servicios vendidos'
          )}
        />
      </div>

      <VoidInvoice
        invoice={invoice}
        setVoidLoading={setLoading}
        fetchData={fetchData}
        closePartibleFn={closePartibleFn}
      />
    </>
  );
};

export default InvoiceDetailHeader;
