// External imports
import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays'
import { useDispatch, useSelector } from 'react-redux';
import { get, zipWith, concat, capitalize } from 'lodash';
import { I18n } from '@aws-amplify/core';

// Local imports
import { renderSelect, renderField } from '../fields/V0/Fields';
import { openModal } from '../../../reducers/modals';
import { activeWarehouses } from '../../../selectors/warehouses';
import { activeVariants as activeVariantsSelector } from '../../../selectors/variants';
import { stationWarehouse } from '../../../selectors/app';
import EditItemVariant from './modals/EditItemVariant';
import VariantsImage from './VariantsImage';
import { Icon } from '@alegradev/smile-ui-react';
import { IconLock, IconLockOpen, IconPencil, IconPlus } from '@tabler/icons-react';
import SelectItemVariantModel from './modals/SelectItemVariant';

// Utility functions
// import { transformVariantWarehouses, productStatusOptions } from './utils'; // Assume these are refactored and moved to a utils file.


// Defines the options for product statuses with their corresponding values and labels.
const productStatusOptions = [
  {
    value: 'active',
    label: 'activeProducts', // Key for translation
    defaultLabel: 'Activos' // Default text
  },
  {
    value: 'inactive',
    label: 'inactiveProducts', // Key for translation
    defaultLabel: 'Inactivos' // Default text
  }
];

// A utility to transform variant warehouses data structure based on certain conditions.
const transformVariantWarehouses = (variant, warehouses) => {
  const singleWarehouse = variant?.inventory?.singleWarehouse;
  const warehousesLength = variant?.inventory?.warehouses?.length ?? 0;

  // When there's a single warehouse defined and no warehouses or exactly one warehouse in the inventory
  if (singleWarehouse && (warehousesLength === 0 || warehousesLength === 1)) {
    return {
      ...variant,
      inventory: {
        warehouses: [{
          initialQuantity: variant?.inventory?.singleWarehouseInitialQuantity,
          minQuantity: variant?.inventory?.warehouses?.[0]?.minQuantity,
          maxQuantity: variant?.inventory?.warehouses?.[0]?.maxQuantity,
          // Find the matching warehouse by ID
          warehouse: warehouses.find(t => t.id === singleWarehouse.id),
        }]
      }
    };
  }

  // When there are multiple warehouses in the inventory
  if (warehousesLength > 0) {
    return {
      ...variant,
      inventory: {
        warehouses: variant.inventory.warehouses.map(w => ({
          ...w,
          // Find the matching warehouse by ID and replace the warehouse info
          warehouse: warehouses.find(t => t.id === w.id),
        }))
      }
    };
  }

  // Return the original variant if none of the above conditions are met
  return variant;
}

const Variants = ({ fieldRefs, values, form, ...props }) => {
  const variantAttributesRef = useRef([]);
  // State hooks for modal and selected variant management
  const [variantModal, setVariantModal] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [openSelectItemVariantsModal, setOpenSelectItemVariantsModal] = useState(false);
  const [variantsSelected, setVariantsSelected] = useState([]);

  // Redux hooks for global state management
  const dispatch = useDispatch();
  const activeVariants = useSelector(activeVariantsSelector);
  const warehouses = useSelector(activeWarehouses);
  const currentWarehouse = useSelector(stationWarehouse);

  // Extracting variants and itemVariants
  const variants = Array.isArray(values?.variantAttributes) && values?.variantAttributes?.length > 0 ? values?.variantAttributes : variantAttributesRef.current;
  const itemVariants = values?.itemVariants;
  const isEditing = values?.isEditing ?? false;

  useEffect(() => {
    if(!values?.variantAttributes || values?.variantAttributes?.length === 0) {
      const currentVariants = variantAttributesRef.current?.length > 0 ? variantAttributesRef.current : variants;
      form.change('variantAttributes', currentVariants);
    }
  }, [variants, itemVariants, values?.variantAttributes]);

  // Function to open modal for item variants
  const openItemVariants = () => dispatch(openModal({ modal: 'variant' }));

  // Function to open variant item modal with selected index
  const openVariantItemModal = index => {
    setSelectedVariant(transformVariantWarehouses(itemVariants?.[index], warehouses));
    setSelectedIndex(index);
    setVariantModal(true);
  };

  useEffect(() => {
    if(variants.length > 0) {
      variantAttributesRef.current = variants;
    }
  }, [variants]);

  // Function to combine variants for generating item variants
  const combineVariants = (variants, index = 0, previous = []) => {
    if (index >= variants.length) return previous;

    const { id, options } = variants[index];
    let result = [];

    if (previous.length) {
      let previousOptions = [];
      let newOptions = [];

      previous.forEach(p => {
        options.forEach(option => {
          previousOptions.push(p);
          newOptions.push({ id, options: option });
        });
      });

      result = zipWith(previousOptions, newOptions, concat);
    } else {
      result = options.map(opt => ([{ id, options: opt }]));
    }

    return combineVariants(variants, index + 1, result);
  };

  // Function to calculate item variants based on variant attributes
  const calculateItemVariants = variantAttributes =>
    combineVariants(variantAttributes).map(variantAtt => ({
      inventory: {
        initialQuantity: null,
        singleWarehouse: currentWarehouse,
      },
      status: productStatusOptions[0],
      variantAttributes: variantAtt.map(({ id, options }) => ({
        id, options: [options]
      }))
    }));

  // Function to toggle item variant selection
  const toggleItemVariant = variant => {
    setSelectedVariant(variant);
    setOpenSelectItemVariantsModal(true);
  };

  const handleVariantsSelected = (selectedVariants) => {
    selectedVariants.sort((a, b) => a.id - b.id);
    setVariantsSelected(selectedVariants);
    form.change('variantAttributes', selectedVariants);
    form.change('itemVariants', calculateItemVariants(selectedVariants));
  };

  // Function to render variant inventory information
  const renderVariantInventory = variantWarehouses => {
    if (!variantWarehouses || variantWarehouses.length === 0) return null;

    if (variantWarehouses.length > 1) {
      return `${variantWarehouses.length} ${I18n.get('warehouses', 'bodegas')}`;
    }

    const warehouseName = warehouses.find(t => t.id === variantWarehouses[0]?.id)?.name || "";
    const initialQuantity = `Cantidad: ${parseInt(variantWarehouses[0]?.initialQuantity || 0)}`;
    const minQuantity = `Min: ${variantWarehouses[0]?.minQuantity ?? ''}`;
    const maxQuantity = `Max: ${variantWarehouses[0]?.maxQuantity ?? ''}`;

    return [warehouseName, initialQuantity, minQuantity, maxQuantity].filter(Boolean).join(' - ');
  };

  // Function to handle variant status toggling
  const handleVariantStatus = index => {
    const itemVariant = itemVariants?.[index];
    const isVariantActive = itemVariant?.status?.value === 'active';

    form.mutators.update('itemVariants', index, {
      ...itemVariant,
      inventory: {
        ...itemVariant?.inventory,
        singleWarehouseInitialQuantity: itemVariant?.inventory?.singleWarehouseInitialQuantity ?? 0,
      },
      status: isVariantActive ? productStatusOptions[1] : productStatusOptions[0],
    });
  };

  return (
    <>
      <div className="p-0 col-12 form-row form-field-array-wrapper">
        <div className="d-flex py-3 flex-wrap align-items-start">
          <button
            type="button"
            className="btn btn-secondary btn d-flex justify-content-center align-items-center mb-3 mr-4"
            onClick={() => openItemVariants()}
          >
            <Icon icon={IconPlus} extraClass="icon icon-primary mr-2" />
            {I18n.get('newVariant', 'Nueva variante')}
          </button>

          {activeVariants.map((variant, index) => (
            <button
              key={index}
              type="button"
              className={`btn mb-3 mr-4 btn-variant 
                ${!!variants && !!variants.find(v => v.id === variant.id) ? 'active' : ''}`}
              onClick={() => toggleItemVariant(variant)}
              disabled={isEditing}
            >
              {get(variant, 'name')}
            </button>
          ))}
        </div>

        <div ref={fieldRefs.itemVariants}></div>
        <FieldArray name="itemVariants">
          {({ fields, meta }) =>
            fields.map((name, index) => {
              return (
                <div
                  key={index} className="w-100"
                >
                  <div
                    className={`combo-item-container d-flex aling-items-center form-row mx-0
                    ${meta.submitFailed && !!meta.error && !!meta.error[index] ? 'border-danger border' : ''}`}
                    style={{ backgroundColor: `${get(values, `itemVariants.${index}.status.value`) === 'active' ? '' : '#F4F5FB'}` }}
                  >
                    <div className="pointer col-md-2 col-lg-2 d-none d-md-block">
                      <VariantsImage
                        form={form}
                        values={values}
                        index={index}
                        disabled={!(get(values, `itemVariants.${index}.status.value`) === 'active')}
                      />
                    </div>

                    <div
                      className="col-md-10 col-lg-9 item-container d-flex flex-column justify-content-center"
                    >
                      <p
                        className="pointer h4 mb-1 text-primary font-weight-bold"
                        onClick={() => {
                          if (get(values, `itemVariants.${index}.status.value`) === 'active')
                            openVariantItemModal(index)
                        }}
                      >
                        {!!get(fields, `value.${index}.variantAttributes.length`)
                          ? get(fields, `value.${index}.variantAttributes`)
                            .map(variantAtt => get(variantAtt, 'options.0.value')).join(' - ')
                          : null
                        }
                      </p>
                      <div className='d-flex justify-content-between align-items-start'>

                        {(!get(fields, `value.${index}.inventory.warehouses.length`, false) || get(fields, `value.${index}.inventory.warehouses.length`) <= 1) && (
                          <>
                            <Field
                              name={`${name}.inventory.singleWarehouse`}
                              component={renderSelect}
                              className="col-md-4 col-lg-5 pl-0"
                              options={warehouses}
                              getOptionLabel={option => option.name}
                              getOptionValue={option => option.id}
                              label={capitalize(I18n.get('warehouse', 'bodega'))}
                              validate={value => !get(value, 'id', null) ? capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) : null}
                              addOptionText={!!props.newWarehouseAction ? capitalize(I18n.get('newWarehouse', 'Nueva bodega')) : null}
                              addOptionAction={() => props.newWarehouseAction(index)}
                              defaultValue={currentWarehouse}
                              height={"3rem"}
                              fontSize={"12px"}
                              disabled={!(get(values, `itemVariants.${index}.status.value`) === 'active')}
                              required
                            />
                            <Field
                              name={`${name}.inventory.singleWarehouseInitialQuantity`}
                              component={renderField}
                              className="col-md-4 col-lg-5 pl-0"
                              type="number"
                              label={capitalize(I18n.get('initialQuantity', 'cantidad inicial'))}
                              // validate={value => !(value) ? capitalize(I18n.get('indicateTheVariantQuantity', 'Indica aquí las cantidades en tus bodegas')) : null}
                              height={"3rem"}
                              fontSize={"12px"}
                              disabled={!(get(values, `itemVariants.${index}.status.value`) === 'active')}
                              required
                            />
                          </>
                        )}

                        {get(fields, `value.${index}.inventory.warehouses.length`) > 1 && (
                          <p className={` pl-1 h5 m-0 text-subtitle
                        ${meta.submitFailed && !!meta.error && !!meta.error[index] ? 'text-danger' : ''} align-self-center`}
                          >
                            {renderVariantInventory(get(fields, `value.${index}.inventory.warehouses`))}
                          </p>
                        )}
                        <Field
                          name={`${name}.status`}
                          className="d-none"
                          component={renderSelect}
                          getOptionLabel={option => capitalize(I18n.get(option.label, option.defaultLabel))}
                          getOptionValue={option => option.value}
                          options={productStatusOptions}
                          defaultValue={productStatusOptions[0]}
                        />
                        <div className='d-flex mt-4'>
                          <button
                            type="button"
                            className="btn button-transparent px-0 mx-1 mt-3"
                            style={{ border: "none", outline: "none", boxShadow: "none" }}
                            onClick={() => openVariantItemModal(index)}
                            title={capitalize(I18n.get('edit', 'Editar'))}
                            disabled={!(get(values, `itemVariants.${index}.status.value`) === 'active')}
                          >
                            <Icon icon={IconPencil} extraClass="icon-primary ml-1" />
                          </button>
                          <button
                            type="button"
                            className="btn button-transparent px-0 mx-1 mt-3"
                            style={{ border: "none", outline: "none", boxShadow: "none" }}
                            onClick={() => handleVariantStatus(index, meta)}
                            title={get(values, `itemVariants.${index}.status.value`) === 'active'
                              ? capitalize(I18n.get('deactivate', 'desactivar'))
                              : capitalize(I18n.get('activate', 'activar'))
                            }
                          >
                            {get(values, `itemVariants.${index}.status.value`) === 'active'
                              ? <Icon icon={IconLockOpen} extraClass="icon-primary" />
                              : <Icon icon={IconLock} extraClass="icon-primary" />
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </FieldArray>
      </div>

      <EditItemVariant
        isOpen={variantModal}
        onRequestClose={() => setVariantModal(false)}
        variant={selectedVariant}
        form={form}
        index={selectedIndex}
      />
      {openSelectItemVariantsModal && (
        <SelectItemVariantModel
          isOpen={openSelectItemVariantsModal}
          onRequestClose={() => setOpenSelectItemVariantsModal(false)}
          variants={activeVariants}
          variantsSelected={variantsSelected}
          handleVariantsSelected={handleVariantsSelected}
          selectedVariant={selectedVariant}
        />
      )}
    </>
  );
}

export default Variants;