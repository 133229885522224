export const exonerationDocumentTypes = [
  {
    type: '38',
    description: 'Compras autorizadas por la Dirección General de Tributación',
    code: '01',
  },
  {
    type: null,
    description: 'Ventas exentas a diplomáticos',
    code: '02',
  },
  {
    type: null,
    description: 'Autorizado por Ley especial',
    code: '03',
  },
  {
    type: null,
    description:
      'Exenciones Dirección General de Hacienda Autorización Local Genérica',
    code: '04',
  },
  {
    type: '39',
    description:
      'Exenciones Dirección General de Hacienda Transitorio V (servicios de ingeniería, arquitectura, topografía obra civil)',
    code: '05',
  },
  {
    type: '40',
    description:
      'Servicios turísticos inscritos ante el Instituto Costarricense de Turismo (ICT)',
    code: '06',
  },
  {
    type: '41',
    description:
      'Transitorio XVII (Recolección, Clasificación, almacenamiento de Reciclaje y reutilizable)',
    code: '07',
  },
  {
    type: null,
    description: 'Exoneración a Zona Franca',
    code: '08',
  },
  {
    type: null,
    description:
      'Exoneración de servicios complementarios para la exportación artículo 11 RLIVA',
    code: '09',
  },
  {
    type: '42',
    description: 'Órgano de las corporaciones municipales',
    code: '10',
  },
  {
    type: '43',
    description:
      'Exenciones Dirección General de Hacienda Autorización de Impuesto Local Concreta',
    code: '11',
  },
  {
    type: null,
    description: 'Otros',
    code: '99',
  },
];

export const EXONERATION_DOCUMENT_TYPES = {
  PURCHASES_AUTHORIZED_BY_TAX_AUTHORITY: '01',
  TAX_EXEMPT_SALES_TO_DIPLOMATS: '02',
  AUTHORIZED_BY_SPECIAL_LAW: '03',
  GENERAL_LOCAL_AUTHORIZATION_FROM_TREASURY: '04',
  ENGINEERING_ARCHITECTURE_SURVEYING_EXEMPTION: '05',
  TOURISM_SERVICES_REGISTERED_WITH_ICT: '06',
  RECYCLING_AND_REUSABLES_EXEMPTION: '07',
  FREE_TRADE_ZONE_EXEMPTION: '08',
  COMPLEMENTARY_EXPORT_SERVICES_EXEMPTION: '09',
  MUNICIPAL_CORPORATION_ENTITIES: '10',
  LOCAL_TAX_AUTHORIZATION_FROM_TREASURY: '11',
  OTHERS: '99',
};
