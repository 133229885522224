export const NEW_INVOICE_MODAL_USE = 'pos-new-invoice-modal-use';
export const SURVEY_FINISHED_NOTIFICATION =
  'pos-nps-survey-finished-notification';
export const SURVEY_FIRST_NOTIFICATION = 'pos-nps-survey-first-notification';
export const DEFAULT_PAYMENT_METHODS_INCLUDE_COL = [
  'cash',
  'debit',
  'credit',
  'transfer',
  'combined',
  'nequiPush',
  'bancolombiaQR',
  'nequi',
];
