import { I18n } from 'aws-amplify';
import { capitalize, get, replace } from 'lodash';
import { COUNTRIES } from '../../../utils/enums/countries';

export const SHARE_METHODS = {
  EMAIL: 'email',
  WHATSAPP: 'whatsapp',
};
export const getTitle = (invoiceCreated, country) => {
  const documentType = get(invoiceCreated, 'numberTemplate.documentType');
  const isElectronic = !!get(invoiceCreated, 'numberTemplate.isElectronic');
  const prefix = get(invoiceCreated, 'numberTemplate.prefix');
  const linkedDevices = !!get(
    invoiceCreated,
    'numberTemplate.linkedDevices',
    null
  );

  switch (country) {
    case COUNTRIES.PANAMA:
      if (isElectronic) {
        return I18n.get(
          'electronicInvoiceSaved',
          'Factura electrónica guardada'
        );
      }
      break;
    case COUNTRIES.COSTA_RICA:
      if (isElectronic && documentType === 'saleTicket') {
        return I18n.get('saleTicketSaved', 'Tiquete guardado');
      }
      break;

    case COUNTRIES.COLOMBIA:
      if (documentType === 'saleTicket' && isElectronic) {
        return I18n.get('documentPOSSaved', 'Documento POS guardado');
      }
      break;

    case COUNTRIES.PERU:
    case COUNTRIES.COSTA_RICA:
      if (documentType === 'saleTicket') {
        return I18n.get(
          'saleTicketSaved',
          country === COUNTRIES.PERU ? 'Boleta guardada' : 'Tiquete guardado'
        );
      }
      break;

    case COUNTRIES.SPAIN:
      if (documentType === 'saleTicket') {
        return I18n.get('invoiceModalTitle.saleTicketSaved', 'Ticket guardado');
      }
      break;
    case COUNTRIES.MEXICO:
      if(documentType === 'saleTicket'){
        return capitalize(I18n.get('invoiceSaved', 'factura guardada'));
      }
      return  capitalize(I18n.get('invoiceSaved.title', 'factura guardada'));
    default:
      return capitalize(I18n.get('invoiceSaved', 'factura guardada'));
  }

  return capitalize(I18n.get('invoiceSaved', 'factura guardada'));
};

export const getInvoiceMessage = (invoiceCreated, country) => {
  const documentTypeKey = get(invoiceCreated, 'numberTemplate.documentType');
  const isElectronic = !!get(invoiceCreated, 'numberTemplate.isElectronic');
  const prefix = get(invoiceCreated, 'numberTemplate.prefix');
  const linkedDevices = !!get(
    invoiceCreated,
    'numberTemplate.linkedDevices',
    null
  );

  let documentType = I18n.get('invoice', 'factura guardada');

  switch (country) {
    case COUNTRIES.PANAMA:
      if (isElectronic) {
        documentType = I18n.get('electronicInvoice', 'Factura electrónica');
      }
      break;

    case COUNTRIES.COLOMBIA:
      if (prefix === 'EPOS' || linkedDevices) {
        documentType = I18n.get('sale', 'Venta');
      } else if (documentTypeKey === 'saleTicket' && isElectronic) {
        documentType = I18n.get(
          'electronicPOSDocument',
          'Documento POS electrónico'
        );
      }
      break;

    case COUNTRIES.PERU:
    case COUNTRIES.COSTA_RICA:
      if (documentTypeKey === 'saleTicket') {
        documentType = I18n.get(
          'saleTicket',
          country === 'peru' ? 'Boleta' : 'Tiquete'
        );
      }
      break;

    case COUNTRIES.SPAIN:
      if (documentTypeKey === 'saleTicket') {
        documentType = I18n.get('saleTicket', 'Ticket');
      }
      break;

    default:
      documentType = I18n.get('invoice', 'factura guardada');
  }

  return replace(
    I18n.get('invoiceCreatedSuccessfullyMessage', 'Tu {} se guardó con éxito.'),
    '{}',
    documentType
  );
};
