import React from 'react';
import { I18n } from '@aws-amplify/core';

import {
  Button,
  Card,
  Divider,
  Grid,
  Icon,
  Typography,
  useModal,
} from '@alegradev/smile-ui-react';
import { COUNTRIES } from '../../../../../utils/enums/countries';
import ExonerationModal from '../modals/exonerations/Exonerations';
import { useElectronicInvoiceStatus } from '../../../../../hooks/useElectronicPayrollStatus';
import { IconPencil, IconPlus, IconTrash } from '@tabler/icons-react';

const renderCostaRicaExonerations = (props) => {
  const { Col, Wrapper } = Grid;
  const { openModal } = useModal();
  const { isActive } = useElectronicInvoiceStatus();

  const handleCreateExoneration = (values) => {
    const formExonerations = props?.values?.exonerations || [];
    const newExoneration = formExonerations?.find(
      (exoneration) => exoneration?.key === values?.key
    );
    if (newExoneration) {
      const index = formExonerations.indexOf(newExoneration);
      formExonerations[index] = values;
    } else {
      formExonerations.push(values);
    }
    props?.form?.change('exonerations', formExonerations);
  };

  const handleOpenExonerationModal = (exoneration = null) => {
    openModal({
      type: 'default',
      size: 'large',
      title: I18n.get(
        'modifyExoneration',
        'Modificar exoneración de impuestosos'
      ),
      name: 'exoneration',
      description: I18n.get(
        'modifyExonerationDescription',
        'Edita o agrega la información de la exoneración de tu cliente'
      ),
      props: {
        exoneration,
        onCreated: (values) => handleCreateExoneration(values),
      },
      component: ExonerationModal,
    });
  };

  const handleDeleteExoneration = (exoneration) => {
    const formExonerations = props?.values?.exonerations || [];
    const newExonerations = formExonerations.filter(
      (item) => item?.key !== exoneration?.key
    );
    props?.form?.change('exonerations', newExonerations);
  };

  if (!isActive) return null;

  return (
    <Col xs={12}>
      <Card
        title={I18n.get('exonerations', 'Exoneraciones')}
        description={I18n.get(
          'addExonerationDescription',
          'Agrega la información de la exoneración de tu contacto'
        )}
        action={{
          label: I18n.get('addExoneration', 'Agregar exoneración'),
          leftIcon: IconPlus,
          variant: 'default',
          emphasis: 'subtle',
          onClick: () => handleOpenExonerationModal(),
        }}
      >
        <Wrapper gap={10} direction={{ lg: 'column' }} extraClasses='w-50'>
          {Array.isArray(props?.values?.exonerations) &&
            props?.values?.exonerations?.length > 0 &&
            props?.values?.exonerations?.map((exoneration, index) => (
              <Wrapper
                direction={{ lg: 'column' }}
                gap={10}
                key={exoneration?.documentNumber}
              >
                <Typography
                  type='label-2'
                  text={`${I18n.get('exoneration', 'Exoneración')} ${index + 1}`}
                />
                <Wrapper justify={{ lg: 'between' }} align={{ lg: 'center' }}>
                  <Typography
                    type='body-2-regular'
                    text={exoneration?.documentNumber}
                  />
                  <Wrapper>
                    <Button
                      emphasis='text'
                      onlyIcon={IconPencil}
                      size='small'
                      type='button'
                      onClick={() => handleOpenExonerationModal(exoneration)}
                    />

                    <Button
                      emphasis='text'
                      onlyIcon={IconTrash}
                      size='small'
                      type='button'
                      onClick={() => handleDeleteExoneration(exoneration)}
                    />
                  </Wrapper>
                </Wrapper>
                <Divider margin='0' />
              </Wrapper>
            ))}
        </Wrapper>
      </Card>
    </Col>
  );
};

const renderExonerations = (props) => {
  switch (props?.country) {
    case COUNTRIES.COSTA_RICA:
      return renderCostaRicaExonerations(props);
    default:
      return null;
  }
};

export default renderExonerations;
