import { I18n } from 'aws-amplify';

export const empty = (history, can, handleCreateBill) => ({
  title: I18n.get(
    'noBills',
    '¡Aún no has creado tu primera factura de compra!'
  ),
  description: I18n.get(
    'noBillsDescription',
    '¡Registra tus compras y mantén tu inventario actualizado!'
  ),
  action: {
    label: I18n.get('newBill', 'Nueva compra'),
    disabled: !can('add', 'bills'),
    handler: handleCreateBill,
  },
});
