import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';

import { renderNewSendEmail, renderSendEmail } from '../fields/V0/Fields';
import { useSelector } from 'react-redux';
import {
  companySelector,
  country as countrySelector,
} from '../../../selectors/company';
import { showNewInvoiceModal } from '../../modals/newInvoice/utils';

const SendInvoiceEmail = ({ invalid, submitting }) => {
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const isNewInvoiceModal = showNewInvoiceModal({ country, company });
  return (
    <Field
      name='email'
      component={isNewInvoiceModal ? renderNewSendEmail : renderSendEmail}
      placeholder={I18n.get('clientEmailPlaceholder', 'Correo')}
      type='email'
      disabled={invalid || submitting}
      submitting={submitting}
    />
  );
};

export default SendInvoiceEmail;
