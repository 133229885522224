import { Icon, Typography } from '@alegradev/smile-ui-react';
import { IconCheck, IconFileSymlink, IconProgress } from '@tabler/icons-react';
import { I18n } from 'aws-amplify';
import { get } from 'lodash';
import { getNumber } from '../../../utils/invoices';

export class BaseInvoicesHistoryStrategy {
  getIdentificationLabel({ invoice }) {
    return I18n.get('identification.invoice.detail', 'Identificación');
  }
  getDetailInvoiceNumber({ invoice, country }) {
    return getNumber(invoice, country);
  }
  getInvoiceNumber({ invoice }) {
    const fullNumber = get(invoice, 'numberTemplate.fullNumber', null);
    const prefixWithNumber = `${get(invoice, 'numberTemplate.prefix', null)}${get(invoice, 'numberTemplate.number', null)}`;
    return (
      I18n.get(`documentType.${get(invoice, 'numberTemplate.documentType')}`) +
      ' ' +
      (fullNumber || prefixWithNumber)
    );
  }
  getEmissionStatus({ invoice }) {
    return (
      <div className='d-flex align-items-center gap-2'>
        <Typography
          type='caption-italic'
          text='No electrónica'
          variant='tertiary'
        />
      </div>
    );
  }
  getLegalStatus({ invoice }) {
    return null;
  }
}
