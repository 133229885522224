import React from 'react';
import { I18n } from '@aws-amplify/core';

import ElectronicPayrollStatus from '../../../settings/Invoices/ElectronicPayrollStatus';
import useElectronicPayrollStatus from '../../../../hooks/useElectronicPayrollStatus/hook';
import NavigationMenu from '../../../common/Navigation';
import { useDispatch, useSelector } from 'react-redux';
import { useHeader } from '../../context/header.context';
import { country as countrySelector } from '../../../../selectors/company';
import { Icon, useModal } from '@alegradev/smile-ui-react';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { offlineData } from '../../../../selectors/app';
import NewLogoutConfirm from '../../../modals/NewLogoutConfirm/NewLogoutConfirm';
import { logout } from '../../../../reducers/app';
import DownloadAppOption from '../../../settings/DownloadApp/DownloadAppOption';
import { useDetectOS } from '../../../../hooks/useDetectOS';
import { IconChartBar, IconChecklist, IconExternalLink, IconLock, IconLogout, IconSettings, IconUserCircle } from '@tabler/icons-react';
import { userSelector } from '../../../../selectors/auth';
import { checkCanDownloadApp } from '../../../../utils/desktop';

const MenuOptions = () => {
  const { setActiveMenu } = useHeader();
  const country = useSelector(countrySelector)
  const { isActive, canElectronicPayroll } = useElectronicPayrollStatus();
  const dispatch = useDispatch();
  const areDataOffline = useSelector(offlineData);
  const installerType = useDetectOS();
  const user = useSelector(userSelector)

  const {openModal: openNewModal} = useModal();

  return (
    <>
      <NavigationMenu
        items={[
          {
            to: 'electronicMenu',
            menuName: 'electronicMenu',
            isMenu: false,
            hover: !isActive,
            hidden: !canElectronicPayroll || country === COUNTRIES.COLOMBIA,
            component: <ElectronicPayrollStatus />,
            action: () => setActiveMenu(''),
            layout: 'big',
          },
          {
            to: 'downloadApp',
            menuName: 'downloadApp',
            isMenu: false,
            hidden: !checkCanDownloadApp(installerType, user.email, country),
            component: <DownloadAppOption />,
            action: () => setActiveMenu(''),
            layout: 'big',
          },
          {
            to: `${process.env.REACT_APP_ALEGRA_SMILE}consumption`,
            menuName: 'planConsumption',
            target: '_blank',
            icon: <Icon icon={IconChartBar} size='small' />,
            title: I18n.get('planConsumption', 'Consumo del plan'),
            isMenu: false,
            extraIcon: <Icon icon={IconExternalLink} size='small' />,
            action: () => setActiveMenu(''),
          },
          {
            to: `${process.env.REACT_APP_ALEGRA_SMILE}profile`,
            menuName: 'profile',
            target: '_blank',
            icon: <Icon icon={IconUserCircle} size='small' />,
            title: I18n.get('profile', 'Mi Perfil'),
            isMenu: false,
            extraIcon: <Icon icon={IconExternalLink} size='small' />,
            action: () => setActiveMenu(''),
          },
          {
            to: `${process.env.REACT_APP_ALEGRA_SMILE}enablements`,
            menuName: 'enablements',
            target: '_blank',
            icon: <Icon icon={IconChecklist} size='small' />,
            title: I18n.get('dianEnablements', 'Habilitación DIAN'),
            hidden: country !== COUNTRIES.COLOMBIA,
            isMenu: false,
            extraIcon: <Icon icon={IconExternalLink} size='small' />,
            action: () => setActiveMenu(''),
          },
          {
            to: `${process.env.REACT_APP_ALEGRA_SMILE}security`,
            target: '_blank',
            menuName: 'security',
            icon: <Icon icon={IconLock} size='small' />,
            title: I18n.get('security', 'Seguridad'),
            isMenu: false,
            extraIcon: <Icon icon={IconExternalLink} size='small' />,
            action: () => setActiveMenu(''),
          },
          {
            to: `/settings`,
            menuName: 'security',
            icon: <Icon icon={IconSettings} size='small' />,
            title: I18n.get('settings', 'Configuraciones'),
            isMenu: false,
            action: () => setActiveMenu(''),
          },
          {
            menuName: 'logout',
            icon: (
              <div>
                <Icon icon={IconLogout} size='small' />
              </div>
            ),
            title: I18n.get('logout', 'cerrar sesión'),
            action: () =>
              areDataOffline
                ? openNewModal({
                    component: NewLogoutConfirm,
                    name: 'logoutConfirm',
                    hideCloseButton: true,
                    title: I18n.get('logout', 'cerrar sesión'),
                  })
                : dispatch(logout()),
            isMenu: false,
            dataTestId: 'logout-component',
            errorIndicator: areDataOffline,
          },
        ]}
      />
    </>
  );
};

export default MenuOptions;