import { get } from 'lodash';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { getHoursBetweenDates, getLocalDate, toast } from '../../../../utils';
import { isElegibleForApplyRefund } from '../../../../components/invoices/utils';
import alegraAPI from '../../../../reducers/alegraAPI';
import parse from 'html-react-parser';
import { handleError } from '../../../../utils/errors';
import { I18n } from 'aws-amplify';

export const canVoid = ({
  invoice,
  country,
  isShiftEnabled,
  isShiftOpen,
  can,
  currentShift,
  company,
}) => {
  if (!invoice) return false;

  if (
    country === COUNTRIES.MEXICO &&
    (invoice?.numberTemplate?.documentType === 'invoice' ||
      invoice?.associatedInvoice?.originApp === 'SELF_INVOICING')
  )
    return false;

  let result =
    get(invoice, 'status') !== 'void' &&
    (!isShiftEnabled ||
      (isShiftEnabled &&
        isShiftOpen === true &&
        get(invoice, 'idShift') === get(currentShift, 'idLocal')));

  if (['peru'].includes(country))
    result = result && !get(invoice, 'numberTemplate.isElectronic');

  if (country === 'republicaDominicana')
    result =
      result &&
      !get(invoice, 'stamp') &&
      !get(invoice, 'numberTemplate.isElectronic');

  if (
    country === 'panama' &&
    !!get(invoice, 'stamp') &&
    !!get(invoice, 'stamp.date') &&
    get(invoice, 'numberTemplate.isElectronic')
  )
    result =
      result &&
      getHoursBetweenDates(
        get(invoice, 'stamp.date'),
        getLocalDate(new Date(), country)
      ) <= 180;

  if (country === 'colombia') {
    const numerationIsElectronic = get(invoice, 'numberTemplate.isElectronic');
    const invoiceEmissionAccepted = get(
      invoice,
      'stamp.legalStatus',
      ''
    ).includes('STAMPED_AND_ACCEPTED');

    if (
      isElegibleForApplyRefund(invoice, country, company) &&
      invoiceEmissionAccepted
    )
      result = result && true;
    else if (numerationIsElectronic) result = result && false;
  }

  result = result && can('void', 'invoices');

  return result;
};

export const canStamp = ({ invoice, country, numerations, can }) => {
  if (!invoice) return false;

  const allowedCountries = [
    COUNTRIES.COSTA_RICA,
    COUNTRIES.ARGENTINA,
    COUNTRIES.COLOMBIA,
    COUNTRIES.PERU,
    COUNTRIES.REPUBLICA_DOMINICANA,
    COUNTRIES.PANAMA,
    COUNTRIES.SPAIN,
  ];

  if (!allowedCountries.includes(country)) return false;

  const isInvoiceStamped = invoice?.stamp?.date ?? null;
  const invoiceNumeration = numerations.find(
    (n) => Number(n.id) === Number(invoice?.numberTemplate?.id)
  );
  const IN_PROCESS_LEGAL_STATUS = 'STAMPED_AND_WAITING_RESPONSE';
  const isInProcess =
    [COUNTRIES.REPUBLICA_DOMINICANA, COUNTRIES.PANAMA].includes(country) &&
    invoice?.stamp?.legalStatus === IN_PROCESS_LEGAL_STATUS;

  return (
    invoiceNumeration &&
    !isInvoiceStamped &&
    invoiceNumeration.isElectronic &&
    can('edit-open', 'invoices', true) &&
    !isInProcess
  );
};

export const stampInvoice = async ({
  invoice,
  country,
  setStampLoading,
  fetchData,
}) => {
  setStampLoading(true);
  const invoicePayments = get(invoice, 'payments', []) || [];

  // Close any open payments before stamping
  await Promise.all(
    invoicePayments.map(async (payment) => {
      if (payment.status === 'open') {
        try {
          await alegraAPI.post(`/payments/${payment.id}/void`);
        } catch (e) {
          console.error(`Error voiding payment ${payment.id}:`, e);
        }
      }
    })
  );

  try {
    let updatedInvoice = {
      paymentForm: get(invoice, 'paymentForm', 'CASH'),
      paymentMethod: get(invoice, 'paymentMethod', 'CASH'),
      stamp: { generateStamp: true },
    };

    if (country === COUNTRIES.PANAMA) {
      updatedInvoice = {
        saleCondition: get(invoice, 'saleCondition', 'CASH'),
        type: 'INTERNAL_OPERATION',
        operationType: 'SALE',
        saleType: 'ORDER',
        stamp: { generateStamp: true },
      };
    }

    await alegraAPI.put(`/invoices/${invoice.id}`, updatedInvoice);

    // Re-open payments after stamping
    if (invoicePayments.length > 0) {
      await Promise.all(
        invoicePayments.map(async (payment) => {
          try {
            await alegraAPI.post(`/payments/${payment.id}/open`);
          } catch (e) {
            console.error(`Error reopening payment ${payment.id}:`, e);
          }
        })
      );
    }

    fetchData({
      start: 0,
      limit: 20,
      sortDirection: 'desc',
    });

    toast.success({
      title: I18n.get('stampedSuccessfullyMessage', '¡Ya está! ⚡'),
      subtitle: I18n.get(
        'invoiceStampedSuccess',
        'La factura electrónica de venta se emitió con éxito'
      ),
    });
  } catch (err) {
    // Re-open payments in case of error
    if (invoicePayments.length > 0) {
      await Promise.all(
        invoicePayments.map(async (payment) => {
          try {
            await alegraAPI.post(`/payments/${payment.id}/open`);
          } catch (e) {
            console.error(`Error reopening payment ${payment.id}:`, e);
          }
        })
      );
    }
    toast.error({
      title: 'Error',
      subtitle: parse(
        handleError(err.response?.data, {
          defaultMessage: I18n.get(
            'changeInvoiceError',
            'No pudimos modificar la factura. Inténtalo de nuevo'
          ),
        })
      ),
    });
  } finally {
    setStampLoading(false);
  }
};
