import { Table } from '@alegradev/smile-ui-react';
import { providerPaymentColumns } from './columns';
import { useMemo } from 'react';
import { banksSelector } from '../../../../reducers/banks';
import { useSelector } from 'react-redux';

function BillDetailBodyProviderPayment({ bill }) {
  const bankAccounts = useSelector(banksSelector.selectAll);

  const billWithCashAccount = useMemo(() => {
    if (!bill?.providerPayment) return [];
    const cashAccount = bankAccounts?.find(
      (account) => account?.type === 'cash'
    );
    if (!cashAccount) return bill?.providerPayment;

    return bill?.providerPayment?.map((billData) => {
      return {
        ...billData,
        bankAccountName: cashAccount?.name,
      };
    });
  }, [bill]);

  return (
    <div style={{ maxWidth: 500 }}>
      <Table
        data={billWithCashAccount}
        simple
        hidePagination
        hideToolbar
        columns={providerPaymentColumns}
      />
    </div>
  );
}

export default BillDetailBodyProviderPayment;
