import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  country as countrySelector,
  companySelector,
  membershipSelector,
  membershipPlanSelector,
} from '../../selectors/company';
import { COUNTRIES } from '../../utils/enums/countries';
import { get } from 'lodash';

export const DECIMALS_ACTIVE_COUNTRIES = [
  COUNTRIES.INTERNATIONAL,
  COUNTRIES.SPAIN,
  COUNTRIES.CHILE,
  COUNTRIES.ARGENTINA,
  COUNTRIES.COLOMBIA,
  COUNTRIES.MEXICO,
  COUNTRIES.PANAMA
];

export const DECIMALS_ACTIVE_IDS = [];

export const calculateAvailabilityByRegistryDate = ({ company, country }) => {
  const registryDate = get(company, 'registryDate', '');

  if (!registryDate) return false;

  return false;
};

const planAvailabilityRules = {};

export const calculateAvailabilityByPlanKeyword = ({ membership, country }) => {
  const planName =
    get(membership, 'plan.keyword', null) ||
    get(membership, 'planKeyword', null);

  const rules = planAvailabilityRules[country];

  return rules ? rules.includes(planName) : false;
};

const useDecimalsVersionsGroup = () => {
  const [isDecimalActive, setIsDecimalActive] = useState(false);
  const company = useSelector(companySelector);
  const membership = useSelector(membershipSelector);
  const membershipPlan = useSelector(membershipPlanSelector);
  const country = useSelector(countrySelector);

  const dateValidation = useMemo(() => {
    return calculateAvailabilityByRegistryDate({ company, country });
  }, [company, country]);

  const planValidation = useMemo(() => {
    return calculateAvailabilityByPlanKeyword({
      membership: Object.keys(membership) > 0 ? membership : membershipPlan,
      country,
    });
  }, [membership, membershipPlan, country]);

  useEffect(() => {
    if (
      DECIMALS_ACTIVE_COUNTRIES.includes(country) ||
      DECIMALS_ACTIVE_IDS.includes(company?.id) ||
      dateValidation ||
      planValidation
    ) {
      setIsDecimalActive(true);
    }
  }, [company, country]);

  return {
    isDecimalActive,
  };
};

export default useDecimalsVersionsGroup;
