import { get, toLower } from 'lodash';
import {
  INVOICE_DOCUMENT_PREFIXES,
  INVOICE_DOCUMENT_TYPES,
  INVOICE_PAYMENT_METHODS,
} from '../utils/constants';
import { I18n } from 'aws-amplify';
import { BaseInvoiceTextStrategy } from './BaseInvoiceTextStrategy';

export const ColombiaInvoiceTextStrategy = {
  getSubmitButtonName: ({ values, numeration, isElectronic }) => {
    if (
      get(numeration, 'prefix', '') ===
        INVOICE_DOCUMENT_PREFIXES.ELECTRONIC_POS ||
      get(numeration, 'linkedDevices', []).length > 0
    ) {
      return I18n.get('sell', 'Vender');
    }

    if (
      [
        INVOICE_PAYMENT_METHODS.BANCOLOMBIA,
        INVOICE_PAYMENT_METHODS.NEQUI,
      ].includes(values.method)
    ) {
      return I18n.get('savePayment', 'Guardar pago');
    }

    return BaseInvoiceTextStrategy.getSubmitButtonName({
      values,
      isElectronic,
    });
  },
  getNewSubmitButtonName: ({ values, numeration }) => {
    if (
      (numeration?.documentType === INVOICE_DOCUMENT_TYPES.SALE_TICKET &&
        numeration?.isElectronic) ||
      (numeration?.documentType === INVOICE_DOCUMENT_TYPES.INVOICE &&
        numeration?.isElectronic)
    ) {
      return I18n.get('emit', 'Emitir');
    }

    if (
      [
        INVOICE_PAYMENT_METHODS.BANCOLOMBIA,
        INVOICE_PAYMENT_METHODS.NEQUI,
        'nequi',
      ].includes(values.paymentMethodSelected)
    ) {
      return I18n.get('savePayment', 'Guardar pago');
    }
    return BaseInvoiceTextStrategy.getNewSubmitButtonName({ numeration });
  },

  getModalTitle: (numeration) => {
    if (
      numeration?.documentType === INVOICE_DOCUMENT_TYPES.SALE_TICKET &&
      numeration?.isElectronic
    ) {
      return I18n.get('newInvoiceModal.title.documentoPOS', 'documento POS');
    }
    if (numeration?.documentType === INVOICE_DOCUMENT_TYPES.SALE_TICKET) {
      return toLower(I18n.get('invoiceModalTitle.saleTicket', 'documento POS'));
    }
    return BaseInvoiceTextStrategy.getModalTitle(numeration);
  },
};
