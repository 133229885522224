import { I18n } from '@aws-amplify/core';
import { get, has, repeat, isString, lowerCase, capitalize } from 'lodash';
import dayjs from 'dayjs';

import argentinaIVAConditions from '../../countriesData/argentina/ivaConditions';
import argentinaIibbConditions from '../../countriesData/argentina/conditionsIibb';

import { fiscalResponsabilities as colombiaFiscalResponsabilities } from '../../countriesData/colombia/fiscalResponsabilities';
import BigNumber from 'bignumber.js';
import { isDefaultClient } from '../../../utils/invoices';
import { COUNTRIES } from '../../../utils/enums/countries';
import { isAccepted } from '../../forms/newRefund/utils';

export const calculateDV = (identification) => {
  const id = identification;
  let dv = null;
  if (!id || id.length > 15) {
    return '';
  }

  const primeNumbers = [
    3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71,
  ];
  let totalSum = 0;

  for (let i = 0; i < id.length; i++) {
    totalSum += id[i] * primeNumbers[id.length - i - 1];
  }

  const mod = totalSum % 11;

  dv = [0, 1].indexOf(mod) !== -1 ? mod : 11 - mod;
  return dv;
};

export const getID = (entity, country) => {
  if (!entity) return '';
  let identification = '';

  if (!!get(entity, 'identificationObject', null)) {
    const number = get(entity, 'identificationObject.number');
    const type = get(entity, 'identificationObject.type');

    if (get(entity, 'identificationObject.type', null) === 'NIT')
      identification = `${type || ''} ${number || ''}-${calculateDV(number)}`;
    else {
      if (country === 'colombia' && isDefaultClient(entity, country)) {
        identification = `NIT ${number || ''}`;
      } else {
        let finalType = type;
        if (type === 'FOREIGN_NIT') {
          finalType = 'NIT de otro país';
        }
        identification = `${finalType || ''} ${number || ''}`;
      }
    }
  } else
    identification = !!get(entity, 'identification', null)
      ? get(entity, 'identification')
      : '';

  if (!!identification && !isString(identification)) {
    const number = get(identification, 'number');
    const type = get(identification, 'type');

    identification = `${type || ''} ${number || ''}`;
  }

  return identification;
};

export const getDV = (entity, country) => {
  if (!entity) return '';
  let dv = '';
  if (country === 'panama' && !!get(entity, 'identificationObject.dv', null)) {
    dv = get(entity, 'identificationObject.dv', null);
  }
  return dv;
};

export const renderAddress = (entity, country) => {
  let address = [];
  switch (country) {
    case 'colombia':
      address.push(
        !!get(entity, 'address.address') ? get(entity, 'address.address') : ''
      );
      address.push(
        !!get(entity, 'address.city') ? get(entity, 'address.city') : ''
      );
      address.push(
        !!get(entity, 'address.department')
          ? get(entity, 'address.department')
          : ''
      );
      break;

    case 'costaRica':
      address.push(
        !!get(entity, 'address.address') ? get(entity, 'address.address') : ''
      );
      address.push(
        !!get(entity, 'address.city') ? get(entity, 'address.city') : ''
      );
      address.push(
        !!get(entity, 'address.department')
          ? get(entity, 'address.department')
          : ''
      );
      break;

    case 'argentina':
      address.push(
        !!get(entity, 'address.address') ? get(entity, 'address.address') : ''
      );
      address.push(
        !!get(entity, 'address.city')
          ? lowerCase(get(entity, 'address.city')) ===
            'ciudad autonoma de buenos aires'
            ? 'CABA'
            : get(entity, 'address.city')
          : ''
      );
      address.push(
        !!get(entity, 'address.province')
          ? lowerCase(get(entity, 'address.province')) ===
            'ciudad autonoma de buenos aires'
            ? 'CABA'
            : get(entity, 'address.province')
          : ''
      );
      break;

    case 'republicaDominicana':
      address.push(
        !!get(entity, 'address.address') ? get(entity, 'address.address') : ''
      );
      address.push(
        !!get(entity, 'address.description')
          ? get(entity, 'address.description')
          : ''
      );
      address.push(
        !!get(entity, 'address.municipality')
          ? get(entity, 'address.municipality')
          : ''
      );
      address.push(
        !!get(entity, 'address.province') ? get(entity, 'address.province') : ''
      );
      break;

    case 'peru':
      address.push(
        !!get(entity, 'address.address') ? get(entity, 'address.address') : ''
      );
      address.push(
        !!get(entity, 'address.urbanization')
          ? get(entity, 'address.urbanization')
          : ''
      );
      address.push(
        !!get(entity, 'address.district') ? get(entity, 'address.district') : ''
      );
      address.push(
        !!get(entity, 'address.city') ? get(entity, 'address.city') : ''
      );
      address.push(
        !!get(entity, 'address.state') ? get(entity, 'address.state') : ''
      );
      address.push(
        !!get(entity, 'address.ubigeoCode')
          ? get(entity, 'address.ubigeoCode')
          : ''
      );
      break;

    case 'mexico':
      address.push(
        !!get(entity, 'address.street') ? get(entity, 'address.street') : ''
      );
      address.push(
        !!get(entity, 'address.exteriorNumber')
          ? get(entity, 'address.exteriorNumber')
          : ''
      );
      address.push(
        !!get(entity, 'address.interiorNumber')
          ? get(entity, 'address.interiorNumber')
          : ''
      );
      address.push(
        !!get(entity, 'address.colony') ? get(entity, 'address.colony') : ''
      );
      address.push(
        !!get(entity, 'address.municipality')
          ? get(entity, 'address.municipality')
          : ''
      );
      address.push(
        !!get(entity, 'address.locality') ? get(entity, 'address.locality') : ''
      );
      address.push(
        !!get(entity, 'address.state') ? get(entity, 'address.state') : ''
      );
      address.push(
        !!get(entity, 'address.zipCode') ? get(entity, 'address.zipCode') : ''
      );
      address.push(
        !!get(entity, 'address.country') ? get(entity, 'address.country') : ''
      );
      break;

    case 'panama':
      if (
        get(entity, 'name', '') === 'Consumidor final' &&
        get(entity, 'identification', '') === ''
      )
        break;
      address.push(
        !!get(entity, 'address.address') ? get(entity, 'address.address') : ''
      );
      address.push(
        !!get(entity, 'address.city') ? get(entity, 'address.city') : ''
      );
      break;
    case 'spain':
      if (!isDefaultClient(entity, country)) {
        address.push(
          !!get(entity, 'address.address') ? get(entity, 'address.address') : ''
        );
        address.push(
          !!get(entity, 'address.city') ? get(entity, 'address.city') : ''
        );
      }
      break;
    default:
      address.push(
        !!get(entity, 'address.address') ? get(entity, 'address.address') : ''
      );
      address.push(
        !!get(entity, 'address.city') ? get(entity, 'address.city') : ''
      );
      break;
  }
  return address.filter((d) => d !== '' && d !== 'null' && d !== 'undefined'  && d !== null && d !== undefined ).join(', ');
};

export const getDocumentType = (invoice, numerations) => {
  if (!numerations || !numerations.find) return null;

  const numerationDocumentType = numerations.find(
    (numeration) => get(numeration, 'id') === get(invoice, 'numberTemplate.id')
  );
  return numerationDocumentType
    ? get(numerationDocumentType, 'documentType')
    : null;
};

export const getSubDocumentType = (invoice, numerations) => {
  if (!numerations || !numerations.find) return null;

  const numerationSubDocumentType = numerations.find(
    (numeration) => get(numeration, 'id') === get(invoice, 'numberTemplate.id')
  );
  return numerationSubDocumentType
    ? get(numerationSubDocumentType, 'subDocumentType')
    : null;
};

export const getIsElectronic = (invoice, numerations) => {
  if (!numerations || !numerations.find) return null;

  const numerationDocumentType = numerations.find(
    (numeration) => get(numeration, 'id') === get(invoice, 'numberTemplate.id')
  );
  return get(numerationDocumentType, 'isElectronic', null);
};

export const getNumerationEndDate = (invoice, numerations) => {
  if (!numerations || !numerations.find) return null;

  const numerationDocumentType = numerations.find(
    (numeration) => get(numeration, 'id') === get(invoice, 'numberTemplate.id')
  );
  return get(numerationDocumentType, 'endDate', null);
};

export const getInvoiceType = (invoice, country, numerations) => {
  if (!invoice) return '';

  const number = getInvoiceNumber(invoice);
  const templateDocumentType = getDocumentType(invoice, numerations);
  const isElectronic = getIsElectronic(invoice, numerations);
  const templateSubDocumentType = getSubDocumentType(invoice, numerations);

  if (country === 'republicaDominicana') {
    if(templateSubDocumentType && templateSubDocumentType?.name === 'Sin NCF') { 
      return I18n.get('invoiceOfSale', 'factura de venta');
    }
    switch (invoice.numberTemplate?.prefix) {
      case 'B01':
        return I18n.get('taxCreditInvoice', 'Factura de Crédito Fiscal');
      case 'B02':
        return I18n.get('consumptionInvoice', 'Factura de Consumo');
      case 'B14':
        return I18n.get(
          'specialTaxationRegimeInvoice',
          'Factura de Régimen Especial de Tributación'
        );
      case 'B15':
        return I18n.get('governmentInvoice', 'Factura Gubernamental');
      case 'E31':
        return I18n.get(
          'taxElectronicCreditInvoice',
          'Factura electrónica de crédito fiscal'
        );
      case 'E32':
        return I18n.get(
          'consumptionElectronicInvoice',
          'Factura electrónica de consumo'
        );
      default:
        return I18n.get('invoiceNFC', 'Factura/NFC');
    }
  }

  if (country === 'peru') {
    if (/B.{3}/.test(number) && isElectronic) {
      return I18n.get('electronicTicket', 'boleta electrónica');
    } else if (/F.{3}/.test(number) && isElectronic)
      return I18n.get('electronicInvoice', 'factura electrónica');
    else if (templateDocumentType !== 'invoice')
      return I18n.get('saleTicket', 'boleta de venta');
    else return I18n.get('invoiceOfSale', 'factura de venta');
  }

  if (country === 'colombia' && !!get(invoice, 'stamp', null) && templateDocumentType === 'saleTicket')
    return I18n.get('electronicPOSDocumentComplementText', 'Documento equivalente electrónico tiquete de máquina registradora con sistema P.O.S.');
  if (country === 'colombia' && !!get(invoice, 'stamp', null))
    return I18n.get('electronicSalesInvoice', 'factura electrónica de venta');
  if (country === 'colombia' && templateDocumentType === 'invoice')
    return I18n.get('paperSalesInvoice', 'factura de venta de papel');
  if (country === 'colombia')
    return I18n.get(
      'equivalentDocumentPOSSystem',
      'sistema P.O.S.'
    );

  if (country === 'panama' && !!get(invoice, 'stamp', null))
    return I18n.get('internalOperationInvoice', 'Factura de operación interna');

  if (country === 'spain' && templateDocumentType === 'invoice')
    return I18n.get('ordinaryInvoice', 'factura ordinaria');

  if (country === 'spain' && templateDocumentType === 'saleTicket')
    return I18n.get('simplifiedInvoice', 'factura simplificada');

  if (country === COUNTRIES.MEXICO && templateDocumentType === 'invoice') {
    return I18n.get('', 'Factura de venta (CFDI)');
  }


  return I18n.get('invoice', 'factura');
};

export const getInvoiceSubType = (invoice, country, numerations) => {
  if (!invoice) return '';

  const templateSubDocumentType = getSubDocumentType(invoice, numerations);

  if (country === 'argentina') {
    switch (templateSubDocumentType) {
      case 'INVOICE_A':
        return 'A';
      case 'INVOICE_B':
        return 'B';
      case 'INVOICE_C':
        return 'C';
      case 'INVOICE_X':
        return 'X';
      default:
        return null;
    }
  }

  return null;
};

const argSubTypes = ['A', 'B', 'C'];
export const getArgetinaInvoiceType = ({ subType, invoice, isElectronic }) => {
  if (subType === 'X' && !isAccepted(invoice?.stamp)) {
    return I18n.get(
      'documentNotValidAsInvoice',
      'Documento no válido como factura'
    );
  }
  return null;
};

export const getArgentinaComplement = ({ subType }) => {
  if(argSubTypes.includes(subType)) {
    return I18n.get(
      'documentNotValidAsLegalDocument',
      'Documento no válido como comprobante fiscal'
    );
  }

  return capitalize(I18n.get('notValidInvoiceArg', 'Factura no valida como comprobante fiscal'))
}

const getFormattedNumber = (invoice, country) => {
  if (!invoice) return null;

  let number = !!get(invoice, 'numberTemplate.number', null)
    ? '' + get(invoice, 'numberTemplate.number', null)
    : null;

  switch (country) {
    case 'peru':
      return `${!!number ? repeat('0', 8 - number.length) : ''}${number || ''}`;
    case 'argentina':
      return `${!!number ? repeat('0', 8 - number.length) : ''}${number || ''}`;
    case 'costaRica':
      if (!!get(invoice, 'numberTemplate.stamp', null))
        return `${!!number ? repeat('0', 10 - number.length) : ''}${number || ''
          }`;
      return number;
    case 'republicaDominicana':
      return `${!!number ? repeat('0', 8 - number.length) : ''}${number || ''}`;
    case 'panama':
      return `${!!number ? repeat('0', 10 - number.length) : ''}${number || ''
        }`;
    default:
      return number;
  }
};

export const getInvoiceNumber = (invoice, country, numerations) => {
  if (!invoice) return null;

  if (!get(invoice, 'numberTemplate', null)) return null;

  const templateSubDocumentType = getSubDocumentType(invoice, numerations);

  const valuePrefix =
    country === 'republicaDominicana'
      ? invoice.numberTemplate.isElectronic
        ? 'e-NCF'
        : templateSubDocumentType && templateSubDocumentType?.name === 'Sin NCF' ? 'N°' : 'NCF'
      : 'N°';

  const fullNumber = get(invoice, 'numberTemplate.fullNumber', null);
  const prefix = !!get(invoice, 'numberTemplate.prefix', null)
    ? get(invoice, 'numberTemplate.prefix', null)
    : '';
  let formattedNumber = getFormattedNumber(invoice, country);

  if (!!fullNumber && country !== 'panama' && country !== 'spain')
    return `${valuePrefix} ${fullNumber || ''}`;

  switch (country) {
    case 'peru':
      return `${valuePrefix} ${prefix ? `${prefix}-` : ''}${formattedNumber || ''
        }`;
    case 'argentina':
      return `${valuePrefix} ${prefix ? `${prefix}-` : ''}${formattedNumber || ''
        }`;
    case 'panama':
      return `${valuePrefix} ${formattedNumber || ''}`;
    case 'spain':
      return `${valuePrefix} ${prefix ? prefix + '-' : ''}${formattedNumber || ''}`;
    default:
      return `${valuePrefix} ${prefix || ''}${formattedNumber || ''}`;
  }
};

export const getPrefix = (invoice, country) => {
  if (!invoice || !get(invoice, 'numberTemplate', null)) return null;

  const prefix = get(invoice, 'numberTemplate.prefix', '') || '';

  if (country === 'panama' && !!prefix)
    return `${repeat('0', 3 - prefix.length)}${prefix}`;

  return prefix;
};

export const getDateTimeTitle = (invoice, country, numerations) => {
  const templateDocumentType = getDocumentType(invoice, numerations);
  if (
    ['panama', 'republicaDominicana'].includes(country) &&
    !!get(invoice, 'stamp')
  )
    return I18n.get('emissionDate', 'Fecha de emisión');

  if (country === 'colombia' && !!get(invoice, 'stamp'))
    return I18n.get('emissionDate', 'Fecha de emisión');

  if (country === 'colombia' && templateDocumentType === 'invoice')
    return I18n.get('emissionDate', 'Fecha de emisión');

  if (country === 'colombia' || country === 'spain')
    return I18n.get('emissionDate', 'Fecha de emisión');

  return I18n.get('date', 'fecha');
};

export const getDateTime = (invoice, country, dateFormat, typeDate = '') => {
  let datetime;
  if (!!get(invoice, 'stamp.date')) datetime = get(invoice, 'stamp.date');
  else if (!!get(invoice, 'createdAt')) datetime = get(invoice, 'createdAt');
  else if (!!get(invoice, 'datetime')) datetime = get(invoice, 'datetime');
  else datetime = get(invoice, 'timestamp');

  if (country === 'peru' && !!get(invoice, 'stamp')) {
    return dayjs(datetime).isValid()
      ? dayjs(datetime).format('YYYY-MM-DDTHH:mm:ss')
      : '';
  }

  if (country === 'colombia') {
    if (typeDate === 'stamp') {
      const datetime = get(invoice, 'stamp.date');
      return dayjs(datetime).isValid()
        ? dayjs(datetime).format('DD/MM/YYYY h:mm a')
        : I18n.get('dateTimeFormat', 'DD/MM/YYYY');
    }
    if (typeDate === 'datetime') {
      const datetime = get(invoice, 'datetime');
      return dayjs(datetime).isValid()
        ? dayjs(datetime).format('DD/MM/YYYY h:mm a')
        : I18n.get('dateTimeFormat', 'DD/MM/YYYY');
    }
    return dayjs(datetime).isValid()
      ? dayjs(datetime).format('DD/MM/YYYY h:mm a')
      : I18n.get('dateTimeFormat', 'DD/MM/YYYY');
  }

  return dayjs(datetime).isValid()
    ? dayjs(datetime).format(
      !!dateFormat
        ? `${dateFormat.toUpperCase()} h:mm a`
        : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')
    )
    : '';
};

export const getDueDate = (invoice, dateFormat) => {
  let date = get(invoice, 'dueDate', null);
  return dayjs(date).isValid()
    ? dayjs(date).format(
      !!dateFormat
        ? dateFormat.toUpperCase()
        : I18n.get('dateFormat', 'DD/MM/YYYY')
    )
    : '';
};

export const getActivityStartDate = (company, country, dateFormat) => {
  let date = null;
  if (country === 'argentina') date = get(company, 'activityStartDate', null);

  return dayjs(date).isValid()
    ? dayjs(date).format(
      !!dateFormat
        ? dateFormat.toUpperCase()
        : I18n.get('dateFormat', 'DD/MM/YYYY')
    )
    : '';
};

export const getInvoicePaymentMethd = (invoice, country, numerations) => {
  const paymentForm = getInvoicePaymentForm(invoice, country, numerations);
  const paymentMethod = get(invoice, 'paymentMethod', null);

  if (country === 'colombia' && paymentMethod === 'INSTRUMENT_NOT_DEFINED')
    return I18n.get('instrument-not-defined', 'Instrumento no definido')
  if (country === 'colombia' && paymentForm === I18n.get('credit', 'Crédito'))
    return '';
  if (country === 'panama' && paymentForm === I18n.get('credit', 'Crédito'))
    return '';
  let method = '';

  if (invoice.payments)
    invoice.payments.forEach((payment) => {
      if (has(payment, 'paymentMethod')) {
        method += method === '' ? '' : ', ';
        method += I18n.get(payment.paymentMethod, payment.paymentMethod);
      }
    });

  return method === '' ? I18n.get('cash', 'efectivo') : method;
};

export const getInvoicePaymentForm = (invoice, country, numerations) => {
  const templateDocumentType = getDocumentType(invoice, numerations);
  const isElectronicPOSDocument = get(invoice, 'stamp', null) && templateDocumentType === 'saleTicket';

  switch (country) {
    case 'peru':
      if (templateDocumentType === 'invoice')
        return I18n.get('paymentForm.Cash', 'Contado');
      break;
    case 'colombia':
      const payments = Array.isArray(invoice.payments) ? invoice.payments : [];

      const totalPaid = Number(invoice.total) <= Number(invoice.totalReceived) 
      ? Number(invoice.totalReceived)
      : payments.reduce(
        (acc, payment) => acc + Number(payment.amount),
        0
      );

      const fullPaid = Number(invoice.total) <= totalPaid || isElectronicPOSDocument;;
      return fullPaid
        ? I18n.get('paymentForm.Cash', 'Contado')
        : I18n.get('credit', 'Crédito');
    case 'panama':
      const isAfterDate = isAfterDateToProduction(invoice, '2023-04-25');
      const isElectronic = get(invoice, 'stamp', null);
      if (!isAfterDate || !isElectronic) return '';
      const isFullPaid = Number(invoice.total) <= Number(invoice.totalReceived);
      return isFullPaid
        ? I18n.get('paymentForm.Cash', 'Contado')
        : I18n.get('credit', 'Crédito');
    default:
      break;
  }
  return '';
};

export const getInvoiceSaleCondition = (invoice, country) => {
  if (!!get(invoice, 'saleCondition')) {
    if (country === 'argentina') {
      switch (get(invoice, 'saleCondition')) {
        case 'CASH':
          return I18n.get('cash', 'Contado');
        case 'DEBIT_CARD':
          return I18n.get('debitCard', 'Tarjeta débito');
        case 'CREDIT_CARD':
          return I18n.get('creditCard', 'Tarjeta crédito');
        case 'TRANSFER':
          return I18n.get('transfer', 'Transferencia');
        default:
          return null;
      }
    }
    if (country === 'costaRica' || country === 'colombia') {
      switch (get(invoice, 'saleCondition')) {
        case '01':
        case 'CASH':
          return I18n.get('paymentForm.Cash', 'Contado');
        case '02':
        case 'CREDIT':
          return I18n.get('credit', 'Crédito');
        default:
          return null;
      }
    }
  }
  return null;
};

export const getInvoicePaymentType = (invoice) => {
  const isFree = invoice.items.every((item) => item.discount === 100);
  const fullPaid =
    Number(invoice.total) === Number(get(invoice, 'payments[0].amount'));
  return isFree
    ? I18n.get('free', 'Gratis')
    : fullPaid
      ? I18n.get('paymentForm.Cash', 'Contado')
      : I18n.get('credit', 'Crédito');
};

export const getInvoiceSaleConcept = (invoice) => {
  if (get(invoice, 'saleConcept')) {
    switch (get(invoice, 'saleConcept')) {
      case 'SERVICES':
        return I18n.get('services', 'Servicios');
      case 'PRODUCTS':
        return I18n.get('products', 'Productos');
      case 'PRODUCTS_SERVICES':
        return I18n.get('productsAndServices', 'Productos y servicios');

      default:
        return null;
    }
  }
  return null;
};

export const getIVACondition = (entity, country) => {
  if (country === 'argentina') {
    return !!argentinaIVAConditions.find(
      (condition) => condition.key === get(entity, 'ivaCondition')
    )
      ? argentinaIVAConditions.find(
        (condition) => condition.key === get(entity, 'ivaCondition')
      ).value
      : null;
  }

  return null;
};

export const getIibbCondition = (company, country) => {
  if (country === 'argentina')
    return !!argentinaIibbConditions.find(
      (condition) => condition.key === get(company, 'conditionIibb')
    )
      ? argentinaIibbConditions.find(
        (condition) => condition.key === get(company, 'conditionIibb')
      ).value
      : null;

  return null;
};

export const getRegime = (company, country) => {
  if (country === 'argentina' || country === 'usa') return null;

  if (!!get(company, 'regime')) return get(company, 'regime', null);

  return null;
};

export const getFiscalResponsabilities = (company, country) => {
  let fiscalResponsabilitiesNames = [];
  switch (country) {
    case 'colombia':
      const fiscalResponsabilities = get(company, 'fiscalResponsabilities', []);
      if (!fiscalResponsabilities || fiscalResponsabilities.length === 0)
        return '';
      fiscalResponsabilities.forEach((res) => {
        const item = colombiaFiscalResponsabilities.find(
          (fiscal) => fiscal.id === +res
        );
        if (item) {
          fiscalResponsabilitiesNames.push(item.value);
        }
      });
      return fiscalResponsabilitiesNames.length > 0
        ? fiscalResponsabilitiesNames.join('-')
        : '';
    default:
      return '';
  }
};

/**
 * Returns isAfterDateToProduction value that indicates if the creation is before or after date.
 *
 * @param {object} invoice Object with the invoice data
 * @param {string} date production date with format "YYYY-MM-DD"
 * @return {boolean} isAfterDateToProduction value that indicates if the creation is before or after date.
 */
export const isAfterDateToProduction = (invoice, date) => {
  let datetime;
  if (!!get(invoice, 'stamp.date')) datetime = get(invoice, 'stamp.date');
  else if (!!get(invoice, 'createdAt')) datetime = get(invoice, 'createdAt');
  else if (!!get(invoice, 'datetime')) datetime = get(invoice, 'datetime');
  else datetime = get(invoice, 'timestamp');

  const dateToProduction = dayjs(date);
  const createdAt = dayjs(datetime).format('YYYY-MM-DD');

  const isAfterDateToProduction = dateToProduction.diff(createdAt, 'd') <= 0;

  return isAfterDateToProduction;
};
export const getSpainTaxCode = (tax) => {
  // We start from the 64, 64 + 1 = 65 (A)
  const ALPHABET_INIT_CHAR_CODE = 64;
  const itemTaxId = parseInt(get(tax, 'id', null));

  if (!itemTaxId)
    return {
      itemTaxCode: null,
    };

  if (itemTaxId === 14)
    // If there is a tax with id 14 we add the I
    return {
      ...tax,
      itemTaxCode:
        String.fromCharCode(ALPHABET_INIT_CHAR_CODE + 14) +
        String.fromCharCode(ALPHABET_INIT_CHAR_CODE + 9),
    };

  return {
    ...tax,
    itemTaxCode: String.fromCharCode(ALPHABET_INIT_CHAR_CODE + itemTaxId),
  };
};
export const getColombiaTaxCode = (tax) => {
  // We start from the 64, 64 + 1 = 65 (A)
  const ALPHABET_INIT_CHAR_CODE = 64;
  const itemTaxId = parseInt(get(tax, 'id', null));

  if (!itemTaxId)
    // We return N when no taxes (78 char)
    return {
      name: 'EXCL',
      type: 'EXCL',
      itemTaxCode: String.fromCharCode(ALPHABET_INIT_CHAR_CODE + 14),
    };

  if (itemTaxId === 14)
    // If there is a tax with id 14 we add the I
    return {
      ...tax,
      itemTaxCode:
        String.fromCharCode(ALPHABET_INIT_CHAR_CODE + 14) +
        String.fromCharCode(ALPHABET_INIT_CHAR_CODE + 9),
    };

  return {
    ...tax,
    itemTaxCode: String.fromCharCode(ALPHABET_INIT_CHAR_CODE + itemTaxId),
  };
};

export const mergeObjectArraysById = (arr1, arr2) => {
  return arr1.map((obj1) => {
    const obj2 = arr2.find((obj) => obj.id === obj1.id);
    if (obj2) {
      const { value, ...rest } = obj1; // Extract "value" and store the rest of the properties in "rest"
      return { ...obj2, subtotal: value, ...rest }; // Merge the objects and rename "value" to "newValue"
    } else {
      return { ...obj1, subtotal: obj1.value, value: new BigNumber(0) }; // If no match is found, add the object without modification
    }
  });
};

export const assingITBISTaxesToLetter = (items) => {
  const percentagesSet = [
    ...new Set(
      items.flatMap((item) =>
        get(item, 'tax', [])
          .filter((t) => get(t, 'type', '') === 'ITBIS')
          .map((t) => t.percentage)
      )
    ),
  ]
    .filter((percentage) => ![0, 16, 18].includes(Number(percentage)))
    .sort((a, b) => a - b);

  const lettersObj = {};
  let initialLetter = 'D';

  for (const perc of percentagesSet) {
    lettersObj[perc] = initialLetter;
    initialLetter = String.fromCharCode(initialLetter.charCodeAt(0) + 1);
  }

  return lettersObj;
};

export const getDefaultCurrencyValue = (currency, value) => {
  const exchangeRate = !!get(currency, 'exchangeRate')
    ? +get(currency, 'exchangeRate')
    : 1;
  const newValue = new BigNumber(value)
    .multipliedBy(new BigNumber(exchangeRate))
    .decimalPlaces(4)
    .toNumber();
  return newValue;
}

export const getFormatNumberToWords = (quantity) => {
  var number = 0;
  quantity = parseFloat(quantity);

  if (quantity == '0.00' || quantity == '0') {
    return 'CERO con 00/100 ';
  } else {
    var ent = quantity.toString().split('.');
    var numberInArray = splitContent(ent[0]);
    var numberLength = numberInArray.length;

    switch (numberLength) {
      case 1:
        number = units(numberInArray[0]);
        break;
      case 2:
        number = tens(numberInArray[0], numberInArray[1]);
        break;
      case 3:
        number = hundreds(numberInArray[0], numberInArray[1], numberInArray[2]);
        break;
      case 4:
        number = thousandUnits(
          numberInArray[0],
          numberInArray[1],
          numberInArray[2],
          numberInArray[3]
        );
        break;
      case 5:
        number = tensOfThousand(
          numberInArray[0],
          numberInArray[1],
          numberInArray[2],
          numberInArray[3],
          numberInArray[4]
        );
        break;
      case 6:
        number = hundredsOfThousand(
          numberInArray[0],
          numberInArray[1],
          numberInArray[2],
          numberInArray[3],
          numberInArray[4],
          numberInArray[5]
        );
        break;
    }

    ent[1] = isNaN(ent[1]) ? '00' : ent[1].length === 1 ? ent[1] * 10 : ent[1];

    return number + ' con ' + ent[1] + '/100';
  }
};

const units = (unit) => {
  var units = Array(
    'UN ',
    'DOS ',
    'TRES ',
    'CUATRO ',
    'CINCO ',
    'SEIS ',
    'SIETE ',
    'OCHO ',
    'NUEVE '
  );

  return units[unit - 1];
}

const tens = (ten, unit) => {
  var diez = Array(
    'ONCE ',
    'DOCE ',
    'TRECE ',
    'CATORCE ',
    'QUINCE',
    'DIECISEIS ',
    'DIECISIETE ',
    'DIECIOCHO ',
    'DIECINUEVE '
  );
  var tens = Array(
    'DIEZ ',
    'VEINTE ',
    'TREINTA ',
    'CUARENTA ',
    'CINCUENTA ',
    'SESENTA ',
    'SETENTA ',
    'OCHENTA ',
    'NOVENTA '
  );

  if (ten == 0 && unit == 0) {
    return '';
  }

  if (ten == 0 && unit > 0) {
    return units(unit);
  }

  if (ten == 1) {
    if (unit == 0) {
      return tens[ten - 1];
    } else {
      return diez[unit - 1];
    }
  } else if (ten == 2) {
    if (unit == 0) {
      return tens[ten - 1];
    } else if (unit == 1) {
      return ('VEINTI' + 'UNO');
    } else {
      return ('VEINTI' + units(unit));
    }
  } else {
    if (unit == 0) {
      return tens[ten - 1] + ' ';
    }
    if (unit == 1) {
      return tens[ten - 1] + ' Y ' + 'UNO';
    }

    return tens[ten - 1] + ' Y ' + units(unit);
  }
}

const hundreds = (hundred, ten, unit) => {
  var hundreds = Array(
    'CIENTO ',
    'DOSCIENTOS ',
    'TRESCIENTOS ',
    'CUATROCIENTOS ',
    'QUINIENTOS ',
    'SEISCIENTOS ',
    'SETECIENTOS ',
    'OCHOCIENTOS ',
    'NOVECIENTOS '
  );

  if (hundred == 0 && ten == 0 && unit == 0) {
    return '';
  }
  if (hundred == 1 && ten == 0 && unit == 0) {
    return 'CIEN ';
  }

  if (hundred == 0 && ten == 0 && unit > 0) {
    return units(unit);
  }

  if (ten == 0 && unit == 0) {
    return hundreds[hundred - 1] + '';
  }

  if (ten == 0) {
    var number = hundreds[hundred - 1] + '' + tens(ten, unit);
    return number.replace(' Y ', ' ');
  }
  if (hundred == 0) {
    return tens(ten, unit);
  }

  return hundreds[hundred - 1] + '' + tens(ten, unit);
}

const thousandUnits = (unimill, hundred, ten, unit) => {
  var number = units(unimill) + ' MIL ' + hundreds(hundred, ten, unit);
  number = number.replace('UN  MIL ', 'MIL ');
  if (unit == 0) {
    return number.replace(' Y ', ' ');
  } else {
    return number;
  }
}

const tensOfThousand = (decemill, unimill, hundred, ten, unit) => {
  var number =
    tens(decemill, unimill) + ' MIL ' + hundreds(hundred, ten, unit);
  return number;
}

const hundredsOfThousand = (
  centenamill,
  decemill,
  unimill,
  hundred,
  ten,
  unit
) => {
  var number = 0;
  number =
    hundreds(centenamill, decemill, unimill) +
    ' MIL ' +
    hundreds(hundred, ten, unit);

  return number;
}

const splitContent = (texto) => {
  var contenido = new Array();
  for (var i = 0; i < texto.length; i++) {
    contenido[i] = texto.substr(i, 1);
  }
  return contenido;
}