import { get } from 'lodash';
import { INVOICE_DOCUMENT_TYPES } from '../utils/constants';
import { I18n } from 'aws-amplify';
import { BaseInvoiceTextStrategy } from './BaseInvoiceTextStrategy';

export const MexicoInvoiceTextStrategy = {
  getSubmitButtonName: ({ values, numeration, isElectronic }) => {
    if (
      get(numeration, 'documentType', '') === INVOICE_DOCUMENT_TYPES.INVOICE
    ) {
      return I18n.get('stamp', 'Timbrar');
    }

    return BaseInvoiceTextStrategy.getSubmitButtonName({
      values,
      numeration,
      isElectronic,
    });
  },
  getNewSubmitButtonName: ({ numeration }) => {
    if (get(numeration, 'documentType') === INVOICE_DOCUMENT_TYPES.INVOICE) {
      return I18n.get('stamp', 'Timbrar');
    }
    return BaseInvoiceTextStrategy.getNewSubmitButtonName({ numeration });
  },
  getModalTitle: (numeration) => {
    return BaseInvoiceTextStrategy.getModalTitle(numeration);
  },
  validate: (values, errors) => {
    return errors;
  },
};
