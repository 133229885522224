import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { renderField } from '../../../../../components/forms/fields/V2';
import { Grid } from '@alegradev/smile-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { warehousesSelector } from '../../../../../reducers/warehouses';
import { setChangePrincipalWarehouseBill } from '../../../../../reducers/app';
import { companySelector } from '../../../../../selectors/company';

function BasicData(props) {
  const dispatch = useDispatch();
  const { Col, Row } = Grid;

  const warehouses = useSelector(warehousesSelector.selectAll);
  const company = useSelector(companySelector);

  const changeWarehouse = (value) => {
    dispatch(setChangePrincipalWarehouseBill(value.name.toLowerCase() !== 'principal'));
  };

  return (
    <Row spacing='lg'>
      <Col xs={4} md={6} sm={12}>
        <Field
          name='currency'
          component={renderField}
          label={capitalize(I18n.get('currency', 'Moneda'))}
          defaultValue={company?.currency?.code}
          disabled={true}
        />
      </Col>
      <Col xs={4} md={6} sm={12}>
        <Field
          name='expiredDate'
          fieldType='date'
          required
          component={renderField}
          label={capitalize(I18n.get('expiredDate', 'Fecha de vencimiento'))}
        />
      </Col>
      <Col xs={4} md={6} sm={12}>
        <Field
          name='warehouse'
          component={renderField}
          fieldType='rselect'
          label={capitalize(I18n.get('warehouse', 'Bodega'))}
          placeholder={I18n.get('select', 'Seleccionar')}
          options={warehouses || []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option}
          onChange={(value) => changeWarehouse(value)}
        />
      </Col>
    </Row>
  );
}

export default BasicData;
