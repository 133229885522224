import { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Table as STable, useModal } from '@alegradev/smile-ui-react';

import { billsColumns } from './columns';
import { empty } from './empty';
import { noResults } from './noResults';
import { useBillsQuery } from '../../services/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { billKeys } from '../../services/utils';
import { actions } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { APIGraphqlSelector } from '../../../../selectors/app';
import { billTransformer } from '../../services/transformers';
import BillDetailBody from '../../detail/Body';
import { useDeleteBillMutation } from '../../services/mutations';
import { hasPermissionTo } from '../../../../selectors/auth';
import { setFirstTimeInBills } from '../../../../reducers/app';
import { country as countrySelector } from '../../../../selectors/company';

function Table({ handleCreateBill }) {
  const history = useHistory();
  const can = useSelector(hasPermissionTo);
  const { closeModal, openModal } = useModal();
  const columns = useMemo(() => billsColumns, []);
  const [metadata, setMetadata] = useState({});
  const queryClient = useQueryClient();
  const api = useSelector(APIGraphqlSelector);
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);

  const { isFetching, isLoading, data } = useBillsQuery(metadata, api);
  const { mutateAsync } = useDeleteBillMutation();

  const items = get(data, 'data', []);
  const totalItems = get(data, 'metadata.total', 0);

  useEffect(() => {
    if (items.length) {
      dispatch(setFirstTimeInBills(false));
    }
  }, [data]);

  const handleChangeTableData = (tableState) => {
    if (tableState?.filters !== metadata.filters) {
      queryClient
        .getQueryCache()
        .findAll()
        .forEach((query) => {
          const key = query.queryKey;

          if (key[0] === billKeys.all && key[1]?.filters?.search) {
            queryClient.removeQueries({ queryKey: key });
          }

          if (key[0] === billKeys.all && key[1]?.filters?.status) {
            queryClient.removeQueries({ queryKey: key });
          }
        });
    }
    setMetadata(tableState);
  };

  return (
    <STable
      onChange={handleChangeTableData}
      totalItems={totalItems}
      data={items?.filter((item) => item)}
      actions={(row) =>
        actions({
          row,
          history,
          mutateAsync,
          closeModal,
          openModal,
          can,
          country,
        })
      }
      columns={columns}
      emptyComponent={empty(history, can, handleCreateBill)}
      noResultsComponent={noResults}
      loading={isLoading || isFetching}
      partibleComponent={(row) => (
        <BillDetailBody bill={billTransformer(row)} partible />
      )}
      onRowClick={(row) => history.push(`/bills/bill/${row.id}/detail`)}
      partible
    />
  );
}

export default Table;
