import React, { useMemo } from 'react';
import { I18n } from '@aws-amplify/core';
import { Field, Form } from 'react-final-form';
import { formError } from '../../../../../../utils/errors';
import { renderField } from '../../../../../../components/forms/fields/V2';
import { Button, Grid, useModal } from '@alegradev/smile-ui-react';
import {
  EXONERATION_DOCUMENT_TYPES,
  exonerationDocumentTypes,
} from '../../../../../../components/countriesData/costaRica/exonerationDocumentTypes';
import {
  InstitutionCodes,
  InstitutionCodesEnum,
} from '../../../../../../components/countriesData/costaRica/institutionNames';
import { costaRicaExonerationValidation } from './validations';
import { uniqueId } from 'lodash';

function ExonerationModal({ onCreated, exoneration }) {
  const { Col, Row, Wrapper } = Grid;
  const { closeModal } = useModal();

  const submit = async (values) => {
    try {
      if (onCreated) {
        onCreated({
          key: values?.key || uniqueId(),
          id: values?.id,
          documentType: values?.documentType?.code,
          otherDocumentType: values?.otherDocumentType,
          documentNumber: values?.documentNumber,
          article: values?.article,
          paragraph: values?.paragraph,
          institutionName: values?.institutionName?.code,
          otherInstitutionName: values?.otherInstitutionName,
          emissionDate: values?.emissionDate,
          percentage: values?.percentage,
        });
        closeModal('exoneration');
      }
    } catch (error) {
      return formError(
        error,
        I18n.get(
          'createPriceListError',
          'hubo un error en la creación de la lista de precio'
        )
      );
    }
  };

  const exonerationInitialValues = useMemo(() => {
    const findDocumentType = exonerationDocumentTypes.find(
      (option) => option.code === exoneration?.documentType
    );
    const findInstitutionName = InstitutionCodes.find(
      (option) => option.code === exoneration?.institutionName
    );
    return {
      key: exoneration?.key || uniqueId(),
      id: exoneration?.id,
      documentType: findDocumentType,
      otherDocumentType: exoneration?.otherDocumentType,
      documentNumber: exoneration?.documentNumber,
      article: exoneration?.article,
      paragraph: exoneration?.paragraph,
      institutionName: findInstitutionName,
      otherInstitutionName: exoneration?.otherInstitutionName,
      emissionDate: exoneration?.emissionDate,
      percentage: exoneration?.percentage,
    };
  }, [exoneration]);

  return (
    <Form
      onSubmit={submit}
      validate={(values) => costaRicaExonerationValidation(values)}
      initialValues={exonerationInitialValues}
    >
      {({ handleSubmit, values, submitting, errors }) => {
        const isExonerationRequired = useMemo(() => {
          const requiredCodes = [
            EXONERATION_DOCUMENT_TYPES.TAX_EXEMPT_SALES_TO_DIPLOMATS,
            EXONERATION_DOCUMENT_TYPES.AUTHORIZED_BY_SPECIAL_LAW,
            EXONERATION_DOCUMENT_TYPES.TOURISM_SERVICES_REGISTERED_WITH_ICT,
            EXONERATION_DOCUMENT_TYPES.RECYCLING_AND_REUSABLES_EXEMPTION,
            EXONERATION_DOCUMENT_TYPES.FREE_TRADE_ZONE_EXEMPTION,
          ];
          return requiredCodes.includes(values?.documentType?.code);
        }, [values?.documentType]);

        return (
          <form noValidate onSubmit={handleSubmit}>
            <Row spacing='lg'>
              <Col xs={12}>
                <Field
                  name='documentType'
                  required
                  label={I18n.get('documentType', 'Tipo documento')}
                  placeholder={I18n.get(
                    'selectDocumentType',
                    'Selecciona un tipo de documento'
                  )}
                  component={renderField}
                  fieldType='rselect'
                  options={exonerationDocumentTypes}
                  getOptionLabel={(option) => option.description}
                />
              </Col>
              {values?.documentType?.code ===
                EXONERATION_DOCUMENT_TYPES.OTHERS && (
                <Col xs={12}>
                  <Field
                    name='otherDocumentType'
                    required
                    label={I18n.get(
                      'otherDocumentType',
                      'Otro tipo de documento'
                    )}
                    component={renderField}
                  />
                </Col>
              )}
              <Col xs={12}>
                <Field
                  name='documentNumber'
                  required
                  label={I18n.get('documentNumber', 'Número de documento')}
                  component={renderField}
                  type='text'
                />
              </Col>
              <Col xs={6}>
                <Field
                  name='article'
                  label={I18n.get('article', 'Artículo')}
                  component={renderField}
                  required={isExonerationRequired}
                  fieldType='text'
                />
              </Col>
              <Col xs={6}>
                <Field
                  name='paragraph'
                  label={I18n.get('paragraph', 'Inciso')}
                  helpTooltip={I18n.get(
                    'subsectionTooltip',
                    'En caso de que el artículo de la ley que autoriza la exoneración no tenga inciso, se debe colocar cero (0).'
                  )}
                  required={isExonerationRequired}
                  component={renderField}
                  fieldType='text'
                />
              </Col>
              <Col xs={12}>
                <Field
                  name='institutionName'
                  required
                  label={I18n.get('institutionName', 'Nombre de institución')}
                  placeholder={I18n.get(
                    'selectInstitution',
                    'Selecciona una institución'
                  )}
                  component={renderField}
                  fieldType='rselect'
                  options={InstitutionCodes}
                  getOptionLabel={(option) => option.name}
                />
              </Col>
              {values?.institutionName &&
                values?.institutionName.code ===
                  InstitutionCodesEnum.OTHERS && (
                  <Col xs={12}>
                    <Field
                      name='otherInstitutionName'
                      required
                      label={I18n.get(
                        'otherInstitutionName',
                        'Otra institución'
                      )}
                      component={renderField}
                    />
                  </Col>
                )}
              <Col xs={6}>
                <Field
                  name='emissionDate'
                  required
                  label={I18n.get('emissionDate', 'Fecha de emisión')}
                  component={renderField}
                  fieldType='date'
                />
              </Col>
              <Col xs={6}>
                <Field
                  name='percentage'
                  required
                  label={I18n.get('percentage', 'Porcentaje')}
                  component={renderField}
                  type='number'
                  placeholder='0'
                />
              </Col>
              <Col xs={12}>
                <Wrapper justify={{ lg: 'end' }} gap={10}>
                  <Button
                    emphasis='outline'
                    type='button'
                    onClick={() => closeModal('exoneration')}
                    text={I18n.get('cancel', 'Cancelar')}
                  />
                  <Button
                    text={I18n.get('confirm', 'Confirmar')}
                    disabled={submitting || Object.keys(errors).length > 0}
                  />
                </Wrapper>
              </Col>
            </Row>
          </form>
        );
      }}
    </Form>
  );
}

export default ExonerationModal;
