import QRCode from 'qrcode.react';
import NequiExt from '../../../svg/paymentMethods/NequiExt';
import TpagaExt from '../../../svg/paymentMethods/TpagaExt';
import { Icon } from '@alegradev/smile-ui-react';
import { IconCheck, IconLoader2, IconRotate } from '@tabler/icons-react';
import { graphqlOperation, I18n } from 'aws-amplify';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  APIGraphqlSelector,
  station as stationSelector,
} from '../../../../selectors/app';
import { capitalize, get } from 'lodash';
import { sendNewGTMEvent } from '../../../../reducers/company';
import { handleError } from '../../../../utils/errors';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import SellerField from '../components/SellerField';
import AnnotationField from '../components/AnnotationField';
const NewQRTransfer = ({ values, form, total, anotationMaxLimit }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [checking, setChecking] = useState(false);
  const [checkingError, setCheckingError] = useState();
  const [success, setSuccess] = useState(false);
  const [code, setCode] = useState();
  const [qrId, setQRId] = useState();
  const station = useSelector(stationSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);

  // const getQRPromise = APIGraphql(graphqlOperation())

  useEffect(() => {
    ref.current = true;
    return () => (ref.current = false);
  }, []);

  const getQR = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await APIGraphql(
        graphqlOperation(mutations.createProviderPayment, {
          payment: {
            provider: values.method,
            terminal_id: get(station, 'id'),
            amount: total,
          },
        })
      );

      if (!!get(response, 'data.createProviderPayment.code')) {
        setQRId(get(response, 'data.createProviderPayment.id'));
        setCode(get(response, 'data.createProviderPayment.code'));
        dispatch(
          sendNewGTMEvent('pos-sale-payment-status', {
            methodType: values.method,
            status: 'QR generado',
            error: '',
            amount: parseFloat(total),
          })
        );
      }

      if (ref.current) {
        setLoading(false);
        setError(null);
      }
    } catch (error) {
      dispatch(
        sendNewGTMEvent('pos-sale-payment-status', {
          methodType: values.method,
          status: 'Obteniendo QR',
          error: handleError(
            error,
            capitalize(
              I18n.get(
                'getQrCodeError',
                'ocurrió un problema al crear el pago, intente de nuevo'
              )
            )
          ),
          amount: parseFloat(total),
        })
      );
      if (ref.current) {
        setLoading(false);
        setError(
          handleError(error, {
            defaultMessage: capitalize(
              I18n.get(
                'getQrCodeError',
                'ocurrió un problema al crear el pago, intente de nuevo'
              )
            ),
          })
        );
      }
    }
  }, [station, total, values.method, APIGraphql]);

  useEffect(() => {
    getQR();
  }, [getQR]);

  const checkQR = async () => {
    setChecking(true);
    setCheckingError(null);

    try {
      const response = await APIGraphql(
        graphqlOperation(queries.getProviderPayment, {
          payment: {
            id: qrId,
            provider: get(values, 'method'),
          },
        })
      );

      if (get(response, 'data.getProviderPayment.state') === 'SUCCESSFUL') {
        form.change('transfer', total);
        form.change('qrId', qrId);
        dispatch(
          sendNewGTMEvent('pos-sale-payment-status', {
            methodType: values.method,
            status: 'Pago exitoso',
            error: '',
            amount: parseFloat(total),
          })
        );
        setSuccess(true);
      }

      if (ref.current) {
        setChecking(false);
        setCheckingError(null);
      }
    } catch (error) {
      dispatch(
        sendNewGTMEvent('pos-sale-payment-status', {
          methodType: values.method,
          status: 'Verificando QR',
          error: handleError(
            error,
            capitalize(
              I18n.get(
                'checkQrCodeError',
                'no se pudo verificar el estado del pago, intente de nuevo'
              )
            )
          ),
          amount: parseFloat(total),
        })
      );
      if (ref.current) {
        setChecking(false);
        setCheckingError(
          handleError(error, {
            defaultMessage: capitalize(
              I18n.get(
                'checkQrCodeError',
                'no se pudo verificar el estado del pago, intente de nuevo'
              )
            ),
          })
        );
      }
    }
  };
  return (
    <>
      <div className='d-flex align-items-center justify-content-between w-100 mb-3'>
        {get(values, 'method') === 'nequi' && <NequiExt />}
        {get(values, 'method') === 'tpaga' && <TpagaExt />}

        <p className='text-capitalize-first h5 text-muted'>
          {I18n.get('scanTheCode', 'Escanea este código con tu app')}
        </p>
      </div>
      <div className='d-flex w-100 gap-3 justify-content-center'>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div className='form__invoice-qr-zone position-relative mb-2'>
            {!!code && (
              <QRCode
                className='position-absolute w-100 h-100 p-3'
                value={code}
                renderAs='svg'
                fgColor={checking ? 'gray' : 'black'}
                size={150}
              />
            )}

            {(!!error || !!loading || !!checking || !!success) && (
              <div className='position-absolute bg-white p-2 rounded-circle'>
                {!success && (loading || checking) && (
                  <Icon
                    icon={IconLoader2}
                    animated
                    extraClass=' icon-primary icon x2'
                  />
                )}
                {!!error && (
                  <button
                    type='button'
                    className='btn button-transparent btn-sm btn-primary rounded-circle p-1'
                    onClick={() => getQR()}
                  >
                    <Icon icon={IconRotate} extraClass='icon-white icon x2' />
                  </button>
                )}
                {success && <Icon icon={IconCheck} size='extraLarge' />}
              </div>
            )}
          </div>

          {!!qrId && !success && !checking && (
            <button
              type='button'
              className='btn button-transparent text-uppercase text-primary my-2'
              disabled={loading}
              onClick={() => checkQR()}
            >
              {I18n.get('checkQRPayment', 'verificar pago')}
            </button>
          )}

          {!!error && (
            <p className='text-danger h5 text-capitalize-first text-center mt-2'>
              {error}
            </p>
          )}

          {!!checkingError && (
            <p className='text-danger h5 text-capitalize-first text-center mt-2'>
              {checkingError}
            </p>
          )}

          {loading && (
            <p className='text-primary h5 text-capitalize-first mt-2'>
              {I18n.get('loadingQRPayment', 'generando código para pago...')}
            </p>
          )}

          {checking && (
            <p className='text-primary h5 text-capitalize-first mt-2'>
              {I18n.get('checkingQRPayment', 'verificando estado de pago...')}
            </p>
          )}
        </div>
        <div className='w-90'>
          <div className='d-flex justify-content-center mt-4'>
            <SellerField />
          </div>
          <div className='col-sm-12 pl-2 pr-0'>
            <AnnotationField
              anotationMaxLimit={anotationMaxLimit}
              type='textarea'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewQRTransfer;
