import { format } from 'date-fns';
import { get } from 'lodash';

export const costaRicaTransformer = (values, _props, newValues) => {
  const identificationType = get(values, 'identification.type', null);
  const identificationNumber = get(values, 'identification.number', null);

  const foreignId = identificationType === 'PE';

  const addressObject = get(values, 'address', null);
  const combinedAddress = [
    get(addressObject, 'combined.value', ''),
    get(addressObject, 'combined.parentValue', ''),
    get(addressObject, 'combined.provinceValue', ''),
  ].join(', ');

  const neighborhood = get(values, 'address.neighborhood', null) || get(values, 'address.neighborhood.value', null);
  const address = get(values, 'address.address', null);
  const splitAddress = combinedAddress?.split(', ');

  let newAddress = { address };
  if (!!combinedAddress && !foreignId) {
    newAddress = {
      ...newAddress,
      district: (combinedAddress && splitAddress[0]) || null,
      city: (combinedAddress && splitAddress[1]) || null,
      department: (combinedAddress && splitAddress[2]) || null,
      neighborhood,
    };
  }
  
  const exonerations = [];
  if (Array.isArray(values.exonerations)) {
    values.exonerations.forEach((exoneration) => {
      exonerations.push({
        id: exoneration?.id,
        article: exoneration?.article,
        paragraph: exoneration?.paragraph,
        percentage: exoneration?.percentage,
        documentType: exoneration?.documentType,
        emissionDate: format(new Date(exoneration?.emissionDate), 'dd/MM/yyyy'),
        documentNumber: exoneration.documentNumber,
        institutionName: exoneration.institutionName,
        otherDocumentType: exoneration.otherDocumentType,
        otherInstitutionName: exoneration.otherInstitutionName,
      });
    });
  }

  delete newValues.identification;
  return {
    ...newValues,
    identificationObject: {
      type: identificationType,
      number: identificationNumber,
    },
    exoneration: exonerations,
    economicActivity: get(values, 'economicActivity.code', null),
    address: newAddress,
    creditLimit: get(values, 'creditLimit', null),
  };
};
