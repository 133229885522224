import { Icon, Typography } from '@alegradev/smile-ui-react';
import { BaseInvoicesHistoryStrategy } from './BaseInvoicesHistoryStrategy';
import { capitalize, get } from 'lodash';
import { INVOICE_DOCUMENT_TYPES } from '../../../components/modals/invoiceRefactored/utils/constants';
import { I18n } from 'aws-amplify';
import {
  IconCheck,
  IconCircleX,
  IconDotsCircleHorizontal,
  IconProgress,
} from '@tabler/icons-react';

const EMISSION_STATUS = {
  ACCEPTED: ['Comprobante aceptado', 'informada'],
  IN_PROCESS: ['Comprobante en proceso de certificación'],
  REJECTED: ['Comprobante rechazado'],
};
export class PeruInvoicesHistoryStrategy extends BaseInvoicesHistoryStrategy {
  getIdentificationLabel({ invoice }) {
    const identificationType = get(
      invoice,
      'client.identificationObject.type',
      null
    );
    if (identificationType === 'RUC') {
      return 'RUC';
    }

    if (identificationType === 'DNI') {
      return 'DNI';
    }

    return I18n.get('identification.invoice.detail', 'Identificación');
  }
  getDetailInvoiceNumber({ invoice }) {
    const fullNumber = get(invoice, 'numberTemplate.fullNumber', null);
    const documentType = get(invoice, 'numberTemplate.documentType', null);
    const isElectronic = get(invoice, 'numberTemplate.isElectronic', false);
    if (documentType === INVOICE_DOCUMENT_TYPES.SALE_TICKET) {
      if (!isElectronic) {
        return (
          I18n.get('invoiceModalTitle.saleTicket', 'Boleta de venta') +
          ' ' +
          fullNumber
        );
      }
      return (
        I18n.get('electronicTicket', 'Boleta de venta electrónica') +
        ' ' +
        fullNumber
      );
    }

    if (documentType === INVOICE_DOCUMENT_TYPES.INVOICE) {
      if (!isElectronic) {
        return I18n.get('invoiceOfSale', 'Factura de venta') + ' ' + fullNumber;
      }

      return (
        I18n.get('electronicInvoice', 'Factura de venta electrónica') +
        ' ' +
        fullNumber
      );
    }

    return fullNumber;
  }
  getEmissionStatus({ invoice }) {
    const isElectronic = get(invoice, 'numberTemplate.isElectronic', false);
    const legalStatus = get(invoice, 'stamp.legalStatus', null);
    if (!legalStatus && isElectronic) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconDotsCircleHorizontal} color='#94A3B8' size='small' />
          <Typography
            type='caption-italic'
            text='Por emitir'
            variant='tertiary'
          />
        </div>
      );
    }
    if (EMISSION_STATUS.ACCEPTED.includes(legalStatus)) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconCheck} color='#16A34A' size='small' />
          <Typography
            type='caption-italic'
            text='Aceptada'
            variant='tertiary'
          />
        </div>
      );
    }
    if (EMISSION_STATUS.REJECTED.includes(legalStatus)) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconCircleX} color='#e11d48' size='small' />
          <Typography
            type='caption-italic'
            text='Rechazada'
            variant='tertiary'
          />
        </div>
      );
    }
    if (EMISSION_STATUS.IN_PROCESS.includes(legalStatus)) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconProgress} color='#94A3B8' size='small' />
          <Typography
            type='caption-italic'
            text='En proceso'
            variant='tertiary'
          />
        </div>
      );
    }
    if (!legalStatus) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Typography
            type='caption-italic'
            text='No electrónica'
            variant='tertiary'
          />
        </div>
      );
    }
    return (
      <div className='d-flex align-items-center gap-2'>
        <Typography
          type='caption-italic'
          text={capitalize(legalStatus)}
          variant='tertiary'
        />
      </div>
    );
  }

  getLegalStatus({ invoice }) {
    const isElectronic = get(invoice, 'numberTemplate.isElectronic', false);
    const legalStatus = get(invoice, 'stamp.legalStatus', null);
    if (!legalStatus && isElectronic) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconDotsCircleHorizontal} color='#94A3B8' size='small' />
          <Typography type='body-3-regular' text='Por emitir' />
        </div>
      );
    }
    if (!legalStatus)
      return (
        <div className='d-flex align-items-center gap-2'>
          <Typography type='body-3-regular' text='No electrónica' />
        </div>
      );

    if (EMISSION_STATUS.ACCEPTED.includes(legalStatus)) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconCheck} color='#16A34A' size='small' />
          <Typography type='body-3-regular' text='Aceptada' />
        </div>
      );
    }

    if (EMISSION_STATUS.IN_PROCESS.includes(legalStatus)) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconProgress} color='#94A3B8' size='small' />
          <Typography type='body-3-regular' text='En proceso' />
        </div>
      );
    }

    if (EMISSION_STATUS.REJECTED.includes(legalStatus)) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconCircleX} color='#e11d48' size='small' />
          <Typography type='body-3-regular' text='Rechazada' />
        </div>
      );
    }
    return (
      <div className='d-flex align-items-center gap-2'>
        <Typography type='body-3-regular' text={capitalize(legalStatus)} />
      </div>
    );
  }
}
