import React, { useCallback, useEffect } from 'react'
import { I18n } from '@aws-amplify/core'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { graphqlOperation } from 'aws-amplify'
import { get } from 'lodash'
import * as mutations from '../../graphql/mutations';
import notificationsAPIV2 from '../../reducers/notificationsAPIV2';
import { setUser } from '../../reducers/auth';
import { userSelector } from '../../selectors/auth';
import { companySelector, notificationsSelector } from '../../selectors/company';
import { APIGraphqlSelector } from '../../selectors/app';
import { notificationsTransformer } from './utils';
import { useElectronicInvoiceOfflineGroup } from '../../hooks/useElectronicInvoiceOfflineGroup';

const deployDate = '2025-01-27'

const ElectronicOfflineNotification = () => {
  const dispatch = useDispatch();
  const { isInGroup } = useElectronicInvoiceOfflineGroup();
  const user = useSelector(userSelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const notifications = useSelector(notificationsSelector);
  const company = useSelector(companySelector);
  const hasFEactive = get(company, 'settings.electronicInvoicing', false);
  const registryDate = get(company, 'registryDate', '');

  const selectedBody = useCallback(() => {
    const notificationInfo = {
      userId: user.idGlobal,
      title: I18n.get('ElectronicOfflineNotification.title', '¿Sin internet? ¡No hay problema! 🎉'),
      text: I18n.get('ElectronicOfflineNotification.body', 'Ahora puedes facturar con numeraciones electrónicas incluso sin conexión. Generaremos un ticket provisional con un código QR para que tus clientes consulten y descarguen su factura fácilmente una vez que se sincronice.'),
      actionCall: process.env.REACT_APP_ALEGRA_COOKIE === 'al-test-days-of-thunder' ? 'https://almost-pos.alegra.com/settings/invoices' : 'https://pos.alegra.com/settings/invoices',
      actionLabel: I18n.get('ElectronicOfflineNotification.action', 'Ir a configuración'),
      type: "info",
    };
    return notificationInfo;
  }, [user.idGlobal])

  useEffect(() => {
    async function checkElectronicOfflineNotification() {
      const notificationName = 'electronic-offline-notification';
      if (notifications?.find(n => n[notificationName])) return;
      if (!hasFEactive) return;
      if (!isInGroup) return;
      if (dayjs(registryDate).isAfter(deployDate)) return;

      try {
        // create cookie to validate if the notification has been shown
        const response = await APIGraphql(graphqlOperation(mutations.updateUser, notificationsTransformer(notificationName)));
        if (!!get(response, 'data.updateUser.id', null)) {
          dispatch(setUser(get(response, 'data.updateUser')))
        }
        await notificationsAPIV2.createNotification(APIGraphql,
          notificationBody({
            ...selectedBody(),
          }));

      } catch (err) {
        console.log("error while creating notification", err);
      }
    }
    checkElectronicOfflineNotification();
    //eslint-disable-next-line
  }, [isInGroup, notifications])
  return (
    <></>
  )
}
const notificationBody = ({ userId, from = null, to = null, section = null, title, text, actionCall, actionLabel, type }) => ({
  name: "electronic-offline-notification-pos",
  teamInCharge: "pos",
  fromDate: from ? from : dayjs().toISOString(),
  toDate: to ? to : dayjs().add(1, 'hour').toISOString(),
  messages: [
    {
      channel: "web",
      category: "new-feature",
      type: 'localized',
      closeable: true,
      section: section ? section : "index-pos-home",
      title,
      text,
      application: "alegra-pos",
      action: {
        actionCall,
        actionLabel
      },
      style: {
        type,
      }
    }
  ]
})
export default ElectronicOfflineNotification;