import { I18n } from 'aws-amplify';
import { capitalize } from 'lodash';
import { EXONERATION_DOCUMENT_TYPES } from '../../../../../../components/countriesData/costaRica/exonerationDocumentTypes';
import { InstitutionCodesEnum } from '../../../../../../components/countriesData/costaRica/institutionNames';

export const costaRicaExonerationValidation = (values) => {
  let errors = {};

  if (!values.documentType) {
    errors.documentType = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );
  }

  const requiredCodes = [
    EXONERATION_DOCUMENT_TYPES.TAX_EXEMPT_SALES_TO_DIPLOMATS,
    EXONERATION_DOCUMENT_TYPES.AUTHORIZED_BY_SPECIAL_LAW,
    EXONERATION_DOCUMENT_TYPES.TOURISM_SERVICES_REGISTERED_WITH_ICT,
    EXONERATION_DOCUMENT_TYPES.RECYCLING_AND_REUSABLES_EXEMPTION,
    EXONERATION_DOCUMENT_TYPES.FREE_TRADE_ZONE_EXEMPTION,
  ];
  const isExonerationRequired = requiredCodes.includes(
    values?.documentType?.code
  );

  if (
    values?.documentType &&
    values.documentType.code === EXONERATION_DOCUMENT_TYPES.OTHERS &&
    !values.otherDocumentType
  ) {
    errors.otherDocumentType = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );
  }

  if (values?.otherDocumentType && values?.otherDocumentType.length > 100) {
    errors.otherDocumentType = capitalize(
      I18n.get(
        'theMaximumSizeForThisFieldIs',
        'El tamaño maximo para este campo es de'
      ) +
        ' 100 ' +
        I18n.get('characteres', 'caracteres')
    );
  }

  if (values?.otherDocumentType && values?.otherDocumentType.length < 5) {
    errors.otherDocumentType = capitalize(
      I18n.get(
        'theMinimumSizeForThisFieldIs',
        'El tamaño minimo para este campo es de'
      ) +
        ' 5 ' +
        I18n.get('characteres', 'caracteres')
    );
  }

  if (!values?.article && isExonerationRequired) {
    errors.article = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );
  }

  if (values?.article && values?.article.length > 6) {
    errors.article = capitalize(
      I18n.get(
        'theMaximumSizeForThisFieldIs',
        'El tamaño maximo para este campo es de'
      ) +
        ' 6 ' +
        I18n.get('characteres', 'caracteres')
    );
  }

  if (!values?.paragraph && isExonerationRequired) {
    errors.paragraph = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );
  }

  if (values?.paragraph && values?.paragraph.length > 6) {
    errors.paragraph = capitalize(
      I18n.get(
        'theMaximumSizeForThisFieldIs',
        'El tamaño maximo para este campo es de'
      ) +
        ' 6 ' +
        I18n.get('characteres', 'caracteres')
    );
  }

  if (!values?.documentNumber) {
    errors.documentNumber = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );
  }

  if (values?.documentNumber && values?.documentNumber.length > 40) {
    errors.documentNumber = capitalize(
      I18n.get(
        'theMaximumSizeForThisFieldIs',
        'El tamaño maximo para este campo es de'
      ) +
        ' 40 ' +
        I18n.get('characteres', 'caracteres')
    );
  }

  if (values?.documentNumber && values?.documentNumber.length < 3) {
    errors.documentNumber = capitalize(
      I18n.get(
        'theMinimumSizeForThisFieldIs',
        'El tamaño minimo para este campo es de'
      ) +
        ' 3 ' +
        I18n.get('characteres', 'caracteres')
    );
  }

  if (!values?.institutionName) {
    errors.institutionName = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );
  }

  if (
    values?.institutionName &&
    values.institutionName.code === InstitutionCodesEnum.OTHERS &&
    !values.otherInstitutionName
  ) {
    errors.otherInstitutionName = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );
  }

  if (
    values?.otherInstitutionName &&
    values?.otherInstitutionName.length > 100
  ) {
    errors.otherInstitutionName = capitalize(
      I18n.get(
        'theMaximumSizeForThisFieldIs',
        'El tamaño maximo para este campo es de'
      ) +
        ' 100 ' +
        I18n.get('characteres', 'caracteres')
    );
  }

  if (values?.otherInstitutionName && values?.otherInstitutionName.length < 5) {
    errors.otherInstitutionName = capitalize(
      I18n.get(
        'theMinimumSizeForThisFieldIs',
        'El tamaño minimo para este campo es de'
      ) +
        ' 5 ' +
        I18n.get('characteres', 'caracteres')
    );
  }

  if (!values?.emissionDate) {
    errors.emissionDate = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );
  }

  if (!values?.percentage) {
    errors.percentage = capitalize(
      I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')
    );
  }

  if (values?.percentage && values?.percentage < 0) {
    errors.percentage = capitalize(
      I18n.get('theValueMustBeGreaterThan', 'El valor debe ser mayor a') + ' 0'
    );
  }

  if (values?.percentage && values?.percentage > 100) {
    errors.percentage = capitalize(
      I18n.get('theValueMustBeLessThan', 'El valor debe ser menor a') + ' 100'
    );
  }

  return errors;
};
