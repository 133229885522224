import { Switch, Route } from 'react-router-dom';

import { Main } from './main';
import { Bill } from './bill';
import { BillDetail } from './detail';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { country as countrySelector } from '../../selectors/company';
import { COUNTRIES } from '../../utils/enums/countries';
import { useEffect } from 'react';

function BillsRouter() {
  const history = useHistory();
  const country = useSelector(countrySelector);

  useEffect(() => {
    if (![COUNTRIES.ARGENTINA, COUNTRIES.INTERNATIONAL, COUNTRIES.PANAMA].includes(country)) {
      history.push('/');
    }
  }, [country, history]);

  return (
    <Switch>
      <Route
        path='/bills/bill/:id/detail'
        component={BillDetail}
      />
      <Route
        path='/bills/bill/:id?'
        component={Bill}
      />
      <Route path='/bills' component={Main} />
    </Switch>
  );
}

export default BillsRouter;
