import { Container, PageHeading, useModal } from '@alegradev/smile-ui-react';
import { useHistory } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import { memo, useEffect, useMemo } from 'react';

import Table from './table/Table';
import { IconPlus } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermissionTo } from '../../../selectors/auth';
import { firstTimeInBills } from '../../../selectors/app';
import { setFirstTimeInBills } from '../../../reducers/app';
import { sendNewGTMEvent } from '../../../reducers/company';
import { membershipACSelector } from '../../../selectors/membership';
import illustration from '../illustrations/Cart-Logo.svg';
import {
  country as countrySelector,
  membershipSelector,
} from '../../../selectors/company';
import { COUNTRIES } from '../../../utils/enums/countries';

const MODAL_NAME = 'BILL_PLAN_UPGRADE';

function Main() {
  const can = useSelector(hasPermissionTo);
  const { openModal, closeModal } = useModal();
  const history = useHistory();
  const firstTimeInBillsSelector = useSelector(firstTimeInBills);
  const dispatch = useDispatch();
  const webPlan = useSelector(membershipACSelector);
  const posPlan = useSelector(membershipSelector);
  const country = useSelector(countrySelector);

  const handleCreateBill = () => {
    const isAlegraPlanRestricted = !['readonly', 'demo'].includes(
      webPlan?.plan?.keyword
    );
    const hasPosPlusOrHighter = [
      'plus-pos',
      'pyme-pos',
      'pro-pos',
      'premium-pos',
      'demo',
    ].includes(posPlan?.plan?.keyword);
    if (isAlegraPlanRestricted || hasPosPlusOrHighter) {
      history.push('/bills/bill');
    } else {
      openModal({
        name: MODAL_NAME,
        title: I18n.get('billFeatureLimitedTitle', 'Actualiza tu plan!'),
        illustration,
        type: 'feature',
        description: I18n.get(
          'billFeatureLimitedDescription',
          'Esta función solo está disponible para planes Pyme, Pro, Plus y Premium.'
        ),
        primaryAction: {
          text: I18n.get('checkPlan', 'Revisar plan'),
          onClick: () =>
            window.open(
              `${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans`,
              '__blank'
            ),
          closeOnClick: true,
        },
        secondaryAction: {
          text: I18n.get('omit', 'Omitir'),
          onClick: () => closeModal(MODAL_NAME),
        },
      });
    }
  };

  useEffect(() => {
    if (firstTimeInBillsSelector) {
      dispatch(setFirstTimeInBills(false));
    }

    sendNewGTMEvent('pos-index-bill-visited', {
      First: firstTimeInBillsSelector,
    });
  }, [firstTimeInBillsSelector]);

  const handleKnowMore = () => {
    switch (country) {
      case COUNTRIES.INTERNATIONAL:
        window.open(
          'https://ayuda.alegra.com/es/registro-de-compras-en-alegra-pos',
          '__blank'
        );
        break;
      case COUNTRIES.PANAMA:
        window.open(
          'https://ayuda.alegra.com/es/registro-de-compras-en-alegra-pos-pan',
          '__blank'
        );
        break;
      case COUNTRIES.ARGENTINA:
        window.open(
          'https://ayuda.alegra.com/es/registro-de-compras-en-alegra-pos-arg',
          '__blank'
        );
        break;
      default:
        break;
    }
  };

  return (
    <Container marginTop={30}>
      <PageHeading
        title={I18n.get('bills', 'Compras')}
        description={I18n.get(
          'billDescription',
          'Registra tus compras de productos y mantén actualizadas las cantidades en tu inventario.'
        )}
        descriptionExtra={{
          label: I18n.get('knowMore', 'Saber más'),
          onClick: handleKnowMore,
        }}
        actions={[
          {
            leftIcon: IconPlus,
            text: I18n.get('newBill', 'Nueva compra'),
            action: handleCreateBill,
            disabled: !can('add', 'bills'),
          },
        ]}
      />
      <Table handleCreateBill={handleCreateBill} />
    </Container>
  );
}

export default memo(Main);
