import { capitalize, get } from 'lodash';
import { BaseInvoicesHistoryStrategy } from './BaseInvoicesHistoryStrategy';
import { getFormattedNumber } from '../../../utils/invoices';
import { COUNTRIES } from '../../../utils/enums/countries';
import { I18n } from 'aws-amplify';
import { INVOICE_DOCUMENT_TYPES } from '../../../components/modals/invoiceRefactored/utils/constants';
import { getInvoiceSubType } from '../../../components/print/defaults/utilities';
import { LEGAL_STATUSES } from '../../../utils/enums/legalStatus';
import { Icon, Typography } from '@alegradev/smile-ui-react';
import {
  IconCheck,
  IconCircleX,
  IconDotsCircleHorizontal,
  IconProgress,
} from '@tabler/icons-react';

const EMISSION_STATUS = {
  ACCEPTED: [
    LEGAL_STATUSES.ACCEPTED,
    LEGAL_STATUSES.ACCEPTED_WITH_OBSERVATIONS,
    LEGAL_STATUSES.STAMPED_AND_ACCEPTED,
  ],
  IN_PROCESS: [
    LEGAL_STATUSES.PENDING,
    LEGAL_STATUSES.STAMPED_AND_WAITING_RESPONSE,
  ],
  REJECTED: [LEGAL_STATUSES.REJECTED, LEGAL_STATUSES.STAMPED_AND_REJECTED],
};
export class ArgentinaInvoicesHistoryStrategy extends BaseInvoicesHistoryStrategy {
  getIdentificationLabel({ invoice }) {
    const identificationType = get(
      invoice,
      'client.identificationObject.type',
      null
    );
    if (identificationType === 'CUI') {
      return 'CUI';
    }

    if (identificationType === 'DNI') {
      return 'DNI';
    }

    return I18n.get('identification.invoice.detail', 'Identificación');
  }
  getInvoiceNumber({ invoice }) {
    const numberTemplate = get(invoice, 'numberTemplate', null);
    return (
      'Factura ' +
      get(numberTemplate, 'prefix', null) +
      '-' +
      getFormattedNumber(
        invoice,
        get(numberTemplate, 'number', null),
        COUNTRIES.ARGENTINA
      )
    );
  }

  getDetailInvoiceNumber({ invoice, numerations }) {
    const numberTemplate = get(invoice, 'numberTemplate', null);
    const documentType = get(invoice, 'numberTemplate.documentType', null);
    const isElectronic = get(invoice, 'numberTemplate.isElectronic', false);

    if (documentType === INVOICE_DOCUMENT_TYPES.INVOICE) {
      if (!isElectronic) {
        return (
          I18n.get('invoiceOfSale', 'Factura de venta') +
          ' ' +
          getInvoiceSubType(invoice, COUNTRIES.ARGENTINA, numerations) +
          ' ' +
          get(numberTemplate, 'prefix', null) +
          '-' +
          getFormattedNumber(
            invoice,
            get(numberTemplate, 'number', null),
            COUNTRIES.ARGENTINA
          )
        );
      }

      return (
        I18n.get('electronicInvoice', 'Factura de venta electrónica') +
        ' ' +
        getInvoiceSubType(invoice, COUNTRIES.ARGENTINA, numerations) +
        ' ' +
        get(numberTemplate, 'prefix', null) +
        '-' +
        getFormattedNumber(
          invoice,
          get(numberTemplate, 'number', null),
          COUNTRIES.ARGENTINA
        )
      );
    }

    return (
      get(numberTemplate, 'prefix', null) +
      '-' +
      getFormattedNumber(
        invoice,
        get(numberTemplate, 'number', null),
        COUNTRIES.ARGENTINA
      )
    );
  }

  getEmissionStatus({ invoice }) {
    const isElectronic = get(invoice, 'numberTemplate.isElectronic', false);
    const legalStatus = get(invoice, 'stamp.legalStatus', null);
    if (!legalStatus && isElectronic) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconDotsCircleHorizontal} color='#94A3B8' size='small' />
          <Typography
            type='caption-italic'
            text='Por emitir'
            variant='tertiary'
          />
        </div>
      );
    }
    if (EMISSION_STATUS.ACCEPTED.includes(legalStatus)) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconCheck} color='#16A34A' size='small' />
          <Typography
            type='caption-italic'
            text='Aceptada'
            variant='tertiary'
          />
        </div>
      );
    }
    if (EMISSION_STATUS.REJECTED.includes(legalStatus)) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconCircleX} color='#e11d48' size='small' />
          <Typography
            type='caption-italic'
            text='Rechazada'
            variant='tertiary'
          />
        </div>
      );
    }
    if (EMISSION_STATUS.IN_PROCESS.includes(legalStatus)) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconProgress} color='#94A3B8' size='small' />
          <Typography
            type='caption-italic'
            text='En proceso'
            variant='tertiary'
          />
        </div>
      );
    }
    if (!legalStatus && !isElectronic) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Typography
            type='caption-italic'
            text='No electrónica'
            variant='tertiary'
          />
        </div>
      );
    }
    return (
      <div className='d-flex align-items-center gap-2'>
        <Typography
          type='caption-italic'
          text={capitalize(legalStatus)}
          variant='tertiary'
        />
      </div>
    );
  }

  getLegalStatus({ invoice }) {
    const isElectronic = get(invoice, 'numberTemplate.isElectronic', false);
    const legalStatus = get(invoice, 'stamp.legalStatus', null);
    if (!legalStatus && isElectronic) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconDotsCircleHorizontal} color='#94A3B8' size='small' />
          <Typography type='body-3-regular' text='Por emitir' />
        </div>
      );
    }
    if (!legalStatus)
      return (
        <div className='d-flex align-items-center gap-2'>
          <Typography type='body-3-regular' text='No electrónica' />
        </div>
      );

    if (EMISSION_STATUS.ACCEPTED.includes(legalStatus)) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconCheck} color='#16A34A' size='small' />
          <Typography type='body-3-regular' text='Aceptada' />
        </div>
      );
    }

    if (EMISSION_STATUS.IN_PROCESS.includes(legalStatus)) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconProgress} color='#94A3B8' size='small' />
          <Typography type='body-3-regular' text='En proceso' />
        </div>
      );
    }

    if (EMISSION_STATUS.REJECTED.includes(legalStatus)) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconCircleX} color='#e11d48' size='small' />
          <Typography type='body-3-regular' text='Rechazada' />
        </div>
      );
    }
    return (
      <div className='d-flex align-items-center gap-2'>
        <Typography type='body-3-regular' text={capitalize(legalStatus)} />
      </div>
    );
  }
}
