import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';
import { BigNumber } from 'bignumber.js';

import { closeModal } from '../../../reducers/modals';
import { companySelector, country as countrySelector } from '../../../selectors/company';
import Success from '../../svg/NewSuccess';
import InvoiceSaving from './InvoiceSaving';
import useOfflineStatusAvailable from '../../../hooks/useOfflineStatusAvailable/hook';
import useConnectionStatus from '../../../hooks/useConnectionStatus/hook';
import { useApiAvailable } from '../../../hooks/useApiAvailable';
import InvoiceOfflineSaved from '../invoiceSaved/InvoiceOfflineSaved';
import { Typography, useDeviceType, useModal } from '@alegradev/smile-ui-react';
import { useFormat } from '../../../hooks/useFormat';
import { getTitle } from '../invoiceSaved/util';
import { newShareInvoiceAvailable } from '../ShareInvoice/utils';
import NewBodyInvoiceSaved from './NewBodyInvoiceSaved';
import NewBodyEmailSaved from './NewBodyEmailSaved';
import Bottom from './Bottom';
import { showNewInvoiceModal } from '../newInvoice/utils';
import InvoiceOfflineBody from '../invoiceSaved/InvoiceOfflineBody';

const NewInvoiceSaved = () => {
  const { isVersionAvailable } = useOfflineStatusAvailable();
  const isOnline = useConnectionStatus();
  const isApiAvailable = useApiAvailable();
  const deviceType = useDeviceType();
  const dispatch = useDispatch();
  const invoiceCreated = useSelector((state) =>
    get(state, 'print.invoice', null)
  );

  const isOpen = useSelector((state) =>
    get(state, 'modals.newInvoiceSaved.isOpen', false)
  );
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const items = get(invoiceCreated, 'items', null);
  const { fmt, decimal } = useFormat();
  const { closeModal: closeNewModal } = useModal();

  const handleClose = () => {
    dispatch(closeModal({ modal: 'newInvoiceSaved' }));
    if (!items) return;
  };

  const handleCloseNewInvoiceModal = () => {
    closeNewModal('NewInvoiceSaved');
  };

  if (
    (isVersionAvailable && (!isOnline || !isApiAvailable)) ||
    window.Cypress
  ) {
    if (showNewInvoiceModal({country, company})) {
      return (
        <InvoiceOfflineBody
          invoiceCreated={invoiceCreated}
          handleClose={handleCloseNewInvoiceModal}
        />
      );
    }
    return (
      <InvoiceOfflineSaved
        isOpen={isOpen}
        isOnline={isOnline}
        handleClose={handleClose}
        invoiceCreated={invoiceCreated}
      />
    );
  }

  return (
    <div className='new-invoice-saved'>
      <div>
        {!!invoiceCreated && !!invoiceCreated.timestamp ? (
          <InvoiceSaving invoiceCreated={invoiceCreated} />
        ) : (
          <>
            <div className='p-3'>
              <Typography
                text={getTitle(invoiceCreated, country)}
                type='heading-4'
              />
            </div>
            <div
              className={`d-flex flex-column p-3 justify-content-center align-items-center ${deviceType === 'mobile' ? 'mt-5' : ''}`}
            >
              <div className='p-4 d-flex flex-column justify-content-center align-items-center gap-3'>
                <Success />
                <div className='modal__invoice-success-title'>
                  <Typography
                    text={I18n.get('invoiceCreatedSuccessfully', '¡Muy bien!')}
                    type='heading-4'
                  />
                </div>
                <div className='modal__invoice-success-message'>
                  <Typography
                    text={I18n.get(
                      'yourSaleWasSavedSuccessfully',
                      'Tu venta se guardó con éxito'
                    )}
                    type='body-2-regular'
                  />
                </div>
              </div>
              {!!invoiceCreated && (
                <div className='new-invoice-saved-total-container'>
                  <div className='d-flex align-items-center gap-3 modal__invoice-success-total'>
                    <div>
                      <Typography
                        text={I18n.get('total', 'total')}
                        type='heading-4'
                      />
                    </div>
                    <div>
                      <Typography
                        text={new BigNumber(invoiceCreated.total).toFormat(
                          decimal,
                          fmt
                        )}
                        type='heading-4'
                      />
                    </div>
                  </div>
                  <div className='d-flex align-items-center gap-3 modal__invoice-success-total'>
                    <div>
                      <Typography
                        text={I18n.get('change', 'cambio')}
                        type='body-3-regular'
                        variant='secondary'
                      />
                    </div>
                    <div>
                      <Typography
                        text={new BigNumber(
                          invoiceCreated?.cashReturned
                        ).toFormat(decimal, fmt)}
                        type='body-3-regular'
                        variant='secondary'
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {newShareInvoiceAvailable({ country }) ? (
        <NewBodyInvoiceSaved />
      ) : (
        <NewBodyEmailSaved />
      )}
      <Bottom
        primaryButton={{
          text: I18n.get('newSale', 'Nueva venta'),
        }}
        secondaryButton={{ text: I18n.get('print', 'Imprimir') }}
        invoiceCreated={invoiceCreated}
      />
    </div>
  );
};

export default NewInvoiceSaved;
