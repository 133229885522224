import React, { useMemo } from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import { closeMenu } from '../../reducers/app';
import { openModal } from '../../reducers/modals';
import { hasPermissionTo } from '../../selectors/auth';
import {
  shiftsEnabled,
  isOnlyInvoicingPlan,
  membershipSelector,
  country as countrySelector,
} from '../../selectors/company';
import Header from './Header';
import Bottom from './Bottom';
import { sendGTMEvent } from '../../reducers/company';
import NavigationMenu from '../common/Navigation';
import useElectronicPayrollStatus from '../../hooks/useElectronicPayrollStatus/hook';
import useContactsVersionsGroup from '../../hooks/useContactsVersionsGroup/hook';
import ElectronicPayrollStatus from '../settings/Invoices/ElectronicPayrollStatus';
import { getSystemVersion } from '../../utils/platform';
import { getPlanKeyword, membershipACSelector } from '../../selectors/membership';
import { COUNTRIES } from '../../utils/enums/countries';
import { PRODUCTS } from '../../utils/enums/products';
import { useConnectionStatus } from '../../hooks/useConnectionStatus';
import useOfflineStatusAvailable from '../../hooks/useOfflineStatusAvailable/hook';

import { Icon, Tooltip, Typography } from '@alegradev/smile-ui-react';
import { isElectronicPOSDocumentAvailableSelector } from '../../selectors/numerations';
import { IconArrowUpRight, IconBasket, IconCash, IconCircleArrowDownLeft, IconCrown, IconFileReport, IconFileText, IconHome, IconPackage, IconSettings, IconSwitchVertical, IconUsers } from '@tabler/icons-react';
import { showSelfInvoice } from '../../pages/invoices/utils/selfInvoiceUtil';

const Navigation = () => {
  const dispatch = useDispatch();
  const isOnline = useConnectionStatus();
  const { isVersionAvailable } = useOfflineStatusAvailable();
  const { canElectronicPayroll, isActive } = useElectronicPayrollStatus();
  const { isContactsActive } = useContactsVersionsGroup();
  const isPosElectronicActive = useSelector(
    isElectronicPOSDocumentAvailableSelector
  );
  const can = useSelector(hasPermissionTo);
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan);
  const menuOpen = useSelector((store) => store.app.menuOpen);
  const isShiftsEnabled = useSelector(shiftsEnabled);
  const membership = useSelector(membershipSelector);
  const country = useSelector(countrySelector);
  const planKeyword = useSelector(getPlanKeyword);
  const webPlan = useSelector(membershipACSelector);

  // incluido para los usuarios antiguos sin plan en POS
  const showPlans = true;

  const isGlobalInvoicesActive = useMemo(() => {
    if (![COUNTRIES.MEXICO].includes(country)) return false;
    return true;
  }, [country]);

  const isContactsMenuActive = useMemo(() => {
    if (country === COUNTRIES.MEXICO && !isActive) return false;
    return true;
  }, [country, isActive]);

  const isBillsMenuActive = useMemo(() => {
    if (![COUNTRIES.ARGENTINA, COUNTRIES.INTERNATIONAL, COUNTRIES.PANAMA].includes(country)) return false;
    return true;
  }, [country]);

  const isBillsMenuRestricted = useMemo(()=> {
    const isAlegraPlanRestricted = !['readonly', 'demo'].includes(webPlan?.plan?.keyword );
    const hasPosPlusOrHighter = [
      'plus-pos',
      'pyme-pos',
      'pro-pos',
      'premium-pos',
      'demo'
    ].includes(membership?.plan?.keyword);

    return isAlegraPlanRestricted || hasPosPlusOrHighter
  }, [membership, webPlan])

  return (
    <div
      className={`new-navigation ${menuOpen ? 'open' : 'closed'}`}
      onClick={() => dispatch(closeMenu())}
    >
      <div
        className='new-navigation-menu'
        onClick={(event) => event.stopPropagation()}
      >
        <Header />
        <NavigationMenu
          items={[
            {
              to: '/',
              menuName: '/',
              action: () => dispatch(closeMenu()),
              title: I18n.get('checkIn', 'facturar'),
              icon: <Icon icon={IconHome} size='small' />,
            },
            {
              to: '/electronic-documents',
              menuName: '/electronic-documents',
              action: () => dispatch(closeMenu()),
              title: I18n.get('electronicDocuments', 'Documentos electrónicos'),
              icon: <Icon icon={IconFileText} size='small' />,
              hidden: country !== COUNTRIES.COLOMBIA || (isPosElectronicActive && isActive),
              disabled: {
                status: !isOnline && isVersionAvailable,
                message: I18n.get('offline_menu_blocked', 'Podrás acceder cuando tengas conexión'),
              },
            },
            {
              onlyInvoicingPlan,
              title: I18n.get('incomes', 'Ingresos'),
              icon: <Icon icon={IconCircleArrowDownLeft} size='small' />,
              menuName: 'invoices',
              hidden: country !== 'colombia',
              disabled: {
                status: !isOnline && isVersionAvailable,
                message: I18n.get('offline_menu_blocked', 'Podrás acceder cuando tengas conexión'),
              },
              items: [
                {
                  to: '/invoices',
                  menuName: '/invoices',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get('salesHistory', 'Historial de ventas'),
                },
                {
                  action: () => {
                    dispatch(closeMenu());
                    dispatch(openModal({ modal: 'dailyReport' }));
                    dispatch(
                      sendGTMEvent('daily-report-voucher-attempted', {
                        creationStatus: 'success',
                      })
                    );
                  },
                  isMenu: false,
                  menuName: '/dailyReport',
                  title: I18n.get(
                    'dailyReport',
                    'Comprobante de informe diario'
                  ),
                },
                // ...(!isActive && canElectronicPayroll
                //   ? [
                //       {
                //         isNavAction: true,
                //         component: <ElectronicPayrollStatus />,
                //       },
                //     ]
                //   : []),
              ],
            },
            ...([
              'peru',
              'argentina',
              'costaRica',
              'republicaDominicana',
              'panama',
              'mexico',
            ].includes(country) &&
            !!canElectronicPayroll
              ? [
                  {
                    onlyInvoicingPlan,
                    title: I18n.get('incomes', 'Ingresos'),
                    menuName: 'invoices',
                    icon: <Icon icon={IconCircleArrowDownLeft} size='small' />,
                    disabled: {
                      status: !isOnline && isVersionAvailable,
                      message: I18n.get('offline_menu_blocked', 'Podrás acceder cuando tengas conexión'),
                    },
                    items: [
                      {
                        to: '/invoices',
                        menuName: '/invoices',
                        action: () => dispatch(closeMenu()),
                        title: I18n.get(
                          'salesHistory',
                          'Historial de ventas'
                        ),
                      },
                      ...(isGlobalInvoicesActive
                        ? [
                            {
                              to: '/global-invoices',
                              menuName: '/globalInvoices',
                              action: () => dispatch(closeMenu()),
                              title: I18n.get(
                                'globalInvoices',
                                'Facturas globales'
                              ),
                            },
                          ]
                        : []),
                      ...(!isActive && canElectronicPayroll
                        ? [
                            {
                              component: <ElectronicPayrollStatus />,
                            },
                          ]
                        : []),
                    ],
                  },
                ]
              : [
                  {
                    onlyInvoicingPlan,
                    to: '/invoices',
                    menuName: '/invoices',
                    exact: true,
                    action: (e) =>
                      !onlyInvoicingPlan
                        ? dispatch(closeMenu())
                        : e.preventDefault(),
                    title: I18n.get('incomes', 'Ingresos'),
                    icon: <Icon icon={IconCircleArrowDownLeft} size='small' />,
                    disabled: {
                      status: !isOnline && isVersionAvailable,
                      message: I18n.get('offline_menu_blocked', 'Podrás acceder cuando tengas conexión'),
                    },
                    hidden: country === 'colombia',
                  },
                ]),
            {
              title: I18n.get('shifts', 'turnos'),
              icon: <Icon icon={IconFileReport} size='small' />,
              menuName: 'shifts',
              disabled: {
                status: !isOnline && isVersionAvailable,
                message: I18n.get('offline_menu_blocked', 'Podrás acceder cuando tengas conexión'),
              },
              hidden: !(
                !!isShiftsEnabled &&
                !onlyInvoicingPlan &&
                can('index', 'pos-cashier')
              ),
              items: [
                {
                  to: '/shifts',
                  menuName: '/shifts',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get('shiftHistory', 'Historial de turnos'),
                },
                {
                  to: '/statistics',
                  menuName: '/statistics',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get('shiftStatistics', 'Reportes de turnos'),
                },
              ],
            },
            {
              onlyInvoicingPlan,
              to: '/payments',
              menuName: '/payments',
              action: (e) =>
                !onlyInvoicingPlan
                  ? dispatch(closeMenu())
                  : e.preventDefault(),
              title: I18n.get('cashManagment', 'gestión de efectivo'),
              disabled: {
                status: !isOnline && isVersionAvailable,
                message: I18n.get('offline_menu_blocked', 'Podrás acceder cuando tengas conexión'),
              },
              icon: <Icon icon={IconCash} size='small' />,
            },
            {
              onlyInvoicingPlan,
              to: '/refunds',
              menuName: '/refunds',
              action: (e) =>
                !onlyInvoicingPlan
                  ? dispatch(closeMenu())
                  : e.preventDefault(),
              title: I18n.get('refunds', 'devoluciones'),
              disabled: {
                status: !isOnline && isVersionAvailable,
                message: I18n.get('offline_menu_blocked', 'Podrás acceder cuando tengas conexión'),
              },
              icon: <Icon icon={IconSwitchVertical} size='small' />,
            },
            {
              to: '/contacts',
              menuName: '/contacts',
              action: () => dispatch(closeMenu()),
              title: I18n.get('contacts', 'Contactos'),
              icon: <Icon icon={IconUsers} size='small' />,
              disabled: {
                status: !isOnline && isVersionAvailable,
                message: I18n.get('offline_menu_blocked', 'Podrás acceder cuando tengas conexión'),
              },
              hidden: !isContactsActive,
            },
            {
              title: I18n.get('inventory', 'Inventario'),
              icon: <Icon icon={IconPackage} size='small' />,
              menuName: '/inventory',
              disabled: {
                status: !isOnline && isVersionAvailable,
                message: I18n.get('offline_menu_blocked', 'Podrás acceder cuando tengas conexión'),
              },
              items: [
                {
                  to: '/inventory/items',
                  menuName: '/inventory/items',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get(
                    'itemsAndServices',
                    'Productos y servicios'
                  ),
                },
                {
                  to: '/inventory/inventory-adjustments',
                  action: () => {
                    dispatch(
                      sendGTMEvent('inventory-adjustments-menu-visited')
                    );
                    dispatch(closeMenu());
                  },
                  menuName: '/inventory/inventory-adjustments',
                  title: I18n.get(
                    'inventoryAdjustments',
                    'Ajustes de inventario'
                  ),
                },
                {
                  to: '/inventory/warehouses',
                  menuName: '/inventory/warehouses',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get('warehouses', 'Bodegas'),
                },
                {
                  to: '/inventory/price-lists',
                  menuName: '/inventory/price-lists',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get('priceLists', 'Lista de precios'),
                },
                {
                  to: '/inventory/categories',
                  menuName: '/inventory/categories',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get('categories', 'Categorias'),
                },
                {
                  to: '/inventory/attributes',
                  menuName: '/inventory/attributes',
                  action: () => dispatch(closeMenu()),
                  title: I18n.get('attributes', 'Atributos'),
                },
              ],
            },
            {
              to: '/bills',
              menuName: '/bills',
              action: () => dispatch(closeMenu()),
              title: I18n.get('shopping', 'Compras'),
              icon: <Icon icon={IconBasket} size='small' />,
              hidden: !isBillsMenuActive,
              disabled: {
                status: !isOnline && isVersionAvailable,
                message: I18n.get('offline_menu_blocked', 'Podrás acceder cuando tengas conexión'),
              },
              extraIcon: !isBillsMenuRestricted ? <div style={{marginRight: 130}}>
                            <Tooltip placement='right' overlay={I18n.get('billFeatureLimitedDescription', 'El registro de facturas de compra está disponible en los planes Pyme, Pro, Plus y Premium.')}>
                              <Icon icon={IconCrown} type='primary' />
                            </Tooltip>
                          </div> : null,
              extra: {
                title: I18n.get('new', 'Nuevo'),
                variant: 'indigo',
              },
            },
            {
              to: '/settings',
              menuName: '/settings',
              exact: true,
              action: () => dispatch(closeMenu()),
              title: I18n.get('settings', 'configuraciones'),
              disabled: {
                status: !isOnline && isVersionAvailable,
                message: I18n.get('offline_menu_blocked', 'Podrás acceder cuando tengas conexión'),
              },
              icon: <Icon icon={IconSettings} size='small' />,
            },
            {
              to: ['demo', 'readonly'].includes(planKeyword)
                ? `${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans`
                : `${process.env.REACT_APP_ALEGRA_SMILE}subscriptions`,
              target: '_blank',
              menuName: 'alegra',
              action: () => dispatch(closeMenu()),
              title: I18n.get('mySubscription', 'Mi suscripción'),
              extra: {
                title: get(membership, 'plan.name'),
                variant: 'slate',
              },
              hidden: !showPlans,
              disabled: {
                status: !isOnline && isVersionAvailable,
                message: I18n.get('offline_menu_blocked', 'Podrás acceder cuando tengas conexión'),
              },
              isMenu: false,
            },
            {
              action: () => {
                dispatch(closeMenu());
                dispatch(openModal({ modal: 'shortcuts' }));
              },
              menuName: 'keyboardShortcuts',
              title: I18n.get('keyboardShortcuts', 'atajos del teclado'),
              extraIcon: <Typography tag='span' variant='tertiary' type='body-3-regular' text={getSystemVersion() === 'mac' ? ( '⇧⌥H' ) : ( '⌃⎇H' )} />,
              isMenu: false,
            },
            {
              to: `${process.env.REACT_APP_ALEGRA_SMILE}potencia-tu-negocio/portal-de-clientes/${PRODUCTS.POS}`,
              target: '_blank',
              menuName: 'alegra',
              action: () => dispatch(closeMenu()),
              title: I18n.get('clientPortal', 'Portal de clientes'),
              hidden: country === COUNTRIES.MEXICO,
              disabled: {
                status: !isOnline && isVersionAvailable,
                message: I18n.get('offline_menu_blocked', 'Podrás acceder cuando tengas conexión'),
              },
              extraIcon: <Icon icon={IconArrowUpRight} size='small' />,
              isMenu: false,
            },
            {
              to: `${process.env.REACT_APP_ALEGRA_SMILE}potencia-tu-negocio/portal-de-clientes`,
              target: '_blank',
              menuName: 'alegra',
              action: () => dispatch(closeMenu()),
              title: I18n.get('selfInvoice', 'Autofacturación de tickets'),
              hidden: country !== COUNTRIES.MEXICO || (country === COUNTRIES.MEXICO && !showSelfInvoice()),
              disabled: {
                status: !isOnline && isVersionAvailable,
                message: I18n.get('offline_menu_blocked', 'Podrás acceder cuando tengas conexión'),
              },
              extraIcon: <Icon icon={IconArrowUpRight} size='small' />,
              isMenu: false,
            },
          ]}
        />
        <Bottom />
      </div>
    </div>
  );
};

export default Navigation;
