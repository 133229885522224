import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { get, isEmpty, last } from 'lodash';

import * as mutations from '../../../graphql/mutations'
import { checkStationValues } from '../../../reducers/app';
import { companySelector, decimalPrecision, country as countrySelector, membershipSelector } from '../../../selectors/company';
import { getInvoiceNumerations } from '../../../selectors/numerations';
import { getMainCurrency } from '../../../selectors/currencies';
import { activeBanks } from '../../../selectors/banks';
import {
  station as stationSelector,
  stationDebitBank,
  stationCreditBank,
  stationTransferBank,
  APIGraphqlSelector,
} from '../../../selectors/app';
import { toast, replaceAndParse } from '../../../utils';
import { formError, handleError } from '../../../utils/errors';
import Header from './Header'
import Body from './Body'
import Bottom from './Bottom'
import { initialValues, validate, transform } from './utils';
import { itemReachedMinQuantity, singleItemReachedMinQuantityFromValues, getKitMaximumQuantity, setInvoice } from '../../../reducers/editInvoice';
import { getItem } from '../../../database/itemsDB';
// import { changeItemsByApi, refresh } from '../../../reducers/items';

const EditInvoice = ({ invoice, onCancel, onRefresh }) => {
  const dispatch = useDispatch()
  const decimal = useSelector(decimalPrecision)
  const numerations = useSelector(getInvoiceNumerations)
  const station = useSelector(stationSelector)
  const banks = useSelector(activeBanks)
  const debitBank = useSelector(stationDebitBank)
  const creditBank = useSelector(stationCreditBank)
  const transferBank = useSelector(stationTransferBank)
  const mainCurrency = useSelector(getMainCurrency)
  const APIGraphql = useSelector(APIGraphqlSelector);
  const country = useSelector(countrySelector)
  const company = useSelector(companySelector)
  const membership = useSelector(membershipSelector)

  const tipAmount = get(invoice, 'additionalCharges[0].amount', 0)
  const tip = {
    include: tipAmount > 0,
    value: tipAmount,
    type: "VALUE"
  }

  const showLimitReached = async (item) => {
    switch (get(item, 'type', 'product')) {
      case 'product':
        toast.warning({
          title: I18n.get('itemLimitWarningTitle', 'Ya vendiste todas las unidades. 🏁'),
          subtitle: I18n.get('itemLimitWarningSubtitle', 'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'),
        })
        break
      case 'kit':
        const maxQuantity = await dispatch(getKitMaximumQuantity(item));
        toast.warning({
          title: replaceAndParse(I18n.get('itemLimitWarningTitle.kitAvailable', '¡Puedes vender máximo {}! ✋'), [`${maxQuantity} ${maxQuantity > 1 ? I18n.get('kits', 'combosT') : I18n.get('kit', 'combo')}`]),
          subtitle: replaceAndParse(I18n.get('itemLimitWarningSubtitle.kitAvailable', 'Ten en cuenta que tu Combo "{}" contiene productos con ese número de unidades disponibles.'), [`<span class="font-weight-bold">${get(item, 'name', '')}</span>`]),
        })
        break;
      default:
        toast.warning({
          title: I18n.get('itemLimitWarningTitle', 'Ya vendiste todas las unidades. 🏁'),
          subtitle: I18n.get('itemLimitWarningSubtitle', 'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'),
        })
        break
    }
  }

  // const updateItemsInventory = async (items) => {
  //   try {
  //     const itemsToUpdate = new Set();
  //     const fullItems = await Promise.all(items.map(async ({ id }) => {
  //       return getItem(+id)
  //     }))

  //     fullItems.forEach((item) => {
  //       if (get(item, 'type') !== 'kit') {
  //         itemsToUpdate.add(+get(item, 'id'))
  //       } else {
  //         const subitems = get(item, 'subitems.item', []);
  //         subitems.forEach(({ id }) => {
  //           itemsToUpdate.add(+id)
  //         })
  //       }
  //     })
  //     await dispatch(changeItemsByApi([...itemsToUpdate.values()]))
  //     dispatch(refresh())
  //   } catch {
  //   }
  // }

  if (!invoice) return null;
  const submit = async (values, form) => {
    const items = get(values, 'items').filter(i => get(i, "id", null) !== null)
    const completeItems = await Promise.all(
      items.map(async (item, index) => {
        return { ...await getItem(parseInt(get(item, "id", null))), quantity: +get(items[index], 'quantity') }
      })
    )
    dispatch(setInvoice({ ...invoice, items: completeItems }))


    const limitReachedItems = await Promise.all(completeItems.map(async (item, index) => {
      if (await dispatch(itemReachedMinQuantity(item, singleItemReachedMinQuantityFromValues)) && (get(item, 'quantity') !== get(form.getFieldState("items"), `initial[${index}].quantity`))) {
        return item
      }
    }));

    if (!isEmpty(limitReachedItems.filter(i => i))) {
      showLimitReached(last(limitReachedItems))
      return;
    }
    try {
      const canContinue = await dispatch(checkStationValues({ type: 'edit-invoice', values }))
      if (!canContinue)
        return;

      const { updatedInvoice, updatedPayments, newPayments } = transform(values, {
        invoice,
        station,
        form,
        banks: {
          debit: debitBank,
          credit: creditBank,
          transfer: transferBank,
        },
        decimal,
        country,
        company,
        membership
      })

      try {
        const updatedPaymentsFiltered = updatedPayments.filter(payment => String(get(payment, "invoices[0].amount")) !== '0')
        const newPaymentsFiltered = newPayments.filter(payment => String(get(payment, "invoices[0].amount")) !== '0')
        const oldPayments = Array.isArray(get(invoice, 'payments', [])) ? get(invoice, 'payments', []) : []
        const oldPaymentsClean = oldPayments.map(payment => ({ amount: get(payment, 'amount'), paymentMethod: get(payment, 'paymentMethod') }))

        const newPaymentsForRequest = [
          ...newPaymentsFiltered.map(payment => ({ amount: get(payment, 'invoices[0].amount'), paymentMethod: get(payment, 'paymentMethod') })), 
          ...updatedPaymentsFiltered.map(payment => ({ amount: get(payment, 'invoices[0].amount'), paymentMethod: get(payment, 'paymentMethod') })),
        ]
        
        newPaymentsForRequest.sort((a, b) => a.paymentMethod === 'cash' ? -1 : b.paymentMethod === 'cash' ? 1 : 0)        

        await Promise.all([
          newPaymentsFiltered.length
            ? Promise.all(newPaymentsFiltered.map(payment => APIGraphql(graphqlOperation(mutations.createInvoicePayment, {
              payment
            }))))
            : Promise.resolve(),
            updatedPaymentsFiltered.length
            ? Promise.all(updatedPaymentsFiltered.map(payment => APIGraphql(graphqlOperation(mutations.updateInvoicePayment, {
              payment
            }))))
            : Promise.resolve(),
            await APIGraphql(graphqlOperation(mutations.updateInvoice, {
              invoice: {...updatedInvoice, oldPayments: oldPaymentsClean, newPayments: newPaymentsForRequest}
            }))
          ])

        // await updateItemsInventory([...items, ...get(form.getFieldState("items"), `initial`)]);

        toast.success({
          title: I18n.get('editInvoiceSuccessfully', '¡Ya se aplicaron tus cambios!'),
          subtitle: I18n.get('editInvoiceSuccessfullyMessage', 'Puedes visualizar la nueva información desde el detalle de tu factura.'),
        })
      } catch (error) {
        toast.error({
          title: I18n.get('editInvoiceError', 'Revisa los datos que cambiaste'),
          subtitle: handleError(error, { defaultMessage: I18n.get('editInvoiceErrorMessage', 'Puedes visualizar la nueva información desde el detalle de tu factura.') }),
        })
      }

      onRefresh()
    } catch (error) {
      return formError(error, I18n.get('changeInvoiceError', 'hubo un error al tratar de modificar la factura'))
    }
  }

  return (
    <Form
      onSubmit={submit}
      validate={values => validate(values, { decimal, country, company, tip, membership })}
      initialValues={initialValues({
        invoice,
        numerations,
        banks,
        debitBank,
        creditBank,
        transferBank,
        mainCurrency,
      })}
      mutators={{
        ...arrayMutators
      }}
    >

      {({ handleSubmit, submitting, form, values, submitError, error }) => (
        <form className="master-detail__detail item-selected" noValidate onSubmit={handleSubmit}>

          <Header invoice={invoice} />

          <Body
            invoice={invoice}
            form={form}
            values={values}
            submitError={submitError || error}
          />

          <Bottom
            submitting={submitting}
            onCancel={onCancel}
          />

        </form>
      )}

    </Form>
  )
}

EditInvoice.propTypes = {
  invoice: PropTypes.object,
  onCancel: PropTypes.func,
}

export default EditInvoice;
