import { isObject, uniqueId } from 'lodash';
import { costaRicaEconomicActivities } from '../../../../../../components/countriesData/costaRica/economicActivities';

function costaRicaInitialValues({
  contact,
  company,
  defaultAccountReceivableCategory,
  defaultDebtToPayCategory,
}) {
  if (!!contact) {
    return {
      ...contact,
      ...contact?.nameObject,
      firstName: contact?.nameObject?.firstName || contact?.name,
      identification: {
        ...contact?.identificationObject,
        number: contact?.identificationObject
          ? contact?.identificationObject?.number
          : contact?.identification?.number,
        type: contact?.identificationObject
          ? contact?.identificationObject?.type?.key
            ? contact?.identificationObject?.type?.key
            : contact?.identificationObject?.type
          : contact?.identification?.type,
      },
      email: contact?.email,
      address: {
        ...contact?.address,
        combined: {
          value: contact?.address?.district,
          parentValue: contact?.address?.city,
          provinceValue: contact?.address?.department,
        },
        neighborhood: isObject(contact?.address?.neighborhood)
          ? contact?.address?.neighborhood
          : contact?.address?.neighborhood,
      },
      seller: contact?.seller?.id
        ? contact?.seller?.id?.toString()
        : contact?.seller,
      priceList: contact?.priceList?.id
        ? contact?.priceList?.id?.toString()
        : contact?.priceList,
      term: contact?.term?.id ? contact?.term?.id?.toString() : contact?.term,
      statementAttached: contact?.statementAttached,
      economicActivity: costaRicaEconomicActivities.find(
        (option) => option.code === contact?.economicActivity
      ),
      exonerations: contact?.exoneration?.map((exoneration) => {
        const [day, month, year] = exoneration?.emissionDate?.split('/');
        const formattedDate = `${year}-${month}-${day}`;
        return {
          ...exoneration,
          emissionDate: new Date(formattedDate),
          key: uniqueId(),
        };
      }),
      accounting: {
        accountReceivable: contact?.accounting?.accountReceivable?.id,
        debtToPay: contact?.accounting?.debtToPay?.id,
      },
      creditLimit: contact?.creditLimit,
    };
  }
  const combinedCompanyAddress = [
    company?.address?.district,
    company?.address?.city,
    company?.address?.department,
  ];

  return {
    accounting: {
      accountReceivable: defaultAccountReceivableCategory?.id,
      debtToPay: defaultDebtToPayCategory?.id,
    },
    address: {
      country: 'CRI',
      combined: combinedCompanyAddress?.every((value) => !!value)
        ? combinedCompanyAddress.join(', ')
        : '',
    },
    statementAttached: true,
    ignoreRepeated: false,
  };
}

export default costaRicaInitialValues;
