import { toastHandler, Typography, Loader } from '@alegradev/smile-ui-react';
import { I18n } from '@aws-amplify/core';
import { useState } from 'react';
import { graphqlOperation } from "aws-amplify"
import { useDispatch, useSelector } from 'react-redux';
import { APIGraphqlSelector } from '../../../selectors/app';
import { isTicketOfflineEnabled } from '../../../selectors/company';
import { setUser } from '../../../reducers/auth';
import * as mutations from '../../../graphql/mutations';
import Switch from '../../common/Switch';
import { isAdmin as isAdminSelector} from '../../../selectors/auth';
import { get } from 'lodash';
import { sendNewGTMEvent } from '../../../reducers/company';

export const SwitchTicketOffline = () => {
  const [isLoading, setIsLoading] = useState(false);
  const isAdmin = useSelector(isAdminSelector);
  const isEnabled = useSelector(isTicketOfflineEnabled);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const dispatch = useDispatch();

  const switchTicketOffline = async () => {
    if (!isAdmin) {
      toastHandler({
        title: I18n.get('enableTicketOffline.notification.error.title', 'Contacta al administrador'),
        description: I18n.get('enableTicketOffline.notification.error.text', 'Esta funcionalidad solo pueden activarla usuarios administradores.'),
        type: 'error',
        direction: "vertical",
        autoClose: 5000,
        position: 'top-right',
      });
      return
    }

    setIsLoading(true);
    try {
      const response = await APIGraphql(graphqlOperation(mutations.updateUser, {
        user: {
          company: {
            localSettings: {
              enabledTicketOffline: !isEnabled
            }
          }
        }
      }));

      dispatch(setUser(get(response, 'data.updateUser')));
      dispatch(sendNewGTMEvent('pos-offline-electronic-invoice-' + (!isEnabled ? 'enabled' : 'disabled')));

      toastHandler({
        title: I18n.get('enableTicketOffline.notification.success', 'Cambios guardados correctamente'),
        autoClose: 5000,
        position: 'top-right',
      });
    } catch (error) {
      toastHandler({
        title: I18n.get('companyUpdateError', 'Error al guardar los cambios'),
        autoClose: 5000,
        position: 'top-right',
        type: 'error',
      });
      console.error('Error updating company', error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className='switch-ofline-ticket-container'>
      <div className='switch-ofline-ticket-container__body'>
        <div>
          <Typography
            variant='primary'
            style={{ color: '#334155' }}
            type='label-1'
            text={I18n.get('enableTicketOffline.title', 'Documentos electrónicos sin internet')}
          />
          <Typography
            variant='primary'
            style={{ color: '#6C757D', fontSize: '12.5px' }}
            type='body-3-regular'
            text={
              `<p>
                ${I18n.get('enableTicketOffline.description.text', 'Sigue creando facturas y documentos POS electrónicos cuando pierdas tu conexión.')}
                <a className="btn-link" href="https://ayuda.alegra.com/colombia/facturacion-electronica-offline-pos" target="_blank" rel="noreferrer">
                  ${I18n.get('knowHowItWorks', 'Conocer cómo funciona')}
                </a>
              </p>`
            }
            withHtml={true}
          />
        </div>

        <div className='switch-container'>
          <div>
            {isLoading &&
              <div className='loader'>
                <Loader />
              </div>
            }
            <Switch
              active={!!isEnabled}
              disabled={isLoading}
              onChange={() => switchTicketOffline()}
              large
            />
          </div>
        </div>

      </div>
    </div>
  )
}