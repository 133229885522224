import React, { useMemo, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, get } from 'lodash';

import { openSideModal } from '../../../reducers/sideModals';
import { setTypeToPrint } from '../../../reducers/print';
import {
  station as stationSelector,
  electronicInvoicing,
  stationInvoiceNumeration,
} from '../../../selectors/app';
import {
  isOnlyInvoicingPlan,
  country as countrySelector,
  multicurrency as isMulticurrencyEnabled,
  companySelector,
} from '../../../selectors/company';
import { numeration as invoiceNumerationSelector } from '../../../selectors/activeInvoice';
import ActiveInvoiceSettings from './ActiveInvoiceSettings';
import InvoiceSettings from './InvoiceSettings';
import SmallDevicesSettings from './SmallDevicesSettings';
import Typography from '../../common/Typography';
import { COUNTRIES } from '../../../utils/enums/countries';

import {
  Button,
  Grid,
  Popover,
  Tooltip,
  useDeviceType,
} from '@alegradev/smile-ui-react';
import {
  IconAdjustmentsHorizontal,
  IconCash,
  IconDiscount2,
  IconDotsVertical,
  IconPrinter,
} from '@tabler/icons-react';
import { openModal } from '../../../reducers/modals';
import useNotification from '../../../hooks/useNotification/hook';
import { isBefore } from 'date-fns';
import { sendNewGTMEvent } from '../../../reducers/company';
import { hasPermissionTo, permissionsSelector } from '../../../selectors/auth';

const invoiceTitle = (country, isElectronic, numeration) => {
  const { prefix, documentType } = numeration;

  let title = I18n.get('invoiceOfSale', 'factura de venta');
  if (
    [
      'peru',
      'costaRica',
      'republicaDominicana',
      'colombia',
      'argentina',
      'panama',
    ].includes(country)
  ) {
    if (isElectronic) {
      if (documentType === 'invoice')
        title = I18n.get(
          'electronicSellInvoice',
          'factura de venta electrónica'
        );
      if (documentType === 'saleTicket')
        title = I18n.get('electronicTicket', 'boleta electrónica');
    } else {
      if (documentType === 'invoice')
        title = I18n.get('invoiceOfSale', 'factura de venta');
      if (documentType === 'saleTicket')
        title =
          country === 'colombia'
            ? I18n.get('posDocument', 'Documento POS')
            : I18n.get('saleTicket', 'boleta de venta');
    }
  }

  if (country === 'spain') {
    if (documentType === 'saleTicket') {
      title = I18n.get('invoiceOfSale', 'Ticket de venta');
    }
    if (documentType === 'invoice') {
      title = I18n.get('ordinaryInvoice', 'Factura ordinaria');
    }
  }

  if (country === COUNTRIES.MEXICO) {
    if (documentType === 'invoice') {
      title = I18n.get('invoiceOfSale.new', 'Factura de venta');
    } else {
      title = I18n.get('saleTicket.new', 'Ticket de venta');
    }
  }

  if (prefix === 'EPOS' || !!get(numeration, 'linkedDevices', null)) {
    title = I18n.get('posDocument', 'Documento POS');
    if (isElectronic)
      title = I18n.get('electronicPOSDocument', 'Documento POS electrónico');
  }

  return title;
};

const Header = () => {
  const { Wrapper } = Grid;
  const [openSettings, setOpenSettings] = useState(false);
  const [openInvoiceSettings, setOpenInvoiceSettings] = useState(false);
  const [openSmallDevicesSettings, setOpenSmallDevicesSettings] =
    useState(false);
  const dispatch = useDispatch();
  const station = useSelector(stationSelector);
  const country = useSelector(countrySelector);
  const invoiceNumeration = useSelector(invoiceNumerationSelector);
  const mainNumeration = useSelector(stationInvoiceNumeration);
  const numeration = !!invoiceNumeration ? invoiceNumeration : mainNumeration;
  const isElectronic = useSelector(electronicInvoicing(numeration));
  const multicurrency = useSelector(isMulticurrencyEnabled);
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan);
  const pendingInvoicesEnabled = get(station, 'pendingInvoicesEnabled', false);
  const { registryDate } = useSelector(companySelector);
  const divieceType = useDeviceType();
  const can = useSelector(hasPermissionTo);
  const permissions = useSelector(permissionsSelector)

  const { viewed, setViewed } = useNotification({
    notificationName: `pos-global-discounts-notification`,
  });

  const handleOpenGlobalDiscounts = () => {
    dispatch(openModal({ modal: 'globalDiscounts' }));
    dispatch(sendNewGTMEvent('pos-global-discount-attempted'));
  };

  return (
    <>
      <div className='invoice-top__header d-flex w-100 pl-3 align-items-center justify-content-between'>
        <div className='py-4 pl-3'>
          <Typography
            type='body-1-bold'
            variant='secondary'
            text={invoiceTitle(country, isElectronic, { ...numeration })}
          />
        </div>

        <div className='invoice-top__header-actions-large justify-content-between align-items-center pr-2'>
          <Popover
            defaultVisible={
              !viewed &&
              isBefore(new Date(registryDate), new Date('12/23/2024')) &&
              !window.Cypress
            }
            showCloseButton
            onClose={() => setViewed()}
            placement='bottom'
            width={340}
            full
            title={I18n.get(
              'globalDiscountsNotification',
              '¡Nuevo! Descuentos globales 🎉'
            )}
            description={I18n.get(
              'globalDiscountsNotificationDescription',
              'Aplica descuentos a todos los productos de una factura en cuestión de segundos. ¡Pruébalo! '
            )}
          >
            <Tooltip
              placement='bottom'
              overlay={capitalize(
                I18n.get('globalDiscounts', 'Descuentos globales')
              )}
            >
              <Button
                type='button'
                emphasis='text'
                onlyIcon={IconDiscount2}
                disabled={!can('edit-discount', 'invoices')}
                onClick={handleOpenGlobalDiscounts}
              />
            </Tooltip>
          </Popover>

          {!!pendingInvoicesEnabled && !onlyInvoicingPlan && (
            <Tooltip
              placement='bottom'
              overlay={capitalize(I18n.get('print', 'imprimir'))}
            >
              <Button
                emphasis='text'
                onlyIcon={IconPrinter}
                onClick={() => dispatch(setTypeToPrint('preInvoice'))}
              />
            </Tooltip>
          )}

          {!!multicurrency && (
            <Tooltip
              placement='bottomRight'
              overlay={capitalize(
                I18n.get('currenciesSettings', 'configuración de monedas')
              )}
            >
              <Button
                emphasis='text'
                onlyIcon={IconCash}
                onClick={() => setOpenSettings(true)}
              />
            </Tooltip>
          )}

          {country === 'costaRica' && (
            <Tooltip
              placement='bottomRight'
              overlay={capitalize(
                I18n.get('invoiceSettings', 'configuración de venta')
              )}
            >
              <Button
                emphasis='text'
                onlyIcon={IconAdjustmentsHorizontal}
                onClick={() => setOpenInvoiceSettings(!openInvoiceSettings)}
              />
            </Tooltip>
          )}

          {!['costaRica'].includes(country) && (
            <Tooltip
              placement='bottomRight'
              overlay={capitalize(
                I18n.get('invoiceSettings', 'configuración de venta')
              )}
            >
              <Button
                emphasis='text'
                onlyIcon={IconAdjustmentsHorizontal}
                onClick={() =>
                  dispatch(openSideModal({ sideModal: 'invoiceSettings' }))
                }
              />
            </Tooltip>
          )}
        </div>

        {divieceType === 'mobile' && (
          <Wrapper align={{ lg: 'center' }}>
            <Tooltip
              placement='bottom'
              overlay={capitalize(
                I18n.get('globalDiscounts', 'Descuentos globales')
              )}
            >
              <Button
                type='button'
                emphasis='text'
                onlyIcon={IconDiscount2}
                disabled={!can('edit-discount', 'invoices')}
                onClick={handleOpenGlobalDiscounts}
              />
            </Tooltip>

            <Button
              type='button'
              emphasis='text'
              onlyIcon={IconDotsVertical}
              onClick={() =>
                setOpenSmallDevicesSettings(!openSmallDevicesSettings)
              }
            />
          </Wrapper>
        )}
      </div>

      {openInvoiceSettings && <InvoiceSettings />}

      {openSettings && (
        <ActiveInvoiceSettings onClose={() => setOpenSettings(false)} />
      )}

      {openSmallDevicesSettings && (
        <SmallDevicesSettings
          printAction={() => dispatch(setTypeToPrint('preInvoice'))}
          currencyAction={() => setOpenSettings(true)}
          settingsAction={() =>
            dispatch(openSideModal({ sideModal: 'invoiceSettings' }))
          }
          onClose={() => openSmallDevicesSettings(false)}
        />
      )}
    </>
  );
};

export default Header;
